import { Component, OnInit, OnDestroy, AfterViewInit, QueryList, ViewChildren, Renderer } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AWS } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PubNubAngular } from 'pubnub-angular2';
import {v4 as uuidv4} from 'uuid';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  userGroup: string = '';
  userSub: string = '';
  ismasking : boolean = true;
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;

  dtOptions: any = {};
  dtOptionsActivity: any = {};
  dtOptionsActivity2: any = {};

  dtTrigger: Subject<AdminComponent> = new Subject();
  IotPatientList: any;
  IotPatientListLength: number = 0;

  dtTrigger1: Subject<AdminComponent> = new Subject();
  TrendList: any;
  TrendListLength: number = 0;

  dtTrigger2: Subject<AdminComponent> = new Subject();
  AdminList: any;
  AdminListLength: number = 0;

  dtTrigger3: Subject<AdminComponent> = new Subject();

  dtTrigger4: Subject<AdminComponent> = new Subject();
  BillingEligibilityExceptionReportList: any;
  BillingEligibilityExceptionReportListLength: number = 0;

  dtTrigger5: Subject<AdminComponent> = new Subject();
  ActivityReportList: any;
  ActivityReportListLength: number = 0;

  dtTrigger6: Subject<AdminComponent> = new Subject();
  ReviewReportList: any;
  ReviewReportListLength: number = 0;

  dtTrigger7: Subject<AdminComponent> = new Subject();
  OffProviderList: any;
  OffProviderLength: number = 0;

  dtTrigger8: Subject<AdminComponent> = new Subject();
  RPMActivityPatientList: any;
  RPMActivityPatientLength: number = 0;

  dtTrigger9: Subject<AdminComponent> = new Subject();
  RPMActivityPatientCallList: any;
  RPMActivityPatientCallLength: number = 0;

  dtTrigger10: Subject<AdminComponent> = new Subject();
  CallActivityList: any;
  CallActivityLength: number = 0;

  dtTrigger11: Subject<AdminComponent> = new Subject();
  ClinicalActivitySummaryList: any;
  ClinicalActivitySummaryLength: number = 0;

  dtTrigger12: Subject<AdminComponent> = new Subject();
  ReportList: any;
  ReportListLength: number = 0;

  body: HTMLElement = document.getElementById('body') as HTMLElement;
  screen: HTMLElement = document.getElementById('screen') as HTMLElement;
  screencontent: HTMLElement = document.getElementById('screencontent') as HTMLElement;
  processing: HTMLElement = document.getElementById('cover') as HTMLElement;

  practiceCount = 0;


  inventoryTypeList = [{ id: "Issue", name: "Issue New" }, { id: "Return", name: "Return" }, { id: "Exchange", name: "Exchange" }];
  inventoryItemList = [];
  inventoryItemOptionList = [];
  addInventoryPatient: any;
  inventoryType = "";
  inventoryItem = 0;
  inventorySerial = "";
  inventoryNewSerial = "";
  inventoryItemOption = "";
  InventoryMaxQuantity:Number;

  inventoryTypeError = false;
  inventoryItemError = false;
  inventoryOptionError = false;
  inventorySerialError = false;
  inventoryNewSerialError = false;
  inventoryisSerial = false;
  inventoryisQuantity = false;
  inventoryQuantityError = false;
  inventoryQuantityErrorStr = ""

  patientItemList = [];

  DeviceList = [{id: 0, name: "All"}, {id: 19, name: "Weight"},{ id: 9, name: "BPM" }, { id: 10, name: "Blood Glucose" }];
  IotPracticeList = [];

  myDate4: any;
  myPrevMonthDate4: any;

  myDateTrend: any;
  Trendpractice = "";

  Iotdevice = "";
  Iotpractice = "";
  Iotdeviceid = "";

  currentPatientSub = "";

  mySubscription: any;

  allPullList = [];
  EligilityExceptionallPullList = [];
  EligibilityExceptionpractice = "All";

  LineChartData: any = [];
  LinechartLabels: any = [];
  LinechartColors = [];
  LinechartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: false
        }
      }],
      xAxes:[{
        ticks:{
            display: true,
            autoSkip: true,
            maxTicksLimit: 10
        }
    }]
    }
  };

  GraphPracticeName = "";

  systemAllPullList = [];

  AddSystemAdminemailInvalid: boolean = false;
  AddSystemAdminEmailError: string = '';

  AddSystemAdminPasswordInvalid: boolean = false;
  AddSystemAdminPasswordError: string = '';

  AddSystemAdminPracticeInvalid: boolean = false;
  AddSystemAdminPracticeError: string = '';

  EditSystemAdminpractice = [];

  EditSystemAdminPracticeInvalid: boolean = false;
  EditSystemAdminPracticeError: string = '';

  EditSystemAdminEmail = "";

  EditSystemAdmin:any;

  assignAdmin:any;
  assignAdminList:any;
  assignAdminObj:any;
  assignAdminObjAll:any;
  assignPractice:any;

  twilioEndPoint = "https://3xcxz84v68.execute-api.us-east-1.amazonaws.com";
  twilioEndPointApiKey = "2pdKyWDlxE6KxzVGV8Dsq5cDxcTVuWhZ3k5TCmYe";

  constructor(private renderer: Renderer, private router: Router, private httpClient: HttpClient, public datepipe: DatePipe, private pubnub: PubNubAngular) {
    let authData = JSON.parse(localStorage.getItem('AUTH'));
    this.userGroup = authData.group;
    this.userSub=  authData.id;

    if(localStorage.getItem('Mask') == 'yes'){
      this.ismasking = true;
    }else if(localStorage.getItem('Mask') == 'no'){
      this.ismasking = false;
    }

    let todaydate = new Date();
    let prevmonthdate = new Date();

    prevmonthdate.setMonth(todaydate.getMonth() - 1);
    prevmonthdate.setDate(prevmonthdate.getDate() + 1);

    this.myDate4 = this.formatDate(todaydate);
    this.myPrevMonthDate4 = this.formatDate(prevmonthdate);

    this.myDateTrend = this.formatDate(todaydate);

    this.myFromDateActivity = this.formatDate(todaydate);
    this.myToDateActivity = this.formatDate(todaydate);
    this.myDateActivity = this.formatDate(todaydate);

    this.myFromDateCallActivity = this.formatDate(todaydate);
    this.myToDateCallActivity = this.formatDate(todaydate);

    this.myFromDateClinicalActivitySummary = this.formatDate(todaydate);
    this.myToDateClinicalActivitySummary = this.formatDate(todaydate);

    this.myDateReview = this.formatDate(todaydate);

    this.thisDate = this.formatDate(todaydate);

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });

    if (localStorage.getItem('ALL-POOL')) {
      this.allPullList = JSON.parse(localStorage.getItem('ALL-POOL'));
      this.allPullList.unshift({ id: 0, name: "All" });

      this.systemAllPullList = JSON.parse(localStorage.getItem('ALL-POOL'));

      this.EligilityExceptionallPullList = JSON.parse(localStorage.getItem('ALL-POOL'));
      this.EligilityExceptionallPullList.unshift({ id: "All", name: "All" });

      this.ActivityallPullList = JSON.parse(localStorage.getItem('ALL-POOL'));
      // this.ActivityallPullList.unshift({ id: "All", name: "All" });
    }
  }

  ngOnInit() {
    this.body.style.overflow = 'auto';
    this.body.classList.add('skin-blue');
    this.body.classList.add('sidebar-mini');

    this.screen.removeAttribute('style');
    this.screencontent.innerHTML = "";
    this.screen.style.display = 'none';

    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      // dom: 'lBfrtip',
      dom: '<"col-sm-2"B><"col-sm-5"l><"col-sm-5"f>rt<"col-sm-5"i><"col-sm-7"p>',
      // Configure the buttons
      buttons: [
        // 'colvis',
        // 'copy',
        // 'print',
        'excel'
      ],
      stateSave: true
    };
    this.dtOptionsActivity = {
      // Declare the use of the extension in the dom parameter
      // dom: 'lBfrtip',
      dom: '<"col-sm-2"B><"col-sm-5"l><"col-sm-5"f>rt<"col-sm-5"i><"col-sm-7"p>',
      // Configure the buttons
      buttons: [
        // 'colvis',
        // 'copy',
        // 'print',
        'excel'
      ],
      stateSave: true,
      ordering: false,
      pageLength: 500,
      paging: false
    };
    this.dtOptionsActivity2 = {
      // Declare the use of the extension in the dom parameter
      // dom: 'lBfrtip',
      dom: '<"col-sm-4"l><"col-sm-4"f>rt<"col-sm-5"i><"col-sm-7"p>',
      // Configure the buttons
      buttons: [
        // 'colvis',
        // 'copy',
        // 'print',
        // 'excel'
      ],
      stateSave: true
    };
    this.dtTrigger.next();
    this.dtTrigger1.next();
    this.dtTrigger2.next();
    this.dtTrigger3.next();
    this.dtTrigger4.next();
    this.dtTrigger5.next();
    this.dtTrigger6.next();
    this.dtTrigger7.next();
    this.dtTrigger8.next();
    this.dtTrigger9.next();
    this.dtTrigger10.next();
    this.dtTrigger11.next();
    this.dtTrigger12.next();

    this.LineChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];

    this.getAdmindashboard();

    if(this.userGroup === 'Superadmin'){
      this.getAdminList();
      this.adminAssign("", "nomodal", "");
    }    
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
    this.dtTrigger2.unsubscribe();
    this.dtTrigger3.unsubscribe();
    this.dtTrigger4.unsubscribe();
    this.dtTrigger5.unsubscribe();
    this.dtTrigger6.unsubscribe();
    this.dtTrigger7.unsubscribe();
    this.dtTrigger8.unsubscribe();
    this.dtTrigger9.unsubscribe();
    this.dtTrigger10.unsubscribe();
    this.dtTrigger11.unsubscribe();
    this.dtTrigger12.unsubscribe();
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.dtTrigger1.next();
    this.dtTrigger2.next();
    this.dtTrigger3.next();
    this.dtTrigger4.next();
    this.dtTrigger5.next();
    this.dtTrigger6.next();
    this.dtTrigger7.next();
    this.dtTrigger8.next();
    this.dtTrigger9.next();
    this.dtTrigger10.next();
    this.dtTrigger11.next();
    this.dtTrigger12.next();
  }

  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  validateEligibilityExceptionForm = () =>{
    console.log("validateEligibilityExceptionForm");

    console.log(this.EligibilityExceptionpractice)

    this.screencontent.innerHTML = "";
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: this.EligibilityExceptionpractice,
        frompage: "Admin",

        token: uuidv4()
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/billingeligibilityexceptionlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status === "success") {
        let responsedata = JSON.parse(response.data);
        console.log(responsedata);

        

        if(responsedata.length > 0) {
          for (var j = 0; j < responsedata.length; j++) {
            if(responsedata[j]['dob']) {
              responsedata[j]['masked_dob'] = this.make_number_mask(responsedata[j]['dob'].toString(),4);
            }
          }
          this.BillingEligibilityExceptionReportList = responsedata;
          this.BillingEligibilityExceptionReportListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "Five-table") {
                dtInstance.destroy();
                this.dtTrigger4.next();
              }
            });
            }
            else {
              this.dtTrigger4.next();
            }
          });
        }
        else {
          this.BillingEligibilityExceptionReportList = responsedata;
          this.BillingEligibilityExceptionReportListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "Five-table") {
                dtInstance.destroy();
                this.dtTrigger4.next();
              }
            });
            }
            else {
              this.dtTrigger4.next();
            }
          });
        }
      }
    },
    async (error: any) => {
      // this.screen.style.display = 'none';
      console.log(error);
      this.screencontent.innerHTML = "";
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        // alert("Request timeout. Please try again.");
        // this.screen.style.display = 'none';
        var root = this;
        var callcounter = 0;
        setTimeout (function requestCallback() {
          root.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/billingeligibilityexceptionlist2', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
            console.log(response);
            if(response.status === "success") {
              root.screen.style.display = 'none';
              let responsedata = JSON.parse(response.data);
              console.log(responsedata);

              if(responsedata.length > 0) {
                for (var j = 0; j < responsedata.length; j++) {
                  if(responsedata[j]['dob']) {
                    responsedata[j]['masked_dob'] = root.make_number_mask(responsedata[j]['dob'].toString(),4);
                  }
                }
                root.BillingEligibilityExceptionReportList = responsedata;
                root.BillingEligibilityExceptionReportListLength = responsedata.length;
                root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                  if(dtElement.dtInstance) {
                  dtElement.dtInstance.then((dtInstance: any) => {
                    if(dtInstance.table().node().id == "Five-table") {
                      dtInstance.destroy();
                      root.dtTrigger4.next();
                    }
                  });
                  }
                  else {
                    root.dtTrigger4.next();
                  }
                });
              }
              else {
                root.BillingEligibilityExceptionReportList = responsedata;
                root.BillingEligibilityExceptionReportListLength = responsedata.length;
                root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                  if(dtElement.dtInstance) {
                  dtElement.dtInstance.then((dtInstance: any) => {
                    if(dtInstance.table().node().id == "Five-table") {
                      dtInstance.destroy();
                      root.dtTrigger4.next();
                    }
                  });
                  }
                  else {
                    root.dtTrigger4.next();
                  }
                });
              }
              
            }
            else {
              callcounter++;
              if(callcounter < 50) {
                setTimeout (requestCallback, 5000);
              }
              else {
                alert("Request timeout. Please try again.");
                root.screen.style.display = 'none';
              }
            }
          },
          async (error: any) => {
            console.log(error);
          });
        }, 5000);
      }
      else {
        await Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.router.navigateByUrl('/sign-in');
      }
    });
  }

  Activitypractice = 'us-east-1_bwJGm9qi1';
  myFromDateActivity: any;
  myToDateActivity: any;
  myDateActivity: any;
  ActivityallPullList = [];
  thisDate: any;

  ActivityShippingLabelDownload = () =>{
    console.log("validateActivityForm");

    console.log(this.ActivityReportList[this.ActivityReportListLength-1]['patient10']);

    var temp = this.ActivityReportList[this.ActivityReportListLength-1]['patient10'];

    var ExcelPatientList = [];

    for(var patient of temp){
      var temppatient = {}
      var Str = patient.firstName + " " + patient.lastName;
      if(patient.address != ''){
        Str += ", "+patient.address;
      }
      if(patient.home_address1 != ''){
        Str += ", "+patient.home_address1;
      }
      if(patient.home_city != ''){
        Str += ", "+patient.home_city;
      }
      if(patient.home_state != ''){
        Str += ", "+patient.home_state;
      }
      if(patient.home_pin != ''){
        Str += ", "+patient.home_pin;
      }
      temppatient["Name_Address"] = Str;
      ExcelPatientList.push(temppatient);
    }

    console.log(ExcelPatientList)

    /* table id is passed over here */   
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(ExcelPatientList);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, "Shipping_Label.xlsx");
    
  }

  make_number_mask = (num, masklength=7) =>{
    console.log(this.userGroup);
    var numstr = num.toString();
    if(this.ismasking == false){
      return numstr;
    }
    var masked_num = numstr;
    if(numstr.length > masklength){
      masked_num = numstr.substr(0,numstr.length-masklength)+Array(masklength+1).join('x');
    }
    return masked_num;
  }

  validateActivityForm = () =>{
    console.log("validateActivityForm");

    console.log(this.Activitypractice)

    this.screencontent.innerHTML = "";
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: this.Activitypractice,
        fromdate: this.formatUTCDBDateTime(this.myDateActivity+" 00:00:00"),
        todate: this.formatUTCDBDateTime(this.myDateActivity+" 23:59:59"),
        token: uuidv4()
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getactivitylist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status === "success") {
        let responsedata = JSON.parse(response.data);
        console.log(responsedata);

        

        if(responsedata.length > 0) {
          for (var i = 0; i < responsedata.length; i++) {

            if(responsedata[i]['admin_email'] && responsedata[i]['admin_email'] != "") {
              responsedata[i]['admin_email_sub'] = responsedata[i]['admin_email'].substring(0,3);;
            }

            responsedata[i]['date'] = this.formatDateMSlash(this.myDateActivity);

          }

          console.log("kkkk",responsedata)
          
          this.ActivityReportList = responsedata;
          this.ActivityReportListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "Six-table") {
                dtInstance.destroy();
                this.dtTrigger5.next();
              }
            });
            }
            else {
              this.dtTrigger5.next();
            }
          });
        }
        else {
          this.ActivityReportList = responsedata;
          this.ActivityReportListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "Six-table") {
                dtInstance.destroy();
                this.dtTrigger5.next();
              }
            });
            }
            else {
              this.dtTrigger5.next();
            }
          });
        }
      }
    },
    async (error: any) => {
      // this.screen.style.display = 'none';
      console.log(error);
      this.screencontent.innerHTML = "";
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        // alert("Request timeout. Please try again.");
        // this.screen.style.display = 'none';
        var root = this;
        var callcounter = 0;
        setTimeout (function requestCallback() {
          root.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getactivitylist2', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
            root.screen.style.display = 'none';
            // console.log(response);
            if(response.status === "success") {
              let responsedata = JSON.parse(response.data);
              console.log(responsedata);
      
              
      
              if(responsedata.length > 0) {
                for (var i = 0; i < responsedata.length; i++) {
      
                  if(responsedata[i]['admin_email'] && responsedata[i]['admin_email'] != "") {
                    responsedata[i]['admin_email_sub'] = responsedata[i]['admin_email'].substring(0,3);;
                  }
      
                  responsedata[i]['date'] = root.formatDateMSlash(root.myDateActivity);
      
                }
      
                console.log("kkkk",responsedata)
                
                root.ActivityReportList = responsedata;
                root.ActivityReportListLength = responsedata.length;
                root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                  if(dtElement.dtInstance) {
                  dtElement.dtInstance.then((dtInstance: any) => {
                    if(dtInstance.table().node().id == "Six-table") {
                      dtInstance.destroy();
                      root.dtTrigger5.next();
                    }
                  });
                  }
                  else {
                    root.dtTrigger5.next();
                  }
                });
              }
              else {
                root.ActivityReportList = responsedata;
                root.ActivityReportListLength = responsedata.length;
                root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                  if(dtElement.dtInstance) {
                  dtElement.dtInstance.then((dtInstance: any) => {
                    if(dtInstance.table().node().id == "Six-table") {
                      dtInstance.destroy();
                      root.dtTrigger5.next();
                    }
                  });
                  }
                  else {
                    root.dtTrigger5.next();
                  }
                });
              }
            }
            else {
              callcounter++;
              if(callcounter < 120) {
                setTimeout (requestCallback, 5000);
              }
            }
          },
          async (error: any) => {
            console.log(error);
          });
        }, 5000);
      }
      else {
        await Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.router.navigateByUrl('/sign-in');
      }
    });
  }

  Reviewpractice = 'us-east-1_bwJGm9qi1';
  myDateReview: any;

  validateReviewForm = () =>{
    console.log("validateReviewForm");

    console.log(this.Reviewpractice)

    // if(this.myFromDateActivity > this.myToDateActivity) {
    //   alert("From Date must be less than from To Date");
    // }
    // else {

      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = new HttpParams({
        fromObject: {
          userpull_id: this.Reviewpractice,
          fromdate: this.formatUTCDBDateTime(this.myDateReview+" 00:00:00"),
          todate: this.formatUTCDBDateTime(this.myDateReview+" 23:59:59")
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getreviewactivity', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        this.screen.style.display = 'none';
        if(response.status === "success") {
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);

          

          if(responsedata.length > 0) {
            for (var i = 0; i < responsedata.length; i++) {

              if(responsedata[i]['admin_email'] && responsedata[i]['admin_email'] != "") {
                responsedata[i]['admin_email_sub'] = responsedata[i]['admin_email'].substring(0,3);;
              }

              responsedata[i]['date'] = this.formatDateMSlash(this.myDateReview);

            }

            console.log("kkkk",responsedata)
            
            this.ActivityReportList = responsedata;
            this.ActivityReportListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "Seven-table") {
                  dtInstance.destroy();
                  this.dtTrigger6.next();
                }
              });
              }
              else {
                this.dtTrigger6.next();
              }
            });
          }
          else {
            this.ActivityReportList = responsedata;
            this.ActivityReportListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "Seven-table") {
                  dtInstance.destroy();
                  this.dtTrigger6.next();
                }
              });
              }
              else {
                this.dtTrigger6.next();
              }
            });
          }
        }
      },
      async (error: any) => {
        // this.screen.style.display = 'none';
        console.log(error);
        this.screencontent.innerHTML = "";
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          alert("Request timeout. Please try again.");
          this.screen.style.display = 'none';
        }
        else {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    // }
  }

  ActivityPatientList = [];

  activityPatient = (patients) => {
    console.log("activityPatient");
    console.log(patients);

    this.RPMActivityPatientList = patients;
    this.RPMActivityPatientLength = patients.length;
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      if(dtElement.dtInstance) {
      dtElement.dtInstance.then((dtInstance: any) => {
        if(dtInstance.table().node().id == "rpm-activity-patient-table") {
          dtInstance.destroy();
          this.dtTrigger8.next();
        }
      });
      }
      else {
        this.dtTrigger8.next();
      }
    });

    let patientSmsModal = document.getElementById("activity-patient-ankerTag") as HTMLElement;
    patientSmsModal.click();
    
  }

  RPMActivityCurrentPatient = {};

  RPMActivityPatientSelet = (patients) => {
    console.log("RPMActivityPatientSelet");
    console.log(patients);

    this.RPMActivityCurrentPatient = patients;

    let getHistoryClass = this;
    getHistoryClass.screen.style.display = 'block';  

    this.httpClient.get(this.twilioEndPoint + '/stage/logs/phoneHistory?phoneNo=' + encodeURIComponent(patients.sub) , {  headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
    .subscribe((response: any) => {
      getHistoryClass.screen.style.display = 'none';
      if (response.message == 'list success') {
        // this.historyList = response.data;

        console.log(response.data)

        this.RPMActivityPatientCallList = response.data;
        this.RPMActivityPatientCallLength = response.data.length;
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          if(dtElement.dtInstance) {
          dtElement.dtInstance.then((dtInstance: any) => {
            if(dtInstance.table().node().id == "rpm-activity-patient-call-table") {
              dtInstance.destroy();
              this.dtTrigger9.next();
            }
          });
          }
          else {
            this.dtTrigger9.next();
          }
        });
      }

         
    },
    async (error: any) => {
      console.log(error);
      getHistoryClass.screen.style.display = 'none';
    });

    
    
  }

  Providerpractice = 'us-east-1_bwJGm9qi1';
  validateOffProviderForm = () =>{
    console.log("validateOffProviderForm");

    console.log(this.Providerpractice)

    this.screencontent.innerHTML = "";
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: this.Providerpractice
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/offhourproviderlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status === "success") {
        let responsedata = JSON.parse(response.data);
        console.log(responsedata);

        

        if(responsedata.length > 0) {
          for (var i = 0; i < responsedata.length; i++) {

            if(responsedata[i]['admin_email'] && responsedata[i]['admin_email'] != "") {
              responsedata[i]['admin_email_sub'] = responsedata[i]['admin_email'].substring(0,3);;
            }

            responsedata[i]['date'] = this.formatDateMSlash(this.myDateReview);

          }

          console.log("kkkk",responsedata)
          
          this.OffProviderList = responsedata;
          this.OffProviderLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "Eight-table") {
                dtInstance.destroy();
                this.dtTrigger7.next();
              }
            });
            }
            else {
              this.dtTrigger7.next();
            }
          });
        }
        else {
          this.OffProviderList = responsedata;
          this.OffProviderLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "Eight-table") {
                dtInstance.destroy();
                this.dtTrigger7.next();
              }
            });
            }
            else {
              this.dtTrigger7.next();
            }
          });
        }
      }
    },
    async (error: any) => {
      // this.screen.style.display = 'none';
      console.log(error);
      this.screencontent.innerHTML = "";
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        alert("Request timeout. Please try again.");
        this.screen.style.display = 'none';
      }
      else {
        await Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.router.navigateByUrl('/sign-in');
      }
    });
  }


  CallActivityPractice = 'us-east-1_bwJGm9qi1';
  myFromDateCallActivity: any;
  myToDateCallActivity: any;

  CallActivityType = "All";

  // ActivityallCallTypeList = [{id :"All", name: "All"}, {id :"Support", name: "Support"}, {id :"Clinical", name: "Clinical"}, {id :"PR", name: "PR"}];
  ActivityallCallTypeList = [{id :"All", name: "All"}, {id :"Support", name: "Support"}, {id :"Clinical", name: "Clinical"}];

  validateCallActivityForm = () =>{
    console.log("validateCallActivityForm");

    console.log(this.CallActivityPractice)

    if(this.myFromDateCallActivity > this.myToDateCallActivity) {
      alert("From Date must be less than from To Date");
    }else {

      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = new HttpParams({
        fromObject: {
          userpull_id: this.CallActivityPractice,
          fromdate: this.formatUTCDBDateTime(this.myFromDateCallActivity+" 00:00:00"),
          todate: this.formatUTCDBDateTime(this.myToDateCallActivity+" 23:59:59"),
          type: this.CallActivityType
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getcallactivitylist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        this.screen.style.display = 'none';
        if(response.status === "success") {
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);

          

          if(responsedata.length > 0) {
            for (var i = 0; i < responsedata.length; i++) {

              responsedata[i]['create_time_str'] = this.formatTimeM(responsedata[i]['create_time'].replaceAll('-','/') + " UTC");

            }

            console.log("kkkk",responsedata)
            
            this.CallActivityList = responsedata;
            this.CallActivityLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "Call-Activity-table") {
                  dtInstance.destroy();
                  this.dtTrigger10.next();
                }
              });
              }
              else {
                this.dtTrigger10.next();
              }
            });
          }
          else {
            this.CallActivityList = responsedata;
            this.CallActivityLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "Call-Activity-table") {
                  dtInstance.destroy();
                  this.dtTrigger10.next();
                }
              });
              }
              else {
                this.dtTrigger10.next();
              }
            });
          }
        }
      },
      async (error: any) => {
        // this.screen.style.display = 'none';
        console.log(error);
        this.screencontent.innerHTML = "";
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          alert("Request timeout. Please try again.");
          this.screen.style.display = 'none';
        }
        else {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }

  ClinicalActivitySummaryPractice = 'us-east-1_bwJGm9qi1';
  myFromDateClinicalActivitySummary: any;
  myToDateClinicalActivitySummary: any;
  
  validateClinicalActivitySummaryForm = () =>{
    console.log("validateClinicalActivitySummaryForm");

    console.log(this.ClinicalActivitySummaryPractice)

    if(this.myFromDateClinicalActivitySummary > this.myToDateClinicalActivitySummary) {
      alert("From Date must be less than from To Date");
    }else {

      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      let params = new HttpParams({
        fromObject: {
          userpull_id: this.ClinicalActivitySummaryPractice,
          fromdate: this.formatUTCDBDateTime(this.myFromDateClinicalActivitySummary+" 00:00:00"),
          todate: this.formatUTCDBDateTime(this.myToDateClinicalActivitySummary+" 23:59:59")
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getclinicalactivitysummarylist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        this.screen.style.display = 'none';
        if(response.status === "success") {
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);

          

          if(responsedata.length > 0) {
            // for (var i = 0; i < responsedata.length; i++) {

            //   responsedata[i]['create_time_str'] = this.formatTimeM(responsedata[i]['create_time'].replaceAll('-','/') + " UTC");

            // }

            console.log("kkkk",responsedata)
            
            this.ClinicalActivitySummaryList = responsedata;
            this.ClinicalActivitySummaryLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "Clinical-Activity-Summary-table") {
                  dtInstance.destroy();
                  this.dtTrigger11.next();
                }
              });
              }
              else {
                this.dtTrigger11.next();
              }
            });
          }
          else {
            this.ClinicalActivitySummaryList = responsedata;
            this.ClinicalActivitySummaryLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "Clinical-Activity-Summary-table") {
                  dtInstance.destroy();
                  this.dtTrigger11.next();
                }
              });
              }
              else {
                this.dtTrigger11.next();
              }
            });
          }
        }
      },
      async (error: any) => {
        // this.screen.style.display = 'none';
        console.log(error);
        this.screencontent.innerHTML = "";
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          alert("Request timeout. Please try again.");
          this.screen.style.display = 'none';
        }
        else {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }

  ReportPractice = 'us-east-1_bwJGm9qi1';

  reportreportForm = () =>{
    console.log("reportreportForm");

    this.ReportList = [];
    this.ReportListLength = 0;
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      if(dtElement.dtInstance) {
      dtElement.dtInstance.then((dtInstance: any) => {
        if(dtInstance.table().node().id == "reportreport-table") {
          dtInstance.destroy();
          this.dtTrigger12.next();
        }
      });
      }
      else {
        this.dtTrigger12.next();
      }
    });

    this.screencontent.innerHTML = "";
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: this.ReportPractice
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/testlambda', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status === "success") {
        let responsedata = JSON.parse(response.data);
        console.log(responsedata);

        

        if(responsedata.length > 0) {

          for (var i = 0; i < responsedata.length; i++) {

            responsedata[i]['update_at_str'] = this.formatTimeM(responsedata[i]['update_at'].replaceAll('-','/') + " UTC").split(' ')[0];

          }
          
          this.ReportList = responsedata;
          this.ReportListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "reportreport-table") {
                dtInstance.destroy();
                this.dtTrigger12.next();
              }
            });
            }
            else {
              this.dtTrigger12.next();
            }
          });
        }
        else {
          this.ReportList = responsedata;
          this.ReportListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "reportreport-table") {
                dtInstance.destroy();
                this.dtTrigger12.next();
              }
            });
            }
            else {
              this.dtTrigger12.next();
            }
          });
        }
      }
    },
    async (error: any) => {
      // this.screen.style.display = 'none';
      console.log(error);
      this.screencontent.innerHTML = "";
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        alert("Request timeout. Please try again.");
        this.screen.style.display = 'none';
      }
      else {
        await Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.router.navigateByUrl('/sign-in');
      }
    });
  }


  formatTimeM = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes();
    var time = "AM";
    if(hour >= 12) {
      time = "PM";
      if(hour > 12) {
        hour = hour - 12;
      }
    }
    var strhour = ''+ hour;
    if(hour == 0) {
      strhour = '12';
    }
    else if(hour < 10){
      strhour = '0' + hour;
    }
    var strminute = '' + minute;
    if(minute < 10){
      strminute = '0' + minute;
    }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('/')+' '+strhour+":"+strminute+" "+time;
  }

  getAdmindashboard = ( ) =>{
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        sub: this.userSub,
        group: this.userGroup
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/admindashboard', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      if (response.status === "success") {
        let responsedata = JSON.parse(response.data);

        this.IotPracticeList = responsedata[0]['data'];

        this.practiceCount = responsedata[0]['count'];
        
        this.screen.style.display = 'none';
        
      }else {
        Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.screen.style.display = 'none';
        this.router.navigateByUrl('/sign-in');
      }
    },
      async (error: any) => {
        await Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.screen.style.display = 'none';
        this.router.navigateByUrl('/sign-in');
    });
  }

  getAdminList = ( ) =>{
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        group: "SystemAdmin"
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getadminlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      if (response.status === "success") {
        var responsedata = JSON.parse(response.data);
        console.log(responsedata);
        if (responsedata.length > 0){
          this.AdminList = responsedata;
          this.AdminListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "third-table") {
                dtInstance.destroy();
                this.dtTrigger2.next();
              }
            });
            }
            else {
              this.dtTrigger2.next();
            }
          });
        }
        else {
          this.AdminList = responsedata;
          this.AdminListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "third-table") {
                dtInstance.destroy();
                this.dtTrigger2.next();
              }
            });
            }
            else {
              this.dtTrigger2.next();
            }
          });
        }
        
        this.screen.style.display = 'none';
        
      }else {
        Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.screen.style.display = 'none';
        this.router.navigateByUrl('/sign-in');
      }
    },
      async (error: any) => {
        await Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.screen.style.display = 'none';
        this.router.navigateByUrl('/sign-in');
    });
  }

  unassignpusr = (pusr, wherefrom, usrtyp='patient') =>{
    let cnfrmmsg = "Do you want to unassign this Patient?";
    if(usrtyp == "user"){
      cnfrmmsg = "Do you want to unassign all patients from this user?";
    }
    let cnfrmCheck = false;
    if(wherefrom != '') {
      if(confirm(cnfrmmsg)) {
        cnfrmCheck = true;
      }
    }
    else {
      cnfrmCheck = true;
    }
    if(cnfrmCheck) {
      console.log(pusr);
      let params = {}
      if(usrtyp == 'patient') {
        params = {
          "assignid": pusr.idassign
        };
      }
      if(usrtyp == 'user') {
        params = {
          "userid": pusr.sub
        };
      }
      this.screen.style.display = 'block';
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/adminunassign', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        this.screen.style.display = 'none';
        if(response.status == "success") {
          let patientAssign1Modal = document.getElementById("passign-systemAdmin-modal-close-button") as HTMLElement;
          patientAssign1Modal.click();
          let patientAssign2Modal = document.getElementById("assign-systemAdmin-modal-close-button") as HTMLElement;
          patientAssign2Modal.click();
          if(wherefrom != '') {
            this.adminAssign("", "open", wherefrom);
          }
        }
      },
      async (error: any) => {
        console.log(error);
        this.screen.style.display = 'none';
      });
    }
  }

  regchkCount = "0";
  actchkCount = "0";

  regchk = (selval) =>{
    this.regchkCount = String(selval.length);
  }

  actchk = (selval) =>{
    this.actchkCount = String(selval.length);
  }

  adminAssign = (admin, modalstatus = "open", modalid="") =>{
    console.log(admin);
    let params = {
      token: uuidv4(),
      userpulls: ''
    };
    if(admin != "") {
      params = {
        token: uuidv4(),
        userpulls: admin.userpulls
      };
    }
    this.screen.style.display = 'block';
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/adminassign', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status == "success") {
        let responsedata = JSON.parse(response.data);
        console.log(responsedata);
        if(modalstatus == "open") {
          if((admin != "" || (admin == "" && this.assignAdmin)) && modalid !="passign-systemAdmin-ankerTag") {
            if(admin != "") {
              this.assignAdmin = admin;
            }
            this.assignAdminObj = {};
            for(let assignres in responsedata){
              if('assigned' in responsedata[assignres]){
                for(let ukey in responsedata[assignres]['assigned']){
                  if(ukey == this.assignAdmin.sub){
                    responsedata[assignres]['assignedfinal'] = [];
                    responsedata[assignres]['assignedfinal'] = responsedata[assignres]['assigned'][ukey];
                    this.assignAdminObj[assignres] = responsedata[assignres];
                  }
                }
              }
              if('activeassigned' in responsedata[assignres]){
                for(let ukey in responsedata[assignres]['activeassigned']){
                  if(ukey == this.assignAdmin.sub){
                    responsedata[assignres]['activeassignedfinal'] = [];
                    responsedata[assignres]['activeassignedfinal'] = responsedata[assignres]['activeassigned'][ukey];
                    this.assignAdminObj[assignres] = responsedata[assignres];
                  }
                }
              }
            }
            console.log(this.assignAdminObj);
            let practiceAssignModal = document.getElementById("assign-systemAdmin-ankerTag") as HTMLElement;
            practiceAssignModal.click();
          }
          if(admin == "") {
            this.assignAdminObjAll = responsedata;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "fourth-table") {
                  dtInstance.destroy();
                  this.dtTrigger3.next();
                }
              });
              }
              else {
                this.dtTrigger3.next();
              }
            });
            if(modalid == "passign-systemAdmin-ankerTag" && this.assignPractice){
              this.adminAssignPatient(this.assignPractice.key);
            }
          }
        }
        else {
          this.assignAdminObjAll = responsedata;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "fourth-table") {
                dtInstance.destroy();
                this.dtTrigger3.next();
              }
            });
            }
            else {
              this.dtTrigger3.next();
            }
          });
        }
      }
      else {
        if(admin != "") {
          this.assignAdmin = '';
        }
        // alert(response.message);
      }
    },
    async (error: any) => {
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        var root = this;
        var callcounter = 0;
        setTimeout (function requestCallback() {
          root.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/adminassign2', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
            console.log(response);
            if(response.status === "success") {
              let responsedata = JSON.parse(response.data);
              console.log(responsedata);
              if(modalstatus == "open") {
                if((admin != "" || (admin == "" && root.assignAdmin)) && modalid !="passign-systemAdmin-ankerTag") {
                  if(admin != "") {
                    root.assignAdmin = admin;
                  }
                  root.assignAdminObj = {};
                  for(let assignres in responsedata){
                    if('assigned' in responsedata[assignres]){
                      for(let ukey in responsedata[assignres]['assigned']){
                        if(ukey == root.assignAdmin.sub){
                          responsedata[assignres]['assignedfinal'] = [];
                          responsedata[assignres]['assignedfinal'] = responsedata[assignres]['assigned'][ukey];
                          root.assignAdminObj[assignres] = responsedata[assignres];
                        }
                      }
                    }
                    if('activeassigned' in responsedata[assignres]){
                      for(let ukey in responsedata[assignres]['activeassigned']){
                        if(ukey == root.assignAdmin.sub){
                          responsedata[assignres]['activeassignedfinal'] = [];
                          responsedata[assignres]['activeassignedfinal'] = responsedata[assignres]['activeassigned'][ukey];
                          root.assignAdminObj[assignres] = responsedata[assignres];
                        }
                      }
                    }
                  }
                  console.log(root.assignAdminObj);
                  let practiceAssignModal = document.getElementById("assign-systemAdmin-ankerTag") as HTMLElement;
                  practiceAssignModal.click();
                }
                if(admin == "") {
                  root.assignAdminObjAll = responsedata;
                  root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                    if(dtElement.dtInstance) {
                    dtElement.dtInstance.then((dtInstance: any) => {
                      if(dtInstance.table().node().id == "fourth-table") {
                        dtInstance.destroy();
                        root.dtTrigger3.next();
                      }
                    });
                    }
                    else {
                      root.dtTrigger3.next();
                    }
                  });
                  if(modalid == "passign-systemAdmin-ankerTag" && root.assignPractice){
                    root.adminAssignPatient(root.assignPractice.key);
                  }
                }
              }
              else {
                root.assignAdminObjAll = responsedata;
                root.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                  if(dtElement.dtInstance) {
                  dtElement.dtInstance.then((dtInstance: any) => {
                    if(dtInstance.table().node().id == "fourth-table") {
                      dtInstance.destroy();
                      root.dtTrigger3.next();
                    }
                  });
                  }
                  else {
                    root.dtTrigger3.next();
                  }
                });
              }
            }
            else {
              if(admin != "") {
                root.assignAdmin = '';
              }
              callcounter++;
              if(callcounter < 50) {
                setTimeout (requestCallback, 5000);
              }
              else {
                alert("Request timeout. Please try again.");
                root.screen.style.display = 'none';
              }
            }
          },
          async (error: any) => {
            console.log(error);
          });
        }, 5000);
      }
      else {
        Auth.signOut();
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.screen.style.display = 'none';
        this.router.navigateByUrl('/sign-in');
      }
    });
  }

  adminAssignPatient = (pdetailkey) =>{
    console.log(this.assignAdminObjAll);
    let pdetail = {};
    pdetail['key']=pdetailkey;
    pdetail['value']=this.assignAdminObjAll[pdetailkey];
    console.log(pdetail);
    pdetail['users']={};
    if('assigned' in pdetail['value']){
      for(let assign in pdetail['value']['assigned']) {
        if(!(assign in pdetail['users'])){
          pdetail['users'][assign]={};
          for(var i=0; i<this.AdminList.length; i++) {
            if(this.AdminList[i]['sub'] == assign) {
              pdetail['users'][assign]['email'] = this.AdminList[i]['email'];
            }
          }
        }
        pdetail['users'][assign]['assigned'] = pdetail['value']['assigned'][assign];
      }
    }
    if('activeassigned' in pdetail['value']){
      for(let activeassign in pdetail['value']['activeassigned']) {
        if(!(activeassign in pdetail['users'])){
          pdetail['users'][activeassign]={};
          for(var i=0; i<this.AdminList.length; i++) {
            if(this.AdminList[i]['sub'] == activeassign) {
              pdetail['users'][activeassign]['email'] = this.AdminList[i]['email'];
            }
          }
        }
        pdetail['users'][activeassign]['activeassigned'] = pdetail['value']['activeassigned'][activeassign];
      }
    }
    console.log(pdetail);
    this.assignPractice = pdetail;
    // this.assignAdminList = this.AdminList;
    this.assignAdminList = [];
    for(var i=0; i<this.AdminList.length; i++) {
      var AdminListArr = this.AdminList[i].userpulls.split(",");
      // console.log(AdminListArr);
      if(AdminListArr.indexOf(pdetail['key']) > -1){
        this.assignAdminList.push(this.AdminList[i]);
      }
    }
    console.log(this.assignAdminList);
    let practiceAssignModal = document.getElementById("passign-systemAdmin-ankerTag") as HTMLElement;
    practiceAssignModal.click();
  }

  AssignSysTemAdminForm = (formval) =>{
    console.log(formval);
    console.log(this.assignPractice);
    if(!formval.susers) {
      alert("Please choose a user to assign");
      return false;
    }
    if(!formval.upatients || (formval.upatients && formval.upatients.length == 0)) {
      alert("Please choose patients to assign");
      return false;
    }

    let params = {
      "usersub": formval.susers,
      "userpull_id": this.assignPractice.key,
      "patients": formval.upatients
    };

    this.screen.style.display = 'block';
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/assignadmin', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      if(response.status == "success") {
        let patientAssignModal = document.getElementById("passign-systemAdmin-modal-close-button") as HTMLElement;
        patientAssignModal.click();
        this.adminAssign("", "open", 'passign-systemAdmin-ankerTag');
        // alert("User assigned successfully");
        // this.screen.style.display = 'none';
      }
      else {
        alert(response.message);
        this.screen.style.display = 'none';
      }
    },
    async (error: any) => {
      Auth.signOut();
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  AddSysTemAdmin = () =>{
    console.log("AddSysTemAdmin");

    this.AddSystemAdminemailInvalid = false;
    this.AddSystemAdminEmailError   = '';
    this.AddSystemAdminPasswordInvalid     = false;
    this.AddSystemAdminPasswordError       = '';
    this.AddSystemAdminPracticeInvalid     = false;
    this.AddSystemAdminPracticeError       = '';

    let practiceGraphModal = document.getElementById("add-systemAdmin-ankerTag") as HTMLElement;
    practiceGraphModal.click();

    setTimeout (() => {
      this.AddSystemAdminmask = "1";
    }, 500);
  }

  EditSystemAdminmask:string = '';
  AddSystemAdminmask = "";

  AddSysTemAdminForm = (form) =>{
    console.log("AddSysTemAdminForm");
    console.log(form);

    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if(!form.email || typeof form.email === 'undefined' || !form.email.match(emailPattern)){
      this.AddSystemAdminemailInvalid = true;
      this.AddSystemAdminEmailError   = 'Invalid email.';
    }else{
      this.AddSystemAdminemailInvalid = false;
      this.AddSystemAdminEmailError   = '';
    }

    if(!form.password || typeof form.password === 'undefined' || form.password==''){
      this.AddSystemAdminPasswordInvalid     = true;
      this.AddSystemAdminPasswordError       = 'Please enter password';
    }else{
      this.AddSystemAdminPasswordInvalid     = false;
      this.AddSystemAdminPasswordError       = '';
    }

    if(!form.practice || typeof form.practice === 'undefined' || form.practice==''){
      this.AddSystemAdminPracticeInvalid     = true;
      this.AddSystemAdminPracticeError       = 'Please choose Practice';
    }else{
      this.AddSystemAdminPracticeInvalid     = false;
      this.AddSystemAdminPracticeError       = '';
    }

    if(this.AddSystemAdminemailInvalid == false && this.AddSystemAdminPasswordInvalid == false && this.AddSystemAdminPracticeInvalid == false){
      console.log("all ok");

      let params = {
        "email": form.email,
        "password": form.password,
        "userpull_id": "",
        "manageuser": "",
        "userpulls": form.practice.join(","),
        "mask": form.mask
      };

      this.screen.style.display = 'block';
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addadmin', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        this.screen.style.display = 'none';
        if(response.status == "success") {
          let practiceGraphModal = document.getElementById("add-systemAdmin-modal-close-button") as HTMLElement;
          practiceGraphModal.click();

          alert("Admin created successfully");
          this.getAdminList();
        }
        else {
          alert(response.message);
        }
      },
      async (error: any) => {
        Auth.signOut();
                localStorage.removeItem('AUTH');
                localStorage.removeItem('AUTH-LIFE');
                localStorage.removeItem('AUTH-PROFILE-PIC');
                localStorage.removeItem('USER-DETAILS');
                this.screen.style.display = 'none';
                this.router.navigateByUrl('/sign-in');
      });
    }
  }

  adminEdit = (admin) =>{
    console.log("adminEdit");
    console.log(admin);

    let practiceGraphModal = document.getElementById("edit-systemAdmin-ankerTag") as HTMLElement;
    practiceGraphModal.click();

    this.EditSystemAdmin = admin;

    this.EditSystemAdminPracticeInvalid     = false;
    this.EditSystemAdminPracticeError       = '';

    this.EditSystemAdminEmail = admin.email;
    this.EditSystemAdminpractice = admin.userpulls.split(',');

    this.EditSystemAdminmask = admin.mask;

    
  }

  EditSysTemAdminForm = (form) =>{
    console.log("EditSysTemAdminForm");
    console.log(form);

    if(!form.practice || typeof form.practice === 'undefined' || form.practice==''){
      this.EditSystemAdminPracticeInvalid     = true;
      this.EditSystemAdminPracticeError       = 'Please choose Practice';
    }else{
      this.EditSystemAdminPracticeInvalid     = false;
      this.EditSystemAdminPracticeError       = '';
    }

    if(this.EditSystemAdminPracticeInvalid == false){
      console.log("all ok");

      let params = {
        "email": this.EditSystemAdmin.email,
        "id": this.EditSystemAdmin.id,
        "userpulls": form.practice.join(","),
        "mask": form.mask
      };

      this.screen.style.display = 'block';
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/editadmin', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        this.screen.style.display = 'none';
        if(response.status == "success") {
          let practiceGraphModal = document.getElementById("edit-systemAdmin-modal-close-button") as HTMLElement;
          practiceGraphModal.click();

          alert("Admin edit successfully");
          this.getAdminList()
        }
        else {
          alert(response.message);
        }
      },
      async (error: any) => {
        Auth.signOut();
                localStorage.removeItem('AUTH');
                localStorage.removeItem('AUTH-LIFE');
                localStorage.removeItem('AUTH-PROFILE-PIC');
                localStorage.removeItem('USER-DETAILS');
                this.screen.style.display = 'none';
                this.router.navigateByUrl('/sign-in');
      });
    }
  }

  adminDelete = (admin) =>{
    if(confirm("Do you want to delete this Admin?")) {
      let params = {
        id: admin['id'],
        sub: admin['sub']
      };
      console.log(params);
      let userClass = this;
      userClass.screen.style.display = 'block';
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/deleteadmin', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          console.log(response);
          userClass.screen.style.display = 'none';
          if(response.status == "success") {
            alert("Admin deleted successfully");
            this.unassignpusr(admin, '', 'user');
            this.getAdminList()
            // location.reload();
          }
          else {
            alert(response.message);
          }
        },
        async (error: any) => {
          Auth.signOut();
                  localStorage.removeItem('AUTH');
                  localStorage.removeItem('AUTH-LIFE');
                  localStorage.removeItem('AUTH-PROFILE-PIC');
                  localStorage.removeItem('USER-DETAILS');
                  userClass.screen.style.display = 'none';
                  userClass.router.navigateByUrl('/sign-in');
        });
    }
  }

  formatDateMSlash = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('/');
  }

  formatDateM = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('-');
  }

  formatUTCDBDateTime = (date) => {
    var d = new Date(date),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getUTCFullYear(),
      hours = d.getUTCHours(),
      minutes = d.getUTCMinutes(),
      seconds = d.getUTCSeconds();
      var strhour = ''+ hours;
      var strminute = '' + minutes;
      var strsecond = '' + seconds;
      if(hours < 10){
        strhour = '0' + hours;
      }
      if(minutes < 10){
        strminute = '0' + minutes;
      }
      if(seconds < 10){
        strsecond = '0' + seconds;
      }
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-')+" "+strhour+":"+strminute+":"+strsecond;
  }


  validateIotSearchForm = ( SearchForm ) =>{
    console.log(SearchForm);

    var valid = true;

    var Iotdeviceid = "";
    if(SearchForm.Iotdeviceid != undefined && SearchForm.Iotdeviceid != "") {
      Iotdeviceid = SearchForm.Iotdeviceid;
    }

    var Iotpractice = "";
    if(SearchForm.Iotpractice != undefined && SearchForm.Iotpractice != "") {
      Iotpractice = SearchForm.Iotpractice;
    }

    var Iotdevice = "";
    if(SearchForm.Iotdevice != undefined && SearchForm.Iotdevice != "") {
      Iotdevice = SearchForm.Iotdevice;
    }

    if(Iotdeviceid == "" && Iotpractice == "" && Iotdevice == "") {
      alert("Please enter DeviceID or Practice and Device");
      valid = false;
    }
    
    if(valid){
      if(Iotpractice != "" && (Iotdeviceid != "" || Iotdevice != "")){
        valid = true;
      }else if (Iotpractice != ""){
        alert("Please enter DeviceID or Device");
        valid = false;
      }
    }

    if(valid){
      if(Iotdevice != "" && (Iotpractice != "" || Iotdeviceid != "")){
        valid = true;
      }else if (Iotdevice != ""){
        alert("Please enter DeviceID or Practice");
        valid = false;
      }
    }

    if(valid) {
      let params = new HttpParams({
        fromObject: {
          practice: Iotpractice,
          device: Iotdevice,
          deviceid: Iotdeviceid,
          fromdate: this.formatUTCDBDateTime(this.myPrevMonthDate4+" 00:00:00"),
          todate: this.formatUTCDBDateTime(this.myDate4+" 23:59:59")
        }
      });
      this.screencontent.innerHTML = "";
      this.screen.style.display = 'block';
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/devicesearchallpractice', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        this.screen.style.display = 'none';
        if(response.status === "success") {
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);

          
          if(responsedata.length > 0) {
            //copy From Patient
            for (var j = 0; j < responsedata.length; j++) {
              
              responsedata[j]['UnAssignProviderList'] = [];
              
              // console.log(responsedata[j]['UnAssignProviderList']);
              if(responsedata[j]['gender']) {
                let str = responsedata[j]['gender'];
                // responsedata[j]['gender'] = str.charAt(0).toUpperCase() + str.slice(1);
                responsedata[j]['gender'] = str.charAt(0).toUpperCase();
              }
              responsedata[j].masked_phone_number="";
              if(responsedata[j].phone_number && responsedata[j].phone_number.length > 0){
                var phonestr = responsedata[j].phone_number.toString();
                responsedata[j].masked_phone_number = this.make_number_mask(phonestr);
              }
              if(responsedata[j]['birthdate']) {
                responsedata[j]['birthdate']=this.formatDateMSlash(responsedata[j]['birthdate']+ " 00:00:00");
                responsedata[j]['masked_birthdate'] = this.make_number_mask(this.formatDateMSlash(responsedata[j]['birthdate']+ " 00:00:00").toString(),4);
                let timeDiff = Math.abs(Date.now() - new Date(responsedata[j]['birthdate']).getTime());
                let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
                responsedata[j]['age']=age;
              }

              
              if(responsedata[j]['DTB'] && responsedata[j]['DTB'] != "0000-00-00 00:00:00") {
                responsedata[j]['DTBstr'] = this.formatDateMSlash(responsedata[j]['DTB'].replaceAll('-','/') + " UTC");
              }
              else {
                responsedata[j]['DTBstr'] = "";
              }

              if(responsedata[j]['dataTransmissionDate'] && responsedata[j]['dataTransmissionDate'] != "0000-00-00 00:00:00") {
                responsedata[j]['transdate'] = this.formatDateMSlash(responsedata[j]['dataTransmissionDate'].replaceAll('-','/') + " UTC");
              }
              else {
                responsedata[j]['transdate'] = "";
              }
              if(responsedata[j].Alerts) {
                for (var i = 0; i < responsedata[j].Alerts.length; i++) {
                  if(responsedata[j].Alerts[i]) {
                    responsedata[j].Alerts[i]['timestamp'] = this.formatDateM(responsedata[j].Alerts[i]['timestamp']);
                    // var settingjsonarr = responsedata[j].Alerts[i]['alert_setting'].trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
                    var settingjsonarr = responsedata[j].Alerts[i]['alert_setting'].trim().split(" ");
                    var settingjson = "";
                    for(var k = 0; k < settingjsonarr.length; k++) {
                      if(settingjsonarr[k].trim() != '') {
                        if(settingjson) {
                          if(settingjsonarr[k].trim() != ":" && settingjsonarr[k].trim().slice(-1) != '"') {
                            settingjson = settingjson + settingjsonarr[k].trim() + ',';
                          }
                          else {
                            settingjson = settingjson + settingjsonarr[k].trim();
                          }
                        }
                        else {
                          settingjson = settingjson + settingjsonarr[k].trim();
                        }
                      }
                    }
                    if(settingjson.slice(-1) == ",") {
                      settingjson = settingjson.slice(0, -1);
                    }
                    settingjson = '{' + settingjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
                    // console.log(settingjson);
                    var detailjsonarr = responsedata[j].Alerts[i]['detailed_value'].trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
                    var detailjson = "";
                    for(var k = 0; k < detailjsonarr.length; k++) {
                      if(detailjsonarr[k].trim() != '') {
                        if(detailjson) {
                          detailjson = detailjson + ',' + detailjsonarr[k].trim();
                        }
                        else {
                          detailjson = detailjson + detailjsonarr[k].trim();
                        }
                      }
                    }
                    var detailjson = '{' + detailjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
                    if(responsedata[j].Alerts[i]['device_name'].trim() == "BPM" || responsedata[j].Alerts[i]['device_name'].trim() == "Oximeter" || responsedata[j].Alerts[i]['device_name'].trim() == "Weight" || responsedata[j].Alerts[i]['device_name'].trim() == "Blood Glucose" || responsedata[j].Alerts[i]['device_name'].trim() == "Thermometer") {
                      console.log(settingjson);
                      responsedata[j].Alerts[i]['alert_setting'] = JSON.parse(settingjson);
                      responsedata[j].Alerts[i]['detailed_value'] = JSON.parse(detailjson);
                      if(responsedata[j].Alerts[i]['detailed_value']['weight']) {
                        responsedata[j].Alerts[i]['detailed_value']['weight'] = Math.floor(responsedata[j].Alerts[i]['detailed_value']['weight'])+" lbs";
                      }
                      if(responsedata[j].Alerts[i]['detailed_value']['bmi']) {
                        responsedata[j].Alerts[i]['detailed_value']['bmi'] = Math.floor(responsedata[j].Alerts[i]['detailed_value']['bmi']);
                      }
                    }
                  }
                }
              }
              if(responsedata[j].DeviceEducation) {
                for (var i = 0; i < responsedata[j].DeviceEducation.length; i++) {
                  responsedata[j].DeviceEducation[i]['timestamp'] = this.formatDateM(responsedata[j].DeviceEducation[i]['timestamp']);
                }
              }
              if(responsedata[j].TransmissionDate && responsedata[j].TransmissionDate.StartDate) {
                responsedata[j].TransmissionDate.StartDate = this.formatDateMSlash(responsedata[j].TransmissionDate.StartDate);
              }
              if(responsedata[j].TransmissionDate && responsedata[j].TransmissionDate.StartDate && responsedata[j].TransmissionDate.EndDate) {
                responsedata[j].TransmissionDate.EndDate = this.formatDateMSlash(responsedata[j].TransmissionDate.EndDate);
              }
              
              
              responsedata[j]['sub_btoa'] = btoa(responsedata[j]['sub']);
              responsedata[j]['userdevices'] = responsedata[j]['getuserDevices'].join(", ");
              
            }

            this.IotPatientList = responsedata;
            this.IotPatientListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "first-table") {
                  dtInstance.destroy();
                  this.dtTrigger.next();
                }
              });
              }
              else {
                this.dtTrigger.next();
              }
            });
          }
          else {
            this.IotPatientList = responsedata;
            this.IotPatientListLength = responsedata.length;
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
              if(dtElement.dtInstance) {
              dtElement.dtInstance.then((dtInstance: any) => {
                if(dtInstance.table().node().id == "first-table") {
                  dtInstance.destroy();
                  this.dtTrigger.next();
                }
              });
              }
              else {
                this.dtTrigger.next();
              }
            });
          }
        }
      },
      async (error: any) => {
        // this.screen.style.display = 'none';
        console.log(error);
        this.screencontent.innerHTML = "";
        if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
          alert("Request timeout. Please try again.");
          this.screen.style.display = 'none';
        }
        else {
          await Auth.signOut({
            global: true
          });
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }

  resetIotSearchForm = () => {
    console.log("resetIotSearchForm");

    this.Iotdevice = "";
    this.Iotpractice = "";
    this.Iotdeviceid = "";
  }

  validateTrendSearchForm = (form) => {
    console.log("validateTrendSearchForm");

    console.log(form);

    let params = new HttpParams({
      fromObject: {
        practice: form.Trendpractice,
        date: form.date
      }
    });
    this.screen.style.display = 'block';
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getallpracticetrend', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status === "success") {
        let responsedata = JSON.parse(response.data);

        console.log(responsedata)

        if (responsedata.length > 0){
          this.TrendList = responsedata;
          this.TrendListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "second-table") {
                dtInstance.destroy();
                this.dtTrigger1.next();
              }
            });
            }
            else {
              this.dtTrigger1.next();
            }
          });
        }
        else {
          this.TrendList = responsedata;
          this.TrendListLength = responsedata.length;
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "second-table") {
                dtInstance.destroy();
                this.dtTrigger1.next();
              }
            });
            }
            else {
              this.dtTrigger1.next();
            }
          });
        }
      }
    },
    async (error: any) => {
      this.screen.style.display = 'none';
      console.log(error);
    });

  }

  resetTrendSearchForm = () => {
    console.log("resetTrendSearchForm");

    this.myDateTrend = "";
    this.Trendpractice = "";
  }

  GotoPracticeTrend = (pull, pullname) => {
    console.log("GotoPracticeTrend");

    console.log(pull);

    this.GraphPracticeName = pullname;

    this.LineChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.LinechartColors = []
    this.LinechartLabels = []

    let params = new HttpParams({
      fromObject: {
        practice: pull
      }
    });
    this.screen.style.display = 'block';
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpracticetrend', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status === "success") {
        let responsedata = JSON.parse(response.data);

        console.log(responsedata);

        var active_patient = [];
        var upload_patient = [];
        var missing_patient = [];
        var alert_patient = [];

        let labels = [];

        for(var temppractice of responsedata){
          active_patient.push(temppractice.active_patient);
          upload_patient.push(temppractice.upload_patient);
          missing_patient.push(temppractice.missing_patient);
          alert_patient.push(temppractice.alert);

          labels.push(temppractice.date);

        }

        this.LineChartData =   [{ 'data':  active_patient, 'label': 'Active Patient Count', 'fill': true, 'lineTension': 0 }];
        this.LineChartData.push({ 'data': upload_patient, 'label': 'Daily Data Uploads by Patient Count', 'fill': true, 'lineTension': 0 });
        this.LineChartData.push({ 'data': missing_patient, 'label': 'Daily upload Missing Patient Count', 'fill': true, 'lineTension': 0 });
        this.LineChartData.push({ 'data': alert_patient, 'label': 'Daily upload alert Count', 'fill': true, 'lineTension': 0 });

        //COLOR
        this.LinechartColors.push({ borderColor: 'rgba(0, 188, 158)', backgroundColor: 'rgba(167, 204, 213, .6)' })        
        this.LinechartColors.push({ borderColor: 'rgba(51, 51, 51)', backgroundColor: 'rgba(235, 187, 164, .6)' })
        this.LinechartColors.push({ borderColor: 'rgba(0, 156, 215)', backgroundColor: 'rgba(200, 230, 245, .6)' })
        this.LinechartColors.push({ borderColor: 'rgba(248, 59, 70)', backgroundColor: 'rgba(230, 160, 180, .6)' })

        this.LinechartLabels = labels;

        let practiceGraphModal = document.getElementById("practice-trend-graph-ankerTag") as HTMLElement;
        practiceGraphModal.click();

      }
    },
    async (error: any) => {
      this.screen.style.display = 'none';
      console.log(error);
    });
  }

  CollectRealtimeDataButtonClick = () => {
    console.log("CollectRealtimeDataButtonClick");

    
    this.screen.style.display = 'block';
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/collectpracticedata', { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status === "success") {
        alert("Save successfully");
      }
    },
    async (error: any) => {
      this.screen.style.display = 'none';
      console.log(error);
    });
  }

  GotoReportAlert = (Trend) => {
    console.log("GotoReportAlert");

    localStorage.setItem('userpull_id', Trend.userpull_id);

    localStorage.setItem('gotoalertreport', Trend.date);
    
    // this.router.navigate(["/dashboard/"]);
    window.open('/dashboard/', '_blank');
  }

  patientDetails = (patient) => {

    localStorage.setItem('userpull_id', patient.userpull_id);

    console.log(patient.sub_btoa)
    this.router.navigate(["/patient-detail/" + patient.sub_btoa]);

    // this.router.navigateByUrl('/patient-detail/' + patient.sub_btoa);
  }

  patientDetailsActivity = (patient) => {
    console.log("patientDetailsActivity")
    console.log(patient)

    let patientSmsModal = document.getElementById("activity-patient-modal-close-button") as HTMLElement;
        patientSmsModal.click();

    localStorage.setItem('userpull_id', patient.userpull_id);

    console.log(patient.sub_btoa)
    window.open("/patient-detail/" + patient.sub_base64, "AdminPatientProfile");
    // this.router.navigate(["/patient-detail/" + patient.sub_base64]);

    // this.router.navigateByUrl('/patient-detail/' + patient.sub_btoa);
  }

  ExceptionpatientDetails = (patient) => {

    localStorage.setItem('userpull_id', patient.userpull_id);
    this.router.navigate(["/patient-detail/" + patient.sub_base64]);

    // this.router.navigateByUrl('/patient-detail/' + patient.sub_btoa);
  }

  BillingEligibilityExceptionRead= (row) => {
    this.screen.style.display = 'block';

    let Params = {
      id: row.id
    };

    


    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/billingeligibilityexceptionread', Params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe( async (response: any) => {
      console.log("response");
      console.log(response)
      this.screen.style.display = 'none';
      if (response.status === "success") {
        alert("exception read successfully");
        
        this.validateEligibilityExceptionForm();
      }else{
        alert(response.message);
      }
    },
    async (error: any) => {
      console.log(error);
      alert("Could not read exception. Please try again.");
      this.screen.style.display = 'none';
    });

  }

  

  patientInventory = (patient) => {
    console.log("patientInventory");

    console.log(patient);

    this.currentPatientSub = patient.userpull_id;

    this.inventoryItemList = [];
    this.inventoryItemOptionList = [];

    this.inventoryType = "";
    this.inventoryItem = 0;
    this.inventorySerial = "";
    this.inventoryNewSerial = "";
    this.inventoryItemOption = "";

    this.inventoryTypeError = false;
    this.inventoryItemError = false;
    this.inventoryOptionError = false;
    this.inventorySerialError = false;
    this.inventoryNewSerialError = false;
    this.inventoryisSerial = false;
    this.inventoryisQuantity = false;
    this.inventoryQuantityError = false;

    this.patientItemList = [];

    this.addInventoryPatient = patient

    let getInventoryClass = this;
    getInventoryClass.screen.style.display = 'block';

    let params = new HttpParams({
      fromObject: {
        userpull_id: this.currentPatientSub,
        patient_sub: patient.sub
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpatientstock', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response)
      getInventoryClass.screen.style.display = 'none';
      if (response.status === "success") { 
        this.patientItemList = JSON.parse(response.data);

        let patientSmsModal = document.getElementById("patient-add-inventory-ankerTag") as HTMLElement;
        patientSmsModal.click();   
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });

  }

  addInventoryTypeChange = (typeTemp) => {
    this.inventoryTypeError = false;
    this.inventoryType = typeTemp;

    this.inventoryItemOptionList = [];

    this.inventoryItem = 0;
    this.inventorySerial = "";
    this.inventoryNewSerial = "";
    this.inventoryItemOption = "";

    this.inventoryItemError = false;
    this.inventoryOptionError = false;
    this.inventorySerialError = false;
    this.inventoryNewSerialError = false;
    this.inventoryisSerial = false;
    this.inventoryisQuantity = false;
    this.inventoryQuantityError = false;

    this.loadInventoryItem();
  }

  loadInventoryItem = () => {
    this.inventoryItemList = [];
    
    let params = new HttpParams({
      fromObject: {
        userpull_id: this.currentPatientSub,
      }
    });
    
    let getInventoryClass = this;
    getInventoryClass.screen.style.display = 'block';
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getinventoryitem', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response)
      getInventoryClass.screen.style.display = 'none';
      if (response.status === "success") {  
        let tempItems = JSON.parse(response.data);
        
        for (var tempItem of tempItems){
          var isPresent = this.patientItemList.some(function(el){ return el.id === tempItem.id});
          if (this.inventoryType != 'Issue'){
            if (isPresent){
              this.inventoryItemList.push(tempItem)
            }
          }else if(this.inventoryType == 'Issue'){
            if (isPresent == false){
              this.inventoryItemList.push(tempItem)
            }else if (tempItem.quantity === 1){
              this.inventoryItemList.push(tempItem)
            }
          }
        }

        console.log(this.inventoryItemList)
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }  

  addInventoryItemChange = (item) => {
    console.log(item)

    if (item >= 0) {
      this.inventoryItemError = false;
      this.inventoryItem = item;
    }else{
      this.inventoryItemError = true;
    }


    for(var temp of this.inventoryItemList){
      if (temp.id == item){
        if (temp.serial === 1){
          this.inventoryisSerial = true;
          if(this.inventoryType != 'Issue'){            
            for(var temppatientItem of this.patientItemList){
              if (temppatientItem.id == item){
                this.inventorySerial = temppatientItem.serial;
              }
            }
          }
        }else{
          this.inventoryisSerial = false;
        }
        if (temp.quantity === 1){
          this.inventoryisQuantity = true;
          if(this.inventoryType != 'Issue'){            
            for(var temppatientItem of this.patientItemList){
              if (temppatientItem.id == item){
                // this.InventoryQuantity = temppatientItem.quantity;
                this.InventoryMaxQuantity = temppatientItem.quantity;
              }
            }
          }
        }else{
          this.inventoryisQuantity = false;
        }
      }
    }

    this.loadInventoryOption();
    
  }

  loadInventoryOption = () => {
    this.inventoryItemOptionList = [];
    if (this.inventoryType !== "Issue" &&  this.inventoryItem > 0) {

      let getInventoryClass = this;
      getInventoryClass.screen.style.display = 'block';    

      let params = new HttpParams({
        fromObject: {
          id: this.inventoryItem.toString(),
          type: this.inventoryType
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getinventoryitemoption', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response)
        getInventoryClass.screen.style.display = 'none';
        if (response.status === "success") {
          this.inventoryItemOptionList = JSON.parse(response.data);
          // this.inventoryItemOptionList.unshift({ id: 0, name: "Select Option" })
        }
      },
      async (error: any) => {
        await Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.screen.style.display = 'none';
        this.router.navigateByUrl('/sign-in');
      });    
    }
  }

  addInventoryQuantityChange = (quantitytemp) => {
    console.log("addInventoryQuantityChange")
    console.log(quantitytemp)
    if (quantitytemp > 0){
      this.inventoryQuantityError = false;
      if (quantitytemp > this.InventoryMaxQuantity){
        this.inventoryQuantityError = true;
        this.inventoryQuantityErrorStr = "Quantity is less than equal to Issued Quantity.";
      }else{
        this.inventoryQuantityError = false;
      }
    }else{
      this.inventoryQuantityError = true;
      this.inventoryQuantityErrorStr = "Quantity is required.";
    }
  }

  addInventoryOptionChange = (optionTemp) => {
    console.log("addInventoryOptionChange")
    console.log(optionTemp)
    if (optionTemp > 0){
      this.inventoryOptionError = false;
    }else{
      this.inventoryOptionError = true;
    }
  }

  addInventorySerialChange = (serialtemp) => {
    console.log("addInventorySerialChange")
    this.inventorySerial = serialtemp;
    if (this.inventoryType === "Issue") {
      if (serialtemp !== ''){
        this.inventorySerialError = false;
      }else{
        this.inventorySerialError = true;
      }
    }
  }

  addInventoryNewSerialChange = (serialtemp) => {
    console.log("addInventoryNewSerialChange")
    this.inventoryNewSerial = serialtemp;
    if (serialtemp !== ''){
      this.inventoryNewSerialError = false;
    }else{
      this.inventoryNewSerialError = true;
    }
  }

  patientAddInventorySubmitButtonClick = async (addInventoryForm) => {
    console.log("patientAddInventorySubmitButtonClicks")
    console.log(addInventoryForm)
    console.log(this.addInventoryPatient)

    let usersub = ""

    if (localStorage.getItem('USER-DETAILS')) {
  		let userDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));

      if(userDetails.sub){
  			usersub = userDetails.sub;
      }
    }

    if (addInventoryForm.inventoryType !== '' && addInventoryForm.inventoryType !== undefined){
      this.inventoryTypeError = false;
    }else{
      this.inventoryTypeError = true;
    }
    

    if (addInventoryForm.inventoryItem > 0) {
      this.inventoryItemError = false;
    }else{
      this.inventoryItemError = true;
    }

    if (this.inventoryisSerial){
      if (this.inventoryType === "Issue") {
        if (addInventoryForm.inventorySerial !== '' && addInventoryForm.inventorySerial !== undefined){
          this.inventorySerialError = false;
        }else{
          this.inventorySerialError = true;
        }
      }

      if (this.inventoryType === "Exchange") {
        if (addInventoryForm.inventoryNewSerial !== '' && addInventoryForm.inventoryNewSerial !== undefined){
          this.inventoryNewSerialError = false;
        }else{
          this.inventoryNewSerialError = true;
        }
      }else{
        this.inventoryNewSerialError = false;
      }
    }else{
      this.inventorySerialError = false;
      this.inventoryNewSerialError = false;
    }

    // if (addInventoryForm.inventoryType !== '' && addInventoryForm.inventoryType !== "Issue"){
    //   if (Number(addInventoryForm.inventoryItemOption) >= 0) {
    //     this.inventoryOptionError = false;
    //   }else{
    //     this.inventoryOptionError = true;
    //   }
    // }else{
    //   this.inventoryOptionError = false;
    // }

    if (this.inventoryItemOption != ''){
      this.inventoryOptionError = false;
    }else{
      this.inventoryOptionError = true;
      if (this.inventoryType == "Issue"){
        this.inventoryOptionError = false;
      }
    }

    let InventoryQuantity = 1;

    if (this.inventoryisQuantity){
      if (addInventoryForm.inventoryQuantity > 0){
        this.inventoryQuantityError = false;
        if (addInventoryForm.inventoryQuantity > this.InventoryMaxQuantity){
          this.inventoryQuantityError = true;
          this.inventoryQuantityErrorStr = "Quantity is less than equal to Issued Quantity.";
        }else{
          this.inventoryQuantityError = false;
        }
        InventoryQuantity = addInventoryForm.inventoryQuantity
      }else{
        this.inventoryQuantityError = true;
        this.inventoryQuantityErrorStr = "Quantity is required.";
      }
    }else{
      this.inventoryQuantityError = false;
    }

    if (this.inventoryItemError == false && this.inventoryOptionError == false && this.inventorySerialError == false && this.inventoryNewSerialError == false && this.inventoryQuantityError == false) {

      let addInventoryParams = {
        id_items: addInventoryForm.inventoryItem,
        id_options: addInventoryForm.inventoryItemOption,
        inventory_type: this.inventoryType,
        user_id: usersub,
        user_type: "user",
        userpull_id: this.currentPatientSub,
        patient_id: this.addInventoryPatient.sub,
        serial: addInventoryForm.inventorySerial,
        new_serial: addInventoryForm.inventoryNewSerial,
        userauth: localStorage.getItem('AUTH-TOKEN'),
        quantity: InventoryQuantity
      };

      if ((this.inventoryType == 'Issue' || this.inventoryType == 'Exchange') && (addInventoryForm.inventoryItem != '1' && addInventoryForm.inventorySerial !== '' && addInventoryForm.inventorySerial !== undefined)){
        let iGlocoseParams = {
          api_key: "E54E547C-5E9D-4103-AF25-0EB971E93BDF-1623765760"
        };

        if (this.inventoryType == 'Issue'){
          iGlocoseParams['device_id'] = addInventoryForm.inventorySerial;
        }else if (this.inventoryType == 'Exchange'){
          iGlocoseParams['device_id'] = addInventoryForm.inventoryNewSerial;
        }

        this.screen.style.display = 'block';

        
        this.httpClient.post('https://api.iglucose.com/devices/validate/', iGlocoseParams)
        .subscribe( (response: any) => {
          this.screen.style.display = 'none';
          console.log("response");
          console.log(response)

          if (response.is_valid == true){
            addInventoryParams['item_active'] = 1;
            this.patientAddInventoryApiCall(addInventoryParams);
          }else if(response.is_valid == false){
            var confirmMsg = "This device is not active  you want to continue";
            if(confirm(confirmMsg)) {
              addInventoryParams['item_active'] = 0;
              this.patientAddInventoryApiCall(addInventoryParams);
            }
          }

        },(error: any) => {
          console.log(error);
          this.screen.style.display = 'none';          
        });
      }else{
        addInventoryParams['item_active'] = 1;
        this.patientAddInventoryApiCall(addInventoryParams);
      }      
      
    }
  }

  patientAddInventoryApiCall = (addInventoryParams) => {
    console.log("patientAddInventoryApiCall");



    console.log("final api call call");

    console.log("final api parameter", addInventoryParams);



    this.screen.style.display = 'block';

    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addinventory', addInventoryParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe( async (response: any) => {
      console.log("response");
      console.log(response)
      this.screen.style.display = 'none';
      if (response.status === "success") {
        alert("Inventory save successfully");
        let patientSmsModal = document.getElementById("patient-add-inventory-modal-close-button") as HTMLElement;
        patientSmsModal.click();
        
        this.patientInventory(this.addInventoryPatient);
      }else{
        alert(response.message);
      }
    },
    async (error: any) => {
      console.log(error);
      alert("Could not save inventory. Please try again.");
      this.screen.style.display = 'none';
    });

  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { empty } from 'rxjs';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.css']
})
export class ConsentComponent implements OnInit {

  body: HTMLElement = document.getElementById('body') as HTMLElement;
  screen: HTMLElement = document.getElementById('screen') as HTMLElement;
  processing: HTMLElement = document.getElementById('cover') as HTMLElement;

  practiceId: any;
  subId: any;

  isValidated: boolean = false;
  errordob: string = '';

  consentdata: any;
  consentHtml: string = '';

  patientdobdate: any;
  sh: any;
  isChecked: boolean = true;
  relation: any;
  sig: any;
  sigbck: any;
  thisDate: any;

  ipAddress: string = '';

  updating:boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private httpClient: HttpClient) {
    let todaydate = new Date(); 
    this.thisDate = this.formatDate(todaydate);
  }

  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  formatUTCDateCustom = (date) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const days = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat'
    ]
    var d = new Date(date),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getUTCFullYear(),
      hour = d.getUTCHours(),
      minute = d.getUTCMinutes();
    
    var time = "AM";
    if(hour >= 12) {
      time = "PM";
      if(hour > 12) {
        hour = hour - 12;
      }
    }
    var strhour = ''+ hour;
    if(hour == 0) {
      strhour = '12';
    }
    else if(hour < 10){
      strhour = '0' + hour;
    }
    var strminute = '' + minute;
    if(minute < 10){
      strminute = '0' + minute;
    }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    // return [year, month, day].join('-');
    return strhour+":"+strminute+" "+time+" "+days[d.getUTCDay()]+" "+day+" "+months[d.getUTCMonth()]+" "+year;
  }

  getIPAddress()
  {
    this.httpClient.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
    });
  }

  ngOnInit() {
    this.getIPAddress();
    this.body.classList.add('login-page');
    this.practiceId = atob(this.route.snapshot.paramMap.get('practice'));
    this.subId = atob(this.route.snapshot.paramMap.get('sub'));
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: this.practiceId,
        patient_sub: this.subId
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getconsent', { params })
    .subscribe((response: any) => {
      console.log(this.ipAddress);
      if (response.status === "success") {
        this.consentdata = JSON.parse(response.data);
        if(this.consentdata['consent_header_html']){
          this.consentHtml = "";
          // this.patientdobdate = this.consentdata['patient_dob']; // comment to provide by user
          this.sh = 1;
          this.isChecked = true;
          this.relation = "";
          this.sig = this.consentdata['patient_name'];
          this.sigbck = this.consentdata['patient_name'];
        }
        else {
          this.consentHtml = "<p><h4>"+response.message+"</h4></p>";
        }
      }
      this.screen.style.display = 'none';
    },
    async (error: any) => {
      console.log(error);
    });
  }

  changePPA = () =>{
    this.relation = "";
    if(this.sh == 1){
      this.sig = this.sigbck;
    }
    else {
      this.sig = "";
    }
  }

  validatePatientForm = (data) =>{
    if(data.dobdate == this.consentdata['patient_dob']){
      this.isValidated = true;
      this.errordob = "";
    }
    else {
      this.errordob = "Invalid data provided";
    }
  }

  validateConsentPatientForm = ( consentData, btnpress ) =>{
    console.log(consentData);
    consentData['ipAddress'] = this.ipAddress;
    console.log(this.consentdata);
    if(typeof consentData.relation != 'undefined' && consentData.relation == "") {
      alert("Please provide relationship.");
      return false;
    }
    if(!consentData.signature || typeof consentData.signature === 'undefined') {
      alert("Please put signature.");
      return false;
    }
    else {
      let userData = [];
      let todaydate = new Date();
      let date_value = this.formatDate(todaydate);;
      let con_date = date_value.split("-");
      let Date_format = con_date[1]+'/'+con_date[2]+'/'+con_date[0]+' 00:00:00';
      let formatted_date = this.formatUTCDateCustom(Date_format);
      userData.push({Name:"custom:consent",Value:"1"})
      userData.push({Name:"custom:consentDate",Value:formatted_date})

      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        group: "Patient",
        sub: this.subId,
        consent_stat: btnpress,
        consent_details: consentData,
        consent_content: this.consentdata
      };
      
      for(var k = 0; k < userData.length; k++) {
        params[userData[k]['Name']] = userData[k]['Value'];
      }

      console.log(params);

      this.updating = true;
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateconsent', params)
      .subscribe((response: any) => {
        console.log(response);
        this.consentHtml = "<p><h4>Thank you for your response. You may close the browser window now.</h4></p>";
        this.consentdata = {};
      },
      async (error: any) => {
        console.log(error);
        alert("Consent could not be updated. Please try again.");
      });

    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatgptService {

  // private apiUrl = 'https://your-api-gateway-url.execute-api.region.amazonaws.com/your-stage-name';
  private apiUrl = 'https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development';

  constructor(private http: HttpClient) {}

  getResponse(prompt: string, token: string): Observable<any> {
    // let params = new HttpParams({
    //   fromObject: {
    //     prompt: prompt
    //   }
    // });
    let params = {
      prompt: prompt,
      token: token
    };
    // return this.http.get(`${this.apiUrl}/chatgpt`, { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) });
    return this.http.post(`${this.apiUrl}/chatgpt`, params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) });
  }

  // storeRequestResponse(request: string, response: string): Observable<any> {
  //   return this.http.get(`${this.apiUrl}/store?request=${request}&response=${response}`);
  // }
}

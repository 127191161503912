import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-patient-consent',
  templateUrl: './patient-consent.component.html',
  styleUrls: ['./patient-consent.component.css']
})
export class PatientConsentComponent implements OnInit {

  body: HTMLElement = document.getElementById('body') as HTMLElement;
  screen: HTMLElement = document.getElementById('screen') as HTMLElement;
  processing: HTMLElement = document.getElementById('cover') as HTMLElement;

  practiceId: any;
  subId: any;
  templateUniqueId: any;

  isValidated: boolean = false;
  errordob: string = '';

  consentdata: any;
  consentHtml: string = '';

  patientdobdate: any;
  sh: any;
  isChecked: boolean = true;
  relation: any;
  sig: any;
  sigbck: any;
  thisDate: any;

  ipAddress: string = '';

  updating:boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private httpClient: HttpClient) {
    let todaydate = new Date(); 
    this.thisDate = this.formatDate(todaydate);
  }

  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  formatUTCDateCustom = (date) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const days = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat'
    ]
    var d = new Date(date),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getUTCFullYear(),
      hour = d.getUTCHours(),
      minute = d.getUTCMinutes();
    
    var time = "AM";
    if(hour >= 12) {
      time = "PM";
      if(hour > 12) {
        hour = hour - 12;
      }
    }
    var strhour = ''+ hour;
    if(hour == 0) {
      strhour = '12';
    }
    else if(hour < 10){
      strhour = '0' + hour;
    }
    var strminute = '' + minute;
    if(minute < 10){
      strminute = '0' + minute;
    }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    // return [year, month, day].join('-');
    return strhour+":"+strminute+" "+time+" "+days[d.getUTCDay()]+" "+day+" "+months[d.getUTCMonth()]+" "+year;
  }

  getIPAddress()
  {
    this.httpClient.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
    });
  }

  formatDateMSlash = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    //console.log(d.toString());
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('/');
  }

  consentTemplateList=[];
  practicename = "";
  patientdetail = {};

  ngOnInit() {
    // this.body.classList.add('login-page');
    this.practiceId = atob(this.route.snapshot.paramMap.get('practice'));
    this.subId = atob(this.route.snapshot.paramMap.get('sub'));
    this.templateUniqueId = atob(this.route.snapshot.paramMap.get('template'));
    this.screen.style.display = 'block';
    let params = {
      userpull_id: this.practiceId
    };
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getconsenttemplate', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
      console.log(response);
      if(response.status == "success") {
        this.consentTemplateList = JSON.parse(response.data);
        console.log(this.consentTemplateList);
        let params = new HttpParams({
          fromObject: {
            userpull_id: this.practiceId,
            sub: this.subId
          }
        });
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpatientconsent', { params })
        .subscribe((response: any) => {
          this.screen.style.display = 'none';
          this.patientdetail = JSON.parse(response.data);
          this.patientdetail['consentdd'] = this.formatDateMSlash(this.patientdetail['consentDate'].replaceAll('-','/') + " UTC");
          this.patientdetail['dob'] = "";
          if(this.patientdetail['birthdate'] != null) {
            this.patientdetail['dob'] = this.formatDateMSlash(this.patientdetail['birthdate'].replaceAll('-','/') + " 00:00:00");
          }
          this.patientdetail['sig'] = this.patientdetail['firstName'] + " " + this.patientdetail['lastName'];
          if('signature' in this.patientdetail && this.patientdetail['signature'] != null){
            this.patientdetail['sig'] = this.patientdetail['signature'];
          }
          console.log(this.patientdetail);
          for(var i=0; i<this.consentTemplateList.length; i++){
            if(this.consentTemplateList[i]['shortname'] == this.templateUniqueId){
              this.consentHtml = this.consentTemplateList[i]['en-US']['consenttext'];
              if(this.patientdetail['p_language'] != "en-US" && this.patientdetail['p_language'] in this.consentTemplateList[i]){
                this.consentHtml = this.consentTemplateList[i][this.patientdetail['p_language']]['consenttext'];
              }
              this.practicename = this.consentTemplateList[i]['practice'];
              // setTimeout (() => {
              //   window.print();
              //   window.close();
              //   // this.router.navigateByUrl('/patient-detail/'+btoa(this.subId));
              // }, 1000);
            }
          }
        });
      }
    });
  }

}

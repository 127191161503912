import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { ChartsModule } from 'ng2-charts';
import { PubNubAngular } from 'pubnub-angular2';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IfAllowed } from './auth.middleware';
import { HeaderComponent } from './layouts/auth-layout/header/header.component';
import { FooterComponent } from './layouts/auth-layout/footer/footer.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ProviderComponent } from './provider/provider.component';
import { PatientComponent } from './patient/patient.component';

import { DatePipe } from '@angular/common';
import { PatientDetailComponent } from './patient-detail/patient-detail.component';
import { PatientReportComponent } from './patient-report/patient-report.component';
import { ConsentComponent } from './consent/consent.component';
import { AdminComponent } from './admin/admin.component';
import { ProfileComponent } from './profile/profile.component';
import { PatientSymptomComponent } from './patient-symptom/patient-symptom.component';
import { CareplanComponent } from './careplan/careplan.component';
import { ZohochatComponent } from './zohochat/zohochat.component';
import { PatientConsentComponent } from './patient-consent/patient-consent.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    AuthLayoutComponent,
    ProviderComponent,
    PatientComponent,
    PatientDetailComponent,
    PatientReportComponent,
    ConsentComponent,
    AdminComponent,
    ProfileComponent,
    PatientSymptomComponent,
    CareplanComponent,
    ZohochatComponent,
    PatientConsentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    ChartsModule
  ],
  providers: [IfAllowed, DatePipe, PubNubAngular],
  bootstrap: [AppComponent]
})

export class AppModule { }

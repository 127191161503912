import { AfterViewInit, Component, OnInit, ViewChild, ElementRef, OnDestroy, Renderer, ViewChildren, QueryList } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AWS } from '@aws-amplify/core';
import { NgForm } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Auth } from 'aws-amplify';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import * as AWSServices from 'aws-sdk';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as XLSX from 'xlsx';
import * as country_list_data from 'src/environments/country_list.json';
import { async } from '@angular/core/testing';
import { Console } from 'console';
declare var $: any;
declare const Twilio: any;


@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements AfterViewInit, OnInit {
  userGroup: string = '';
  userSub: string = '';
  ismasking : boolean = true;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  @ViewChild('uploadPatient', {static: false})
  myInputVariable: ElementRef;

  @ViewChild('uploadManualTrans', {static: false})
  myInputVariable2: ElementRef;

  has_facility:boolean = false;
  facility_arr: any;
 
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;

  dtOptions: any = {};
  dtOptionsPayer: any = {};

  step: any;
  stepActiveArr = ["Active", "ActiveEducation", "ActiveSettings", "ActiveEligibility", "ActiveNoEducation", "ActiveNoSettings", "ActiveNoEligibility"];

  htmlToAdd: any;
  syncStatus: any;

  htmlToAddSMS: any;
  smsStatus: any;

  htmlToAddConsent: any;
  consentStatus: any;
  consentdata: any;
  consentLang: any;

  logedInUserManagePermission : string = "no";
  defaultdeviceReading: any;
  defaultsettingDetails: any;

  processing: HTMLElement = document.getElementById('cover') as HTMLElement;
  screen: HTMLElement = document.getElementById('screen') as HTMLElement;
  screencontent: HTMLElement = document.getElementById('screencontent') as HTMLElement;
  patientList: any;
  userDetails: any;

  monthDetails:any;

  mySubscription: any;

  myMonth: any;
  myTime: any;

  readingpatient: any;
  nonvalidatedLink:string = '';
  validatedLink:string = '';

  loadListing:boolean = true;
  viewmodalid:string = '';

  providerlist: any;
  languageList: any;
  languageArr: any;
  providercountexceed:boolean = false;

  selectedprovider: any;

  patientFormCountry: any;

  currentDuration: any;
  previousDuration: any; 

  country_list_code: any = (country_list_data as any).default;

  iotPracticeSubs: any;

  practice: string = '';
  autoEmail: boolean = false;

  patientFormEmail: string = '';

  formInvalid:boolean = false;
  formInvalidMsg:boolean = false;
  formInvalidMsgTxt: string = '';
  flashSuccess:boolean = false;
  
  formInvalids:boolean = false;
  formInvalidMsgs:boolean = false;

  givenNameInvalid: boolean = false;
  givenNameError: string = '';

  middleNameInvalid: boolean = false;
  middleNameError: string = '';

  familyNameInvalid: boolean = false;
  familyNameError: string = '';

  emailInvalid: boolean = false;
  emailError: string = '';

  phoneNumberInvalid: boolean = false;
  phoneNumberError: string = ''; 

  facetimeInvalid: boolean = false;
  facetimeError: string = ''; 
  
  birthdateInvalid: boolean = false;
  birthdateError: string = ''; 

  addressInvalid: boolean = false;
  addressError: string = '';

  genderInvalid: boolean = false;
  genderError: string = '';

  heightInvalid: boolean = false;
  heightError: string = '';

  countryInvalid: boolean = false;
  countryError: string = '';

  assignInvalid: boolean = false;
  assignError: string = '';

  patientallselected: boolean = false;
  selectedPatientStatus: boolean = false;
  selectedConsentStatus: boolean = false;

  consentval: boolean = false;
  eduval: boolean = false;

  smsText:String = "";
  smsTextList = [];
  smsTempTextList = [];
  smstype: any;
  smsError = false;
  smsTypeError = false;

  remindersms = false;
  replysms = false;
  replysmsprofile = false;
  profilevalidation:string = "validated";
  smstyperemind15:String = "";
  smstyperemind10:String = "";
  smstyperemind7:String = "";
  smstyperemind5:String = "";
  smstyperemind3:String = "";
  smstyperemind:String = "";
  smstypereply:String = "";
  smsTempTextRemind15:String = "";
  smsTempTextRemind10:String = "";
  smsTempTextRemind7:String = "";
  smsTempTextRemind5:String = "";
  smsTempTextRemind3:String = "";
  smsTempTextRemind:String = "";
  smsTempTextReply:String = "";

  showpatientlang = false;
  patientlang = false;
  patientlangArr = [];
  patlang: any;

  dtSystemAdmin: DataTables.Settings = {};
  dtTrigger: Subject<PatientComponent> = new Subject();
  dtTrigger1: Subject<PatientComponent> = new Subject();
  dtTrigger2: Subject<PatientComponent> = new Subject();
  dtTrigger3: Subject<PatientComponent> = new Subject();
  dtTrigger4: Subject<PatientComponent> = new Subject();

  inventoryTypeList = [{ id: "Issue", name: "Issue New" }, { id: "Return", name: "Return" }, { id: "Exchange", name: "Exchange" }];
  inventoryItemList = [];
  inventoryItemOptionList = [];
  addInventoryPatient: any;
  inventoryType = "";
  inventoryItem = 0;
  inventorySerial = "";
  inventoryNewSerial = "";
  inventoryItemOption = "";
  InventoryMaxQuantity:Number;

  inventoryTypeError = false;
  inventoryItemError = false;
  inventoryOptionError = false;
  inventorySerialError = false;
  inventoryNewSerialError = false;
  inventoryisSerial = false;
  inventoryisQuantity = false;
  inventoryQuantityError = false;
  inventoryQuantityErrorStr = ""

  patientItemList = [];

  errorUploadMsg: string = '';
  successUploadMsg: string = '';

  manualerrorUploadMsg: string = '';
  manualsuccessUploadMsg: string = '';

  twilioEndPoint = "https://3xcxz84v68.execute-api.us-east-1.amazonaws.com";
  twilioEndPointApiKey = "2pdKyWDlxE6KxzVGV8Dsq5cDxcTVuWhZ3k5TCmYe";
  twilioNumber = "";
  twilioConstantNumber = "+14083518666";
  isTwilioEnabled = false;
  clickCallisDisabled = false;
  endButtonShow = true;
  phonecall = true;
  twliotimer = null;

  patientphone:string = '';

  ZohoTicketDate: any;

  usercheckShow = false;
  userclass = false;

  uploadDate: any;

  userpull_id:string = '';

  ispccPractice = false;

  CustomList = [];
  selectedCustomList = "none";

  Global_firstName = "";
  Global_lastName = "";
  Global_birthdate: any;
  Global_gender = "";

  PatientCustomList = [];

  Addcustomlistname = "";
  AddCustomListShow = false;

  constructor( private renderer: Renderer, private router: Router, private httpClient: HttpClient ) { 
    let authData = JSON.parse(localStorage.getItem('AUTH'));
    this.userGroup = authData.group;
    this.userSub = authData.id;
    this.userpull_id = localStorage.getItem('userpull_id');

    if(localStorage.getItem('Mask') == 'yes'){
      this.ismasking = true;
    }else if(localStorage.getItem('Mask') == 'no'){
      this.ismasking = false;
    }

    if (localStorage.getItem('MANAGE-USER') && localStorage.getItem('MANAGE-USER') == "yes") {
      this.logedInUserManagePermission = "yes";
    }
    let todaydate = new Date();
    this.myMonth = this.formatMonth(todaydate);
    this.myTime = this.formatTime(todaydate);

    this.uploadDate = todaydate;

    this.EligibilitythisDate = this.formatDate(todaydate);
    this.ZohoTicketDate = this.formatDate(todaydate);

  	if(sessionStorage.getItem('flashSuccess')){
      this.flashSuccess = true;
    }
    this.patientFormCountry = "United States";
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });

    //////// Get Current Month And Past Month Date //////////////////
    this.setMonthDetails();

    if (localStorage.getItem('USER-DETAILS')) {
      var logedInUserDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));
      console.log(logedInUserDetails);
      if ('custom:group' in logedInUserDetails && logedInUserDetails['custom:group'] == 'SystemAdmin') {
        this.usercheckShow = true;
      }
    }
  }

  resetGlobalSearchForm = () => {
    console.log("resetGlobalSearchForm");

    this.Global_firstName = "";
    this.Global_lastName = "";
    this.Global_birthdate = "";
    this.Global_gender = "";

    this.step = 'Active';
    this.selectedprovider = 'all';
    this.selectedCustomList = "none";

    this.patientList = [];


    
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      if(dtElement.dtInstance) {
      dtElement.dtInstance.then((dtInstance: any) => {
        if(dtInstance.table().node().id == "patient-table") {
          dtInstance.destroy();
          this.dtTrigger.next();
        }
      });
      }
      else {
        this.dtTrigger.next();
      }
    });
  }

  changeCustomList = (custom) =>{
    console.log("resetGlobalSearchForm");
    console.log(custom);
    // this.getPatientList(this);
  }

  ChangeUserStatus = (user_status)=>{

    if(user_status != '') {
      sessionStorage.removeItem('DataTables_patient-table');
    }
    if(user_status == '' && sessionStorage.getItem('DataTables_User_Status') != null) {
      user_status = sessionStorage.getItem('DataTables_User_Status');
    }
    else if(user_status == '') {
      user_status = 'Active';
      sessionStorage.removeItem('DataTables_patient-table');
    }
    this.step = user_status;

    this.stepstep = this.step.substring(0,3);
  }

  SubmitGlobalSearchForm = () =>{
    var valid = true;

    // if (this.Global_firstName == "" && this.Global_lastName == "" && (this.Global_birthdate == "" || this.Global_birthdate == undefined)){
    //   alert("Please enter atleast one search criteria");
    //   valid = false;
    // }

    if(valid && this.Global_firstName !== ""){
      if(this.Global_firstName.length < 3){
        alert("Please enter First Name atleast 3 characters");
        valid = false;
      }
    }

    if(valid && this.Global_lastName !== ""){
      if(this.Global_lastName.length < 3){
        alert("Please enter Last Name atleast 3 characters");
        valid = false;
      }
    }

    if(valid) {
      this.getPatientList(this, this.step);
    }
  }

  AddCustomListShowButtonClick = ()=>{
    this.AddCustomListShow = true;
  }

  AddCustomList = () =>{
    console.log("AddCustomList");
    let processedArr = [];
    for(var i=0; i<this.patientList.length; i++) {
      if(this.patientList[i]['selectedclass']) {
        processedArr.push(this.patientList[i]['sub']);
      }
    }

    this.PatientCustomList = processedArr;

    console.log(processedArr);

    let patientFacilityModal = document.getElementById("patient-patient-custom-list-ankerTag") as HTMLElement;
    patientFacilityModal.click();

  }

  validateAddCustomList = () => {
    var valid = true;

    if (this.Addcustomlistname == ""){
      alert("Please enter name");
      valid = false;
    }

    if(valid) {
      var addupdateParams = {
        userpull_id: localStorage.getItem('userpull_id'),
        user_sub :this.userSub,
        user_group: this.userGroup,
        name: this.Addcustomlistname,
        subs: JSON.stringify(this.PatientCustomList)
      };
  
      this.screen.style.display = 'block';
  
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addcustomlist', addupdateParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe( async (response: any) => {
        this.screen.style.display = 'none';
        console.log("response");
        console.log(response)
        if (response.status === "success") {
          alert("CustomList added successfully");
          this.AddCustomListShow = false;
          this.Addcustomlistname = "";
          this.getCustomList();
        }else{
          alert(response.message);
        }
  
  
      },
      async (error: any) => {
        console.log(error);
        alert("Could not save template. Please try again.");
        this.screen.style.display = 'none';
      });
    }

  }

  EditCustomlist = (type, custom) => {
    console.log("EditCustomlist");
    console.log(type);
    console.log(custom);

    var addupdateParams = {
      userpull_id: localStorage.getItem('userpull_id'),
      type: type,
      custom: JSON.stringify(custom),
      subs: JSON.stringify(this.PatientCustomList)
    };

    this.screen.style.display = 'block';

    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/editcustomlist', addupdateParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe( async (response: any) => {
      this.screen.style.display = 'none';
      console.log("response");
      console.log(response)
      if (response.status === "success") {
        alert("CustomList updated successfully");
        this.getCustomList();
        this.getPatientList(this);
      }else{
        alert(response.message);
      }


    },
    async (error: any) => {
      console.log(error);
      alert("Could not save template. Please try again.");
      this.screen.style.display = 'none';
    });
  }
  
  getGlobalConfig = () => {
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getglobalconfig', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        let globalConfig = JSON.parse(response.data);

        for(var temp of globalConfig){
          if (temp.config_key === 'twilio_number' && temp.config_value !== ''){
            this.isTwilioEnabled = true;
            this.twilioNumber = temp.config_value;
          }
          if (temp.config_key === 'pcc_orgid' && temp.pcc_orgid !== ''){
            this.ispccPractice = true;
          }
        }

        console.log(globalConfig)

        if(response.has_facility) {
          this.has_facility = response.has_facility;
          this.facility_arr = response.facility_list;
        }
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  getCustomList = (user_status = '') => {
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getcustomlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        this.CustomList = JSON.parse(response.data);        
        console.log(this.CustomList)

        this.languageList = JSON.parse(response.LanguageList);
        this.providerlist = JSON.parse(response.ProviderList);
        if((this.providerlist).length > 55) {
          console.log((this.providerlist).length);
          this.providercountexceed = true;
        }

        if(user_status != '') {
          sessionStorage.removeItem('DataTables_patient-table');
        }
        if(user_status == '' && sessionStorage.getItem('DataTables_User_Status') != null) {
          user_status = sessionStorage.getItem('DataTables_User_Status');
        }
        else if(user_status == '') {
          user_status = 'Active';
          sessionStorage.removeItem('DataTables_patient-table');
        }
        this.step = user_status;
    
        this.stepstep = this.step.substring(0,3);
    
        if(sessionStorage.getItem('DataTables_Provider') == null) {
          this.selectedprovider = 'all';
        }
        else {
          this.selectedprovider = sessionStorage.getItem('DataTables_Provider');
          if(this.providerlist && this.providerlist.length > 0){
            var found = 0;
            for(var pl=0; pl <this.providerlist.length; pl++){
              if(this.providerlist[pl]['sub'] == this.selectedprovider) {
                found = 1;
              }
            }
            if(found == 0){
              this.selectedprovider = 'all';
            }
          }
        }

      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  facilityTemplateAddType = "";
  facilityNameError = false;
  facilityTemplateForm:any;
  EditFacilityId = 0;

  addNewfacilityTemplate = async () => {
    this.facilityTemplateAddType = "";
    this.facilityNameError = false;
    this.EditFacilityId = 0;
    setTimeout (() => {
      let facilityDataFinal = {};
      facilityDataFinal['facilityName'] = "";
      this.facilityTemplateForm= facilityDataFinal;
    }, 500);
    this.screen.style.display = 'block';
    await this.delay(500);
    this.screen.style.display = 'none';
    this.facilityTemplateAddType = "Add";
    setTimeout(()=>{
      let facilityelement: HTMLElement = document.getElementById('facilityname') as HTMLElement;
      facilityelement && facilityelement.focus();
    });
  }

  facilityNameChange = (tempVal) => {
    console.log(tempVal);

    if (tempVal !== ''){
      this.facilityNameError = false;
    }else{
      this.facilityNameError = true;
    }
  }

  facilityEdit = async (template) => {
    console.log(template);
    this.facilityTemplateAddType = "";
    this.facilityNameError = false;
    this.EditFacilityId = template.idfacility;
    setTimeout (() => {
      let facilityDataFinal = {};
      facilityDataFinal['facilityName'] = template.facilitytext;
      this.facilityTemplateForm= facilityDataFinal;
    }, 500);
    this.screen.style.display = 'block';
    await this.delay(500);
    this.screen.style.display = 'none';
    this.facilityTemplateAddType = "Edit";
    setTimeout(()=>{
      let facilityelement: HTMLElement = document.getElementById('facilityname') as HTMLElement;
      facilityelement && facilityelement.focus();
    });
  }

  facilityFormSubmitButtonClick = (form, facilityAddType) => {
    console.log(form);
    console.log(facilityAddType)

    if (form.facilityname !== '' && form.facilityname !== undefined){
      this.facilityNameError = false;
    }else{
      this.facilityNameError = true;
    }

    if (this.facilityNameError == false){
      console.log("Final call");

      var addupdateParams = {};
      var addupdateUrl = "";
      var addupdateMassage = "";  

      if (facilityAddType == 'Add'){
        addupdateUrl = "https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addfacility";
        addupdateMassage = "Facility added successfully";
        addupdateParams = {
          text: form.facilityname,
          userpull_id: localStorage.getItem('userpull_id')
        };
      }else if (facilityAddType == 'Edit'){
        addupdateUrl = "https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updatefacility";
        addupdateMassage = "Facility updated successfully";
        addupdateParams = {
          facility_id: this.EditFacilityId,
          text: form.facilityname,
          userpull_id: localStorage.getItem('userpull_id')
        };
      }

      if (addupdateUrl != ''){

        console.log("Final call Param", addupdateParams);     

        this.screen.style.display = 'block';

        this.httpClient.post(addupdateUrl, addupdateParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe( async (response: any) => {
          console.log("response");
          console.log(response)
          if (response.status === "success") {
            this.getGlobalConfig();
            this.facilityTemplateAddType = "";
            alert(addupdateMassage);
            this.screen.style.display = 'none';
          }else{
            alert(response.message);
            this.screen.style.display = 'none';
          }
        },
        async (error: any) => {
          console.log(error);
          alert("Could not save template. Please try again.");
          this.screen.style.display = 'none';
        });
      }

    }
  }

  PccFacilitySync = () => {
    console.log("PccFacilitySync");

    var addupdateParams = {
      userpull_id: localStorage.getItem('userpull_id')
    };

    this.screen.style.display = 'block';

    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/pccfacilitysync', addupdateParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe( async (response: any) => {
      console.log("response");
      console.log(response)
      alert("Pcc Facility sync successfully");
      location.reload();
      this.screen.style.display = 'none';
    },
    async (error: any) => {
      console.log(error);
      alert("Could not save template. Please try again.");
      this.screen.style.display = 'none';
    });
  }

  facilitymanage = () => {
    this.facilityTemplateAddType = "";
    let patientFacilityModal = document.getElementById("facility-list-ankerTag") as HTMLElement;
    patientFacilityModal.click();
  }

  patientFacilityEdit = async (patientinfo) => {
    patientinfo.updateFacility = true;
  }

  patientFacilityClose = (patientinfo) => {
    patientinfo.updateFacility = false;
  }

  updateFacility = ( eventinfo, patientinfo ) =>{
    let pClass = this;
    pClass.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      group: "Patient",
      sub: (patientinfo.sub)?patientinfo.sub:'',
      "facility": eventinfo.target.value
    };
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        if(response.status == "success") {
          this.getPatientList(this);
        }
        else {
          alert("Facility could not be updated. Please try again.");
          pClass.screen.style.display = 'none';
        }
      },
      async (error: any) => {
        console.log(error);
        alert("Facility could not be updated. Please try again.");
        pClass.screen.style.display = 'none';
      });
  }

  facilityDelete = (template) => {
    console.log(template);

    var confirmMsg = "Do you want to delete this facility? Please note, all users assigned to this facility will be unassigned";
    if(confirm(confirmMsg)) {
      console.log("confirm delete");
      this.screen.style.display = 'block';

      let deleteParams = {
        facility_id: template.idfacility,
        userpull_id: localStorage.getItem('userpull_id')
      };

      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/deletefacility', deleteParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe( async (response: any) => {
        console.log("response");
        console.log(response)
        this.screen.style.display = 'none';
        if (response.status === "success") {
          this.getGlobalConfig();
          this.facilityTemplateAddType = "";
          this.getPatientList(this);
        }else{
          alert(response.message);
          this.screen.style.display = 'none';
        }
      },
      async (error: any) => {
        console.log(error);
        alert("Could not delete facility. Please try again.");
        this.screen.style.display = 'none';
      });
    }
  }

  twillioRecordButton = true;
  roomName = "";

  callNumbers = [];
  // callNumbers = [{phone: "+555552222222", status: "Ring", callsid: "CAf6bac30172197afb0367cfbf2b13cb5c"}]

  twillioaddnumberadd = false;

  twillioaddnumber:string = "";

  twlioConfarencetimer: any;

  twlioSecondtimer: any;

  SecondCallSid:string = "";

  SecondCallStatus:string = "";

  twlioConfarenceCallStatusList = ["queued", "initiated", "ringing", "in-progress"];


  twiliocalllistAddbuttonClick = (val) => {
    console.log("twiliocalllistAddbuttonClick")
    console.log(val)

    this.twillioaddnumberadd = true;
    this.twillioaddnumber = "";

  }

  twiliocalllistAddNumberbuttonClick = (val) => {
    console.log("twiliocalllistAddNumberbuttonClick")
    console.log(val)

    let phone_numberPattern = /^[+]\d+$/;                ///// Checking for +Number /////
    if(!this.twillioaddnumber || typeof this.twillioaddnumber === 'undefined' || !this.twillioaddnumber.match(phone_numberPattern)){
      alert("Invalid Phone number.")
    }else{
      this.twillioaddnumberadd = false;
      let params = {
        roomName: this.roomName,
        fromNumber: this.twilioConstantNumber,
        numbers: [this.twillioaddnumber]
      };

      let tempNumber = this.twillioaddnumber;
      this.httpClient.post(this.twilioEndPoint + '/stage/twilio/conferenceCall', params, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
        .subscribe( async (response: any) => {
          console.log(response);
          var addNumber = response;
          console.log(addNumber['callSids'][0])

          this.callNumbers.push({phone: tempNumber.toString(), status: "", callsid: addNumber['callSids'][0]});
          
        },
        async (error: any) => {
          console.log(error);
        });
      this.twillioaddnumber = "";
    }

    

  }

  callConfarenceWatcher = async () => {
    this.twlioConfarencetimer = setInterval(async () => {
      // console.log("callConfarenceWatcher")

      if (this.callNumbers.length > 0){
        for(let callNumber of this.callNumbers){

          var tempcallNumber = callNumber;

          let index = this.callNumbers.indexOf(callNumber);

          const params = new HttpParams({
            fromObject: {
              callsid: callNumber.callsid
            }
          });


          let response: any = await this.httpClient.get(this.twilioEndPoint + '/stage/twilio/CallStatus', { params, headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) }).toPromise();

          console.log(response);
          console.log(response['callStatus']['status']);

          tempcallNumber["status"] = response['callStatus']['status'];

          this.callNumbers[index] = tempcallNumber;
    
          await this.delay(100);

          if(this.twlioConfarenceCallStatusList.indexOf(response['callStatus']['status']) == -1){
            this.callNumbers.splice(index, 1);
          }

        }
      }
      
    }, 2000);
  }

  secondcallWatcher = async () => {
    this.twlioSecondtimer = setInterval(async () => {
      console.log("secondcallWatcher")

      const params = new HttpParams({
        fromObject: {
          callsid: this.SecondCallSid
        }
      });

      let response: any = await this.httpClient.get(this.twilioEndPoint + '/stage/twilio/CallStatus', { params, headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) }).toPromise();

      console.log(response);
      console.log(response['callStatus']['status']);

      this.SecondCallStatus = response['callStatus']['status'];
      
    }, 1000);
  }

  twiliocalllistdeletebuttonClick = (val) => {
    console.log("twiliocalllistdeletebuttonClick")
    console.log(val)

    const index: number = this.callNumbers.indexOf(val);
    this.callNumbers.splice(index, 1);
    if (index !== -1) {
        
        let params = {
          roomName: this.roomName,
          entity: val.phone
        };
        this.httpClient.post(this.twilioEndPoint + '/stage/twilio/RemovePersonFromConference', params, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
          .subscribe( async (response: any) => {
            console.log(response);
            
          },
          async (error: any) => {
            console.log(error);
          });
    }
  }

  getSecondCallSid = () => {
    console.log("getSecondCallSid");
    const params = new HttpParams({
      fromObject: {
        roomName: this.roomName
      }
    });
    this.httpClient.get(this.twilioEndPoint + '/stage/twilio/SecondCallSid', { params, headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
    .subscribe((response: any) => { 
      console.log(response);
      this.SecondCallSid = response['callsid']
      this.secondcallWatcher();
    },
    async (error: any) => {
      console.log(error);
    });
  }

  patienttwiliocallButtonClick =  async (userDetails, smsno=false) => {
    console.log("twilio call Button Pressed");
    console.log(userDetails);

    this.roomName = "";

    this.callNumbers = [];
    let usercallingno = userDetails.phone_number;
    let masked_usercallingno = userDetails.masked_phone_number;
    if(smsno) {
      usercallingno = userDetails.phoneNoSms;
      masked_usercallingno = userDetails.masked_phoneNoSms;
    }
    this.patientphone = masked_usercallingno;
    if (this.clickCallisDisabled == false) {
      console.log("twilio call")

      this.clickCallisDisabled = true;

      let patientNumber = usercallingno;    
      let practice = localStorage.getItem('userpull_id'); //user pull id
      let patientName = userDetails.sub; //patient sub
      let reason = "general";   
      let tt = new Date();
      console.log(tt.getFullYear());
      let roomName = tt.getFullYear().toString()+tt.getMonth().toString()+tt.getDate().toString()+tt.getHours().toString()+tt.getMinutes().toString()+tt.getSeconds().toString()+tt.getMilliseconds().toString()+'-'+patientNumber.replace('+','');
      this.roomName = roomName;
      console.log(roomName);
      
      var callbackUrl = this.twilioEndPoint + "/stage/twilio/callbackUrl?patientNumber=" + patientNumber +"&twilioNumber=" + this.twilioConstantNumber + "&fromNumber=" + this.twilioNumber + "&practice=" + practice + "&patientName=" + patientName + "&reason=" + reason + "&user_info="+localStorage.getItem('user_sub')+"&roomName=" + roomName +"&isreview=0";
      console.log(callbackUrl)

      let tokenParams = {
        twilioNumber: this.twilioConstantNumber,
        fromNumber: this.twilioNumber,
        patientNumber: patientNumber,
        callbackUrl: encodeURI(callbackUrl),
        roomName: roomName
      };

      this.httpClient.post(this.twilioEndPoint + '/stage/twilio/call/token3', tokenParams, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
      .subscribe( async (response: any) => {
        console.log("token call Sucess");
        console.log(response.token);
        
        let callDetailsModal = document.getElementById("call-details-ankerTag") as HTMLElement;
        callDetailsModal.click();

        var tokenparam = {
          fakeLocalDTMF: true,
          enableRingingState: true
          // sounds: {
          //   disconnect: ''
          // }
        };

        Twilio.Device.setup(response.token, tokenparam);
        this.endButtonShow = true;
        console.log("device setup");
        await this.delay(3000);
        console.log("call setup");
        var callParams = {
          // phoneNumber: this.twilioConstantNumber,
          phoneNumber: patientNumber,
          recording : true,
          practice: practice,
          patientName: patientName,
          reason : reason,
          roomName: roomName,
          isWeb: true,
          isreview: 0,
          fromNumber: this.twilioNumber,
          twilioNumber: this.twilioConstantNumber, //'+14083518666' // twilio number
          user_info: localStorage.getItem('user_sub'),
          user_group: this.userGroup,
          loction: "PatientList",
          extra_param: JSON.stringify({subtype:this.step, description: "" })
        };
        var outcall = Twilio.Device.connect(callParams);

        outcall.on('reject', function(call) {
          console.log(call);
        });

        outcall.on('ringing', function(call) {
          console.log(call);
        });

        outcall.on('accept', function(call) {
          console.log(call);
        });

        outcall.on('disconnect', function(call) {
          console.log(call);
        });

        await this.delay(3000);

        this.getSecondCallSid();

        this.callWatcher();

        this.callConfarenceWatcher();
        
      },
      async (error: any) => {
        console.log(error);
      });
    }

  }

  patienttwilioClickDigit = async (digit) => {
    console.log("digit pressed")
    console.log(digit)
    Twilio.Device.activeConnection().sendDigits(digit)
  }

  patienttwiliocallEndButtonClick = () => {
    console.log("call end Sucess");
    this.endButtonShow = false;
    this.phonecall = true;
    this.clickCallisDisabled = false;
    Twilio.Device.disconnectAll();

    clearInterval(this.twlioConfarencetimer);
    clearInterval(this.twlioSecondtimer);

    let patientCallModal = document.getElementById("patient-call-modal-close-button") as HTMLElement;
    patientCallModal.click();
  }

  callWatcher = () => {
    this.twliotimer = setInterval(async () => {
      console.log("hhhhhh")
      console.log(Twilio.Device.status())

      if (Twilio.Device.status() == 'busy'){
        console.log(Twilio.Device.activeConnection().status())
      }
      if (Twilio.Device.status() == 'ready'){
        console.log("call end by user");
        this.endButtonShow = false;
        this.phonecall = true;
        this.clickCallisDisabled = false;
        clearInterval(this.twliotimer);

        clearInterval(this.twlioConfarencetimer);
        clearInterval(this.twlioSecondtimer);

        let patientCallModal = document.getElementById("patient-call-modal-close-button") as HTMLElement;
        patientCallModal.click();
      }
    }, 600);
  }

  twilliocallcheckValue = (val) =>{
    console.log("twilliocallcheckValue");
    console.log(this.twillioRecordButton);

    var isPaused = true;

    if(this.twillioRecordButton == true){
      isPaused = false;
    }else if(this.twillioRecordButton == false){
      isPaused = true;
    }

    let params = {
      roomName: this.roomName,
      isPaused: isPaused
    };
    this.httpClient.post(this.twilioEndPoint + '/stage/twilio/RecordingOnAndOff', params, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }) })
      .subscribe( async (response: any) => {
        console.log(response);
      },
      async (error: any) => {
        console.log(error);
      });

  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  setMonthDetails = () => {

    this.monthDetails = {
        currentMonthFirstDate:'',
        currentMonthLastDate:'',
        pastMonthFirstDate:'',
        pastMonthLastDate:'',
        currentMonthName:'',
        pastMonthName:'',
        currentYear:''
    };


    let date = new Date();

    let currentMonthfirstDay    = new Date(date.getFullYear(), date.getMonth(), 1);
    let currentMonthlastDay     = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let pastMonthfirstDay       = new Date(date.getFullYear(), date.getMonth()-1, 1);
    let pastMonthLastDay        = new Date(date.getFullYear(), date.getMonth()-1 + 1, 0);

    this.monthDetails.currentMonthFirstDate  = this.splitAndReplace(currentMonthfirstDay.toString());
    this.monthDetails.currentMonthLastDate   = this.splitAndReplace(currentMonthlastDay.toString());
    this.monthDetails.pastMonthFirstDate     = this.splitAndReplace(pastMonthfirstDay.toString());
    this.monthDetails.pastMonthLastDate      = this.splitAndReplace(pastMonthLastDay.toString());

    this.monthDetails.currentMonthName       = this.splitAndReplace(currentMonthlastDay.toString(),'Month');
    this.monthDetails.pastMonthName          = this.splitAndReplace(pastMonthLastDay.toString(),'Month');

    this.monthDetails.currentYear             = date.getFullYear();
  }

  splitAndReplace = (SplitDate,Month='') =>{
    let res = SplitDate.split(" ");
    if(Month!="")
    {
      return res[1];  
    }
    return res[2].replace(/^0/, '');
  }

  manualstoreData: any;
  manualfileUploaded: File;
  manualworksheet: any;
  manualuploadedFile(event) {
    this.manualerrorUploadMsg = "";
    this.manualfileUploaded = event.target.files[0];
    if(this.manualfileUploaded && this.manualfileUploaded.type && this.manualfileUploaded.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.manualreadExcel();
    }
    else if(this.manualfileUploaded && this.manualfileUploaded.type){
      alert("Please choose valid excel file");
      this.manualworksheet = "";
      this.myInputVariable2.nativeElement.value = "";
    }
    else {
      this.manualworksheet = "";
      this.myInputVariable2.nativeElement.value = "";
    }
  }
  manualreadExcel() {
    let readFile = new FileReader();
    readFile.onload = (e) => {
      this.manualstoreData = readFile.result;
      var data = new Uint8Array(this.manualstoreData);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      this.manualworksheet = workbook.Sheets[first_sheet_name];
      this.manualerrorUploadMsg = '';
      this.manualsuccessUploadMsg = '';
    }
    readFile.readAsArrayBuffer(this.manualfileUploaded);
  }
  readAsManualJson() {
    this.myInputVariable2.nativeElement.value = "";
    let jsonData = XLSX.utils.sheet_to_json(this.manualworksheet, { raw: false });
    console.log(jsonData);
    this.manualerrorUploadMsg = '';
    this.manualsuccessUploadMsg = '';
    if(jsonData.length > 0){
      let userDataArr = []
      for(var i=0; i<jsonData.length; i++) {
        console.log(jsonData[i]);
        let userData = [];
        userData.push({Name:"first",Value:jsonData[i]['First']});
        userData.push({Name:"last",Value:jsonData[i]['Last']});
        userData.push({Name:"dob",Value:jsonData[i]['DOB']});

        userData.push({Name:"time",Value:jsonData[i]['Date/time']});
        userData.push({Name:"device",Value:jsonData[i]['Device Type']});
        userData.push({Name:"reading",Value:jsonData[i]['Reading']});
        
        userData.push({Name:"Date",Value:jsonData[i]['Date']});
        userData.push({Name:"Oximeter",Value:jsonData[i]['Oximeter']});
        userData.push({Name:"Oximeter-Time",Value:jsonData[i]['Oximeter-Time']});
        userData.push({Name:"Thermometer",Value:jsonData[i]['Thermometer']});
        userData.push({Name:"Thermometer-Time",Value:jsonData[i]['Thermometer-Time']});
        userData.push({Name:"BPM",Value:jsonData[i]['BPM']});
        userData.push({Name:"BPM-Time",Value:jsonData[i]['BPM-Time']});

        userDataArr.push(userData);
      }
      let processing = 0;
      // console.log(userDataArr.length);
      for(var j=0; j<userDataArr.length; j++) {
        let params = {
          userpull_id: localStorage.getItem('userpull_id')
        };
        for(var k = 0; k < userDataArr[j].length; k++) {
          params[userDataArr[j][k]['Name']] = userDataArr[j][k]['Value'];
        }
        console.log(params);
        let arrlength = userDataArr.length;
        this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/manualsync', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          console.log(response);
          // console.log(arrlength);
          processing = processing + 1;
          if(response.status=="success") {
            this.manualsuccessUploadMsg = this.manualsuccessUploadMsg + "<br>" + response.message;
          }
          else if('message' in response && response.message != "") {
            this.manualerrorUploadMsg = this.manualerrorUploadMsg + "<br>" + response.message;
          }
          else if('errorMessage' in response && response.errorMessage != "") {
            this.manualerrorUploadMsg = this.manualerrorUploadMsg + "<br>" + response.errorMessage;
          }
          // console.log(processing);
          if(processing == arrlength){
            console.log("complete");
            this.manualsuccessUploadMsg = this.manualsuccessUploadMsg + "<br><b>Complete</b>";
          }
        },
        async (error: any) => {
          console.log(error);
          if('error' in error){
            if('message' in error.error){
              this.manualerrorUploadMsg = this.manualerrorUploadMsg + "<br>" + error.error.message;
            }
          }
          processing = processing + 1;
          if(processing == arrlength){
            console.log("complete");
            this.manualsuccessUploadMsg = this.manualsuccessUploadMsg + "<br><b>Complete</b>";
          }
        });
      }
    }
    else {
      var errMsg = 'There is some error. Please check following:';
      this.manualerrorUploadMsg = errMsg+"<br>Please choose correct file";
      return false;
    }
    this.manualworksheet = "";
  }

  storeData: any;
  fileUploaded: File;
  worksheet: any;

  ImportType = "Patient_Demography";
  uploadhtmlToAdd: any;
  uploadStatus: any;

  ImportModule = ['RPM'];

  // importTypeChange = (typeTemp) => {
  //   console.log("importTypeChange");
  //   console.log(typeTemp);
  // }

  uploadExcel = [];

  uploadedFile(event) {
    this.errorUploadMsg = "";
    this.fileUploaded = event.target.files[0];
    if(this.fileUploaded && this.fileUploaded.type && this.fileUploaded.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.readExcel();
    }
    else if(this.fileUploaded && this.fileUploaded.type){
      alert("Please choose valid excel file");
      this.worksheet = "";
      this.myInputVariable.nativeElement.value = "";
    }
    else {
      this.worksheet = "";
      this.myInputVariable.nativeElement.value = "";
    }
  }
  readExcel() {
    let readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      var data = new Uint8Array(this.storeData);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];
      this.errorUploadMsg = '';
      this.successUploadMsg = '';
    }
    readFile.readAsArrayBuffer(this.fileUploaded);
  }
  datevalidate( input ) {
    var dateinput = new Date( input );
    input = input.split( '/' );
    return dateinput.getMonth() + 1 === +input[0] && dateinput.getDate() === +input[1] && dateinput.getFullYear() === +input[2];
  }

  ispccpatientsyncButtonshow = false;

  PccpatientsyncStatus  = (type) => {
    console.log("PccpatientsyncStatus");

    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id')
      }
    });

    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/pccpatientImportstatus', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      if(response.status == "success") {
        if(type == 'button'){
          alert(response.message);
        }
        
        let newattr = JSON.parse(response.data);
        if (newattr.button == 'hide'){
          this.ispccpatientsyncButtonshow = false;
        }else if (newattr.button == 'show'){
          this.ispccpatientsyncButtonshow = true;
        }
      }
    });
  }

  Pccpatientsync = async () => {
    let selfacilityval = "0";
    this.errorUploadMsg = '';
    this.successUploadMsg = '';
    let providercheckval = (<HTMLInputElement>document.getElementById('assigntoprovider')).checked;
    let assignsub = "";
    if(providercheckval){
      for (var j = 0; j < this.providerlist.length; j++) {
        if(j == 0) {
          assignsub = this.providerlist[j]['sub'].replace(/-/g, '_');
        }
        else if(j < 55) {
          assignsub = assignsub +','+ this.providerlist[j]['sub'].replace(/-/g, '_');
        }
      }
    }
    else{
      let e = (<HTMLSelectElement>document.getElementById('providerassign')).selectedOptions;
      console.log(e.length);
      for(var p=0; p<e.length; p++){
        // console.log(e[p].value);
        if(p == 0) {
          assignsub = e[p]['value'].replace(/-/g, '_');
        }
        else if(p < 55) {
          assignsub = assignsub +','+ e[p]['value'].replace(/-/g, '_');
        }
      }
    }
    console.log(assignsub);
    if(assignsub == "") {
      this.errorUploadMsg += '<br>Please choose at least one provider';
    }
    var Reporting_provider = "";
    let re = (<HTMLSelectElement>document.getElementById('Reporting_provider')).selectedOptions;
    console.log("ri",re.length);
    if(re.length > 0){
      var temp_Reporting_provider = re[0]['value'];
      if(re[0]['value'] != ''){
        Reporting_provider = re[0]['value'];
      }else{
        this.errorUploadMsg += '<br>Please choose Reporting provider';
      }
    }else{
      this.errorUploadMsg += '<br>Please choose Reporting provider';
    }
    console.log("Reporting_provider", Reporting_provider);
    if(this.errorUploadMsg) {
      var errMsg = 'There is some error. Please check following:';
      this.errorUploadMsg = errMsg + this.errorUploadMsg;
      return false;
    }
    if(this.has_facility){
      selfacilityval = (<HTMLInputElement>document.getElementById('facility_choose')).value;
      console.log(parseInt(selfacilityval));
      if(parseInt(selfacilityval) > 0) {
        // console.log(this.facility_arr);
        let selfacilitytext = "";
        for(var facilityval of this.facility_arr){
          if(facilityval.idfacility == selfacilityval){
            selfacilitytext = facilityval.facilitytext;
          }
        }
        if(confirm("Do you want to submit with facility "+selfacilitytext+"?")){

        }
        else {
          return false;
        }
      }
      else {
        if(confirm("Do you want submit by not selecting any facility?")){
          
        }
        else {
          return false;
        }
      }
    }
    
    let params = {
      userpull_id: localStorage.getItem('userpull_id')
    };

    if(this.has_facility && parseInt(selfacilityval) > 0){
      params['facility_id']=selfacilityval;
    }
    // params['modules']=this.ImportModule;
    params['reporting_provider']=Reporting_provider;
    params["assignsub"]=assignsub;

    console.log("pcc params", params);

    this.screen.style.display = 'block';

    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/pccpatientimport', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe( async (response: any) => {
      this.screen.style.display = 'none';
      console.log(response);
      if(response.status == "success") {
        alert("Patient Import process started in background and will reflect in all patient list shortly.");
      }else if(response.status == "error") {
        alert(response.message);
      }
      this.ispccpatientsyncButtonshow = true;
    },
    async (error: any) => {
      if('error' in error && 'message' in error['error'] && error['error']['message'] == "Endpoint request timed out") {
        alert("Patient Import process started in background and will reflect in all patient list shortly.");
        this.ispccpatientsyncButtonshow = true;
      }
      // Auth.signOut();
      // localStorage.removeItem('AUTH');
      // localStorage.removeItem('AUTH-LIFE');
      // localStorage.removeItem('AUTH-PROFILE-PIC');
      // localStorage.removeItem('USER-DETAILS');
      // this.screen.style.display = 'none';
      // this.router.navigateByUrl('/sign-in');
    });
    

  }


  readAsJson = async () => {
    this.uploadExcel = [];
    let selfacilityval = "0";
    console.log(this.ImportModule)
    if (this.ImportModule.length == 0){
      alert("Please select atleast one modules");
      return false
    }else{
      console.log(this.ImportModule.indexOf('RPM'))
      if(this.ImportModule.indexOf('RPM') < 0){
        if(confirm("Do you want to import "+this.ImportModule.toString()+"?")){

        }
        else {
          return false;
        }
      }else{
        if (this.ImportModule.length > 1){
          if(confirm("Do you want to import "+this.ImportModule.toString()+"?")){

          }
          else {
            return false;
          }
        }
      }
    }
    if(this.has_facility){
      selfacilityval = (<HTMLInputElement>document.getElementById('facility_choose')).value;
      console.log(parseInt(selfacilityval));
      if(parseInt(selfacilityval) > 0) {
        // console.log(this.facility_arr);
        let selfacilitytext = "";
        for(var facilityval of this.facility_arr){
          if(facilityval.idfacility == selfacilityval){
            selfacilitytext = facilityval.facilitytext;
          }
        }
        if(confirm("Do you want to submit with facility "+selfacilitytext+"?")){

        }
        else {
          return false;
        }
      }
      else {
        if(confirm("Do you want submit by not selecting any facility?")){
          
        }
        else {
          return false;
        }
      }
    }
    let jsonData = XLSX.utils.sheet_to_json(this.worksheet, { raw: false });
    console.log("jsonData",jsonData);
    this.errorUploadMsg = '';
    this.successUploadMsg = '';
    let onlyAlphabets = /^[A-Za-z]+$/;
    let onlyAlphaNumeric = /^[A-Za-z0-9 ]+$/;
    let domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
    let prefixval = (<HTMLInputElement>document.getElementById('emailPrefix')).value;
    let domainval = (<HTMLInputElement>document.getElementById('emailDomain')).value;
    let providercheckval = (<HTMLInputElement>document.getElementById('assigntoprovider')).checked;
    console.log(providercheckval);
    if(!prefixval || !prefixval.match(onlyAlphabets)) {
      this.errorUploadMsg += '<br>Invalid Email Prefix';
    }
    if(!domainval || !domainval.match(domainRegex)) {
      this.errorUploadMsg += '<br>Invalid Email Domain';
    }
    let assignsub = "";
    if(providercheckval){
      for (var j = 0; j < this.providerlist.length; j++) {
        if(j == 0) {
          assignsub = this.providerlist[j]['sub'].replace(/-/g, '_');
        }
        else if(j < 55) {
          assignsub = assignsub +','+ this.providerlist[j]['sub'].replace(/-/g, '_');
        }
      }
    }
    else{
      let e = (<HTMLSelectElement>document.getElementById('providerassign')).selectedOptions;
      console.log(e.length);
      for(var p=0; p<e.length; p++){
        // console.log(e[p].value);
        if(p == 0) {
          assignsub = e[p]['value'].replace(/-/g, '_');
        }
        else if(p < 55) {
          assignsub = assignsub +','+ e[p]['value'].replace(/-/g, '_');
        }
      }
    }
    console.log(assignsub);
    if(assignsub == "") {
      this.errorUploadMsg += '<br>Please choose at least one provider';
    }
    var Reporting_provider = "";
    let re = (<HTMLSelectElement>document.getElementById('Reporting_provider')).selectedOptions;
    console.log("ri",re.length);
    if(re.length > 0){
      var temp_Reporting_provider = re[0]['value'];
      if(re[0]['value'] != ''){
        Reporting_provider = re[0]['value'];
      }else{
        this.errorUploadMsg += '<br>Please choose Reporting provider';
      }
    }else{
      this.errorUploadMsg += '<br>Please choose Reporting provider';
    }
    console.log("Reporting_provider", Reporting_provider);
    if(this.errorUploadMsg) {
      var errMsg = 'There is some error. Please check following:';
      this.errorUploadMsg = errMsg + this.errorUploadMsg;
      return false;
    }
    // return;
    if(jsonData.length > 0){
      let userDataArr = [];
      for(var i=0; i<jsonData.length; i++) {
        console.log(jsonData[i]);

        if(this.ImportType == 'Patient_Demography' || this.ImportType == 'Patient_Demography_Insurance' || this.ImportType == 'Auto_Demography'){

          if(!jsonData[i]['First Name *'] || typeof jsonData[i]['First Name *'] === 'undefined' || !jsonData[i]['First Name *'].match(onlyAlphaNumeric)){
            this.errorUploadMsg += '<br>Invalid First Name';
          }

          if(!jsonData[i]['Last Name *'] || typeof jsonData[i]['Last Name *'] === 'undefined' || !jsonData[i]['Last Name *'].match(onlyAlphaNumeric)){
            this.errorUploadMsg += '<br>Invalid Last Name';
          }

          let phone_numberPattern = /^[+]\d+$/;                ///// Checking for +Number /////
          if(!jsonData[i]['Phone Number * (ex: +19876543210)'] || typeof jsonData[i]['Phone Number * (ex: +19876543210)'] === 'undefined' || !jsonData[i]['Phone Number * (ex: +19876543210)'].match(phone_numberPattern)){
            this.errorUploadMsg += '<br>Phone Number should be in "+19876543210" format';
          }

          if(jsonData[i]['SMS Number'] && jsonData[i]['SMS Number'] != '' && !jsonData[i]['SMS Number'].match(phone_numberPattern)){
            this.errorUploadMsg += '<br>SMS Number should be in "+19876543210" format';
          }

          let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          if(jsonData[i]['FaceTime (Phone Number / Email)'] && jsonData[i]['FaceTime (Phone Number / Email)'] != '' && !jsonData[i]['FaceTime (Phone Number / Email)'].match(phone_numberPattern) && !jsonData[i]['FaceTime (Phone Number / Email)'].match(emailPattern)){
            this.errorUploadMsg += '<br>FaceTime should be in "+19876543210" Phone Number format or any valid email format';
          }

          if( !jsonData[i]['DOB * (MM/DD/YYYY)']  || typeof jsonData[i]['DOB * (MM/DD/YYYY)'] === 'undefined' || jsonData[i]['DOB * (MM/DD/YYYY)']==''){
            this.errorUploadMsg += '<br>Please provide DOB in "MM/DD/YYYY" format';
          }
          else {
            if(!this.datevalidate(jsonData[i]['DOB * (MM/DD/YYYY)'])) {
              console.log(jsonData[i]['DOB * (MM/DD/YYYY)']);
              this.errorUploadMsg += '<br>Please provide valid DOB in "MM/DD/YYYY" format';
            }
            else {
              var dateinput = new Date( jsonData[i]['DOB * (MM/DD/YYYY)'] );
              console.log(dateinput);
              var datecurrent = new Date();
              console.log(datecurrent);
              if (dateinput >= datecurrent) {
                this.errorUploadMsg += '<br>DOB should be less than or equal to today';
              }
            }
          }

          if(this.ImportType == 'Patient_Demography' || this.ImportType == 'Patient_Demography_Insurance'){

            if(!jsonData[i]['Gender * (M / F)'] || typeof jsonData[i]['Gender * (M / F)'] === 'undefined' || (jsonData[i]['Gender * (M / F)'] && jsonData[i]['Gender * (M / F)'] != "M" && jsonData[i]['Gender * (M / F)'] != "F")){
              this.errorUploadMsg += '<br>Please provide valid Gender';
            }

            if(!jsonData[i]['Address *'] || typeof jsonData[i]['Address *'] === 'undefined' || jsonData[i]['Address *']==''){
              this.errorUploadMsg += '<br>Please provide Address';
            }
          }

          if(!jsonData[i]['Height * (in inches)'] || typeof jsonData[i]['Height * (in inches)'] === 'undefined' || jsonData[i]['Height * (in inches)']=="" || Number(jsonData[i]['Height * (in inches)'])<1){
            this.errorUploadMsg += '<br>Please provide Height';
          }

          
        }

        if(this.ImportType == 'Patient_Demography_Insurance' || this.ImportType == 'Auto_Demography'){
          console.log("type 11")
          if(!jsonData[i]['Primary Payer Code *'] || typeof jsonData[i]['Primary Payer Code *'] === 'undefined' || jsonData[i]['Primary Payer Code *']==''){
            this.errorUploadMsg += '<br>Please provide Primary Payer Code';
          }else{
            if (this.PayerList.some(function(el){ return el.payerCode === jsonData[i]['Primary Payer Code *'] })){
              var tempprimarypayer = this.PayerList.find(x => x.payerCode === jsonData[i]['Primary Payer Code *']);
              // jsonData[i]['primary_payername'] = tempprimarypayer['payerName'];
            }else{
              this.errorUploadMsg += '<br>Please enter proper Primary Payer Code';
            }
          }
  
          if(!jsonData[i]['Primary Member ID *'] || typeof jsonData[i]['Primary Member ID *'] === 'undefined' || jsonData[i]['Primary Member ID *']==''){
            this.errorUploadMsg += '<br>Please provide Primary Member ID';
          }
  
          //Secondary Checking
          if(jsonData[i]['Supplemental Payer Code'] != undefined && jsonData[i]['Supplemental Payer Code'] != '') {
            if(!jsonData[i]['Supplemental Payer Code'] || typeof jsonData[i]['Supplemental Payer Code'] === 'undefined' || jsonData[i]['Supplemental Payer Code']==''){
              this.errorUploadMsg += '<br>Please provide Supplemental Payer Code';
            }else{
              if (this.PayerList.some(function(el){ return el.payerCode === jsonData[i]['Supplemental Payer Code'] })){
                var tempprimarypayer = this.PayerList.find(x => x.payerCode === jsonData[i]['Supplemental Payer Code']);
                // jsonData[i]['secondary_payername'] = tempprimarypayer['payerName'];
              }else{
                this.errorUploadMsg += '<br>Please enter proper Supplemental Payer Code';
              }
            }
          }
  
          if(jsonData[i]['Supplemental Member ID'] != undefined && jsonData[i]['Supplemental Member ID'] != '') {
            if(!jsonData[i]['Supplemental Member ID'] || typeof jsonData[i]['Supplemental Member ID'] === 'undefined' || jsonData[i]['Supplemental Member ID']==''){
              this.errorUploadMsg += '<br>Please provide Supplemental Member ID';
            }
          }

        }

        if(this.errorUploadMsg) {
          var errMsg = 'There is some error on line no. '+String(parseInt(jsonData[i]['__rowNum__'])+1)+'. Please check following:';
          this.errorUploadMsg = errMsg + this.errorUploadMsg;
          this.worksheet = "";
          this.myInputVariable.nativeElement.value = "";
          return false;
        }

        let userData = [];
        userData.push({Name:"given_name",Value:jsonData[i]['First Name *']});
        userData.push({Name:"family_name",Value:jsonData[i]['Last Name *']});
        userData.push({Name:"phone_number",Value:jsonData[i]['Phone Number * (ex: +19876543210)']});
        userData.push({Name:"custom:height",Value: jsonData[i]['Height * (in inches)']});
        if(jsonData[i]['PD']) {
          userData.push({Name:"user_primary_diagnosis",Value:jsonData[i]['PD']});
        }
        if(jsonData[i]['SMS Number']) {
          userData.push({Name:"custom:phoneNoSms",Value:jsonData[i]['SMS Number']});
          userData.push({Name:"custom:isSmsAllowed",Value: "1"});
        }
        if(jsonData[i]['FaceTime (Phone Number / Email)']) {
          userData.push({Name:"custom:phoneNo2",Value:jsonData[i]['FaceTime (Phone Number / Email)']});
        }
        if(jsonData[i]['Note']) {
          userData.push({Name:"custom:note",Value:jsonData[i]['Note']});
        }
        userData.push({Name:"birthdate",Value:jsonData[i]['DOB * (MM/DD/YYYY)'].replaceAll('/','-')});
        if(jsonData[i]['Gender * (M / F)'] == "M") {
          userData.push({Name:"gender",Value:"Male"});
        }
        else if(jsonData[i]['Gender * (M / F)'] == "F") {
          userData.push({Name:"gender",Value:"Female"});
        }
        if(this.ImportType == 'Patient_Demography' || this.ImportType == 'Patient_Demography_Insurance'){
          userData.push({Name:"address",Value:jsonData[i]['Address *']});
        }        
        if(jsonData[i]['Address 1']) {
          userData.push({Name:"custom:home_address1",Value:jsonData[i]['Address 1']});
        }
        if(jsonData[i]['City']) {
          userData.push({Name:"custom:home_city",Value:jsonData[i]['City']});
        }
        if(jsonData[i]['State']) {
          userData.push({Name:"custom:home_state",Value:jsonData[i]['State']});
        }
        if(jsonData[i]['ZIP']) {
          userData.push({Name:"custom:home_pin",Value:jsonData[i]['ZIP']});
        }
        if(jsonData[i]['MRN 1']) {
          userData.push({Name:"custom:mrn",Value:jsonData[i]['MRN 1']});
        }
        if(jsonData[i]['MRN 2']) {
          userData.push({Name:"custom:mrn1",Value:jsonData[i]['MRN 2']});
        }
        userData.push({Name:"custom:assign",Value:assignsub});
        let todaydate = new Date();
        userData.push({Name:"custom:group",Value: 'Patient'},{Name:"email_verified",Value: 'TRUE'},{Name:"phone_number_verified",Value: 'TRUE'},{Name:"custom:isOnline",Value: '1'},{Name:"custom:enrollDate",Value: this.formatDateCustom(todaydate)});
        console.log(userData);
        // return;

        var peligibility = [];
        var seligibility = [];

        if(this.ImportType == 'Patient_Demography_Insurance' || this.ImportType == 'Auto_Demography'){
          peligibility['primary_memberid'] = jsonData[i]['Primary Member ID *'].trim();
          peligibility['primary_payercode'] = jsonData[i]['Primary Payer Code *'].trim();
          peligibility['primary_payername'] = this.PayerList.find(x => x.payerCode === jsonData[i]['Primary Payer Code *'])['payerName'].trim();
          

          if(jsonData[i]['Supplemental Payer Code'] && jsonData[i]['Supplemental Member ID']) {
            seligibility['secondary_payercode'] = jsonData[i]['Supplemental Payer Code'].trim();
            seligibility['secondary_memberid'] = jsonData[i]['Supplemental Member ID'].trim();
            seligibility['secondary_payername'] = this.PayerList.find(x => x.payerCode === jsonData[i]['Supplemental Payer Code'])['payerName'].trim();
          }


        }

        userDataArr.push({ Attributes: userData, Excel: jsonData[i],  PrimaryEligibility: peligibility, SecondaryEliginility: seligibility});

      }

      console.log(typeof(userDataArr));
      console.log("userDataArr", userDataArr);
      console.log("PrimaryEligibility", typeof(userDataArr[0]['PrimaryEligibility']['primary_memberid']));
      console.log("SecondaryEliginility", typeof(userDataArr[0]['SecondaryEliginility']));
      // return


      let usersub = "";

      if (localStorage.getItem('USER-DETAILS')) {
        let userDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));

        if(userDetails.sub){
          usersub = userDetails.sub;
        }
      }

      //user email check

      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id'),
          prefixval: prefixval,
          domainval: domainval
        }
      });
      console.log(params);
      this.screen.style.display = 'block';
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/useremailchecknew', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe(async (response: any) => {
        console.log(response);
        if(confirm("Email will be created from '"+prefixval+response.data+"@"+domainval+"' to '"+prefixval+String(parseInt(response.data)+userDataArr.length-1)+"@"+domainval+"'. Do you want to continue?")) {
          
          this.screen.style.display = 'none';

          var patientlength = userDataArr.length;
          let syncDetailsModal = document.getElementById("upload-details-ankerTag") as HTMLElement;
          syncDetailsModal.click();
          this.uploadhtmlToAdd = "<div><b>Uploading process started...</b></div>";
          this.uploadhtmlToAdd += "<div><b>Please wait for the 'Close' button at the end...</b></div>";
          this.uploadStatus = "Running";
          var counter = 0;

         
          var attrError = false;

         
          for(var j=0; j<userDataArr.length; j++) {
            var given_name = "";
            var family_name = "";
            var birthdate = "";
            let params = {
              userpull_id: localStorage.getItem('userpull_id'),
              group: "Patient",
              email: prefixval+String(parseInt(response.data)+j)+"@"+domainval
            };
            for(var k = 0; k < userDataArr[j]['Attributes'].length; k++) {
              console.log("attr loop")
              params[userDataArr[j]['Attributes'][k]['Name']] = userDataArr[j]['Attributes'][k]['Value'];
              if (userDataArr[j]['Attributes'][k]['Name'] === 'given_name'){
                given_name = userDataArr[j]['Attributes'][k]['Value'];
              }
              if (userDataArr[j]['Attributes'][k]['Name'] === 'family_name'){
                family_name = userDataArr[j]['Attributes'][k]['Value'];
              }
              if (userDataArr[j]['Attributes'][k]['Name'] === 'birthdate'){
                birthdate = userDataArr[j]['Attributes'][k]['Value'];
              }
            }
            if(this.has_facility && parseInt(selfacilityval) > 0){
              params['facilityid']=selfacilityval;
            }
            params['modules']=this.ImportModule;
            params['reporting_provider']=Reporting_provider;
            console.log(params);

            // console.log("kkkkkkkkk", userDataArr[j]['Excel'])
            var tempexcel = userDataArr[j]['Excel'];
            // console.log("gggggggggg", tempexcel)

            if(this.ImportType == 'Auto_Demography'){
              attrError = true;
              let subscriber = {
                memberID: userDataArr[j]['PrimaryEligibility']['primary_memberid'],
                firstName: given_name,
                lastName: family_name,
                dob: birthdate
              }

              let autoparams = {
                userpull_id: localStorage.getItem('userpull_id'),
                user_type: "user",
                user_id: usersub,
                doS_StartDate: this.formatDateM(this.uploadDate),
                doS_EndDate: this.formatDateM(this.uploadDate),
                isSubscriberPatient: true,
                payerCode: userDataArr[j]['PrimaryEligibility']['primary_payercode'],
                payername: userDataArr[j]['PrimaryEligibility']['primary_payername'],
                type: "Primary",
                subscriber: subscriber,
                Attributes: userDataArr[j]['Attributes']
              };


              var autoresponse: any;
              autoresponse = await this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/geteligibility', autoparams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) }).toPromise()


              console.log("autoresponse", autoresponse)

              if(autoresponse.status == "success") {
                attrError = false;
                let newattr = JSON.parse(autoresponse.Attributes);
                console.log("newattr", newattr)

                for(var k = 0; k < newattr.length; k++) {
                  params[newattr[k]['Name']] = newattr[k]['Value'];
                }
              }



            }

            // return

            var PatientCreation = "";
            var PatientCreationRemarks = "";
            var PrimaryEligibilityStatus = "";
            var PrimaryEligibilityRemarks = "";
            var SecondaryEligibilityStatus = "";
            var SecondaryEligibilityRemarks = "";

            counter++;

            if (attrError == false){
              var cresponse: any;
              cresponse = await this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/createuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) }).toPromise()
              
              console.log(cresponse);
              
              if(cresponse.status=="success") {
                this.uploadhtmlToAdd += '<div class="success"><b>'+counter+' out of '+patientlength+'.</b> Patient create successful </div>';
                PatientCreation = "Success";
                PatientCreationRemarks = "Patient create successful";
                let responsedata = JSON.parse(cresponse.data);

                console.log(responsedata.usersub)

                if (Object.keys(userDataArr[j]['PrimaryEligibility']).length > 0){
                  console.log("Primary Call")

                  let subscriber = {
                    memberID: userDataArr[j]['PrimaryEligibility']['primary_memberid'],
                    firstName: given_name,
                    lastName: family_name,
                    dob: birthdate
                  }

                  let params = {
                    userpull_id: localStorage.getItem('userpull_id'),
                    user_type: "user",
                    user_id: usersub,
                    doS_StartDate: this.formatDateM(this.uploadDate),
                    doS_EndDate: this.formatDateM(this.uploadDate),
                    isSubscriberPatient: true,
                    payerCode: userDataArr[j]['PrimaryEligibility']['primary_payercode'],
                    payername: userDataArr[j]['PrimaryEligibility']['primary_payername'],
                    type: "Primary",
                    sub: responsedata.usersub,
                    subscriber: subscriber
                  };

                  console.log("Primary Call Param", params)

                  // Primary Eligibility
                  var presponse: any;
                  presponse = await this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/geteligibility', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) }).toPromise()

                  if(presponse.status == "success") {
                    PrimaryEligibilityStatus = "Success";
                    PrimaryEligibilityRemarks = "Primary insurance save successful";
                  }else{
                    PrimaryEligibilityStatus = "Failed";
                    PrimaryEligibilityRemarks = "Primary insurance could not save";
                    if(presponse.message != "") {
                      PrimaryEligibilityRemarks = presponse.message;
                    }
                  }
                }

                if (Object.keys(userDataArr[j]['SecondaryEliginility']).length > 0){
                  console.log("Secondary Call")

                  let subscriber = {
                    memberID: userDataArr[j]['SecondaryEliginility']['secondary_memberid'],
                    firstName: given_name,
                    lastName: family_name,
                    dob: birthdate
                  }

                  let params = {
                    userpull_id: localStorage.getItem('userpull_id'),
                    user_type: "user",
                    user_id: usersub,
                    doS_StartDate: this.formatDateM(this.uploadDate),
                    doS_EndDate: this.formatDateM(this.uploadDate),
                    isSubscriberPatient: true,
                    payerCode: userDataArr[j]['SecondaryEliginility']['secondary_payercode'],
                    payername: userDataArr[j]['SecondaryEliginility']['secondary_payername'],
                    type: "Secondary",
                    sub: responsedata.usersub,
                    subscriber: subscriber
                  };

                  console.log("Secondary Call Param", params)

                  // Secondary Eligibility
                  var sresponse: any;
                  sresponse = await this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/geteligibility', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) }).toPromise()

                  if(sresponse.status == "success") {
                    SecondaryEligibilityStatus = "Success";
                    SecondaryEligibilityRemarks = "Supplemental insurance save successful";
                  }else{
                    SecondaryEligibilityStatus = "Failed";
                    SecondaryEligibilityRemarks = "Supplemental insurance could not save";
                    if(sresponse.message != "") {
                      SecondaryEligibilityRemarks = sresponse.message;
                    }
                  }
                }
              }
              else {
                PatientCreation = "Failed";
                PatientCreationRemarks = "Patient could not create";
                this.uploadhtmlToAdd += '<div class="error"><b>'+counter+' out of '+patientlength+'.</b>Patient could not create ';
                if(cresponse.message != "") {
                  PatientCreationRemarks = cresponse.message;
                }
              }
            }else {
              PatientCreation = "Failed";
              PatientCreationRemarks = "Patient could not create";
              this.uploadhtmlToAdd += '<div class="error"><b>'+counter+' out of '+patientlength+'.</b>Patient could not create ';
            }

            console.log("pppppppppp", tempexcel)

            tempexcel['Patient Creation Status'] = PatientCreation;
            tempexcel['Patient Creation Remark'] = PatientCreationRemarks;

            if(this.ImportType == 'Patient_Demography_Insurance' || this.ImportType == 'Auto_Demography'){

              tempexcel['Primary Eligibility Status'] = PrimaryEligibilityStatus;
              tempexcel['Primary Eligibility Remark'] = PrimaryEligibilityRemarks;

              tempexcel['Secondary Eligibility Status'] = SecondaryEligibilityStatus;
              tempexcel['Secondary Eligibility Remark'] = SecondaryEligibilityRemarks;
            }

            this.uploadExcel.push(tempexcel);            

            if(counter == userDataArr.length) {
              this.uploadhtmlToAdd += '<div><b>Uploading process completed...</b></div>';
              this.uploadStatus = "Complete";
            }

              
            
          }
        }
        else {
          this.screen.style.display = 'none';
        }
      },
      async (error: any) => {
        Auth.signOut();
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.screen.style.display = 'none';
        this.router.navigateByUrl('/sign-in');
      });
    }
    else {
      var errMsg = 'There is some error. Please check following:';
      this.errorUploadMsg = errMsg+"<br>Please choose correct file";
      return false;
    }
    this.worksheet = "";
    this.myInputVariable.nativeElement.value = "";
  }

  UploadCloseButtonClick = () => {
    this.getPatientList(this, 'All');
  }

  downloadUploadExcelButtonClick = (ImportType) => {
    console.log("downloadexcel");
    console.log(ImportType);
    console.log(this.uploadExcel)

    var TempExcel = []
    for(var tempexcel of this.uploadExcel){
      console.log(tempexcel)
      for (var k = 0; k < tempexcel.length; k++){
        console.log("attr")
        console.log(tempexcel[k])
      }
    }
    if (this.uploadExcel.length > 0){
      /* table id is passed over here */   
      const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.uploadExcel);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, "UploadReport.xlsx");
    }else{
      alert("All Items In-Hand Quantity is grater than reorder minimum Quantity");
    }
  }

  formatTime = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes();
      var strhour = ''+ hours;
      var strminute = '' + minutes;
      if(hours < 10){
        strhour = '0' + hours;
      }
      if(minutes < 10){
        strminute = '0' + minutes;
      }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-')+'T'+strhour+":"+strminute;
  }

  smstyperemindChange = (remindval, remindtype) => {
    console.log(remindval);
    console.log(remindtype);
    if(remindtype == 15){
      this.smsTempTextRemind15 = "";
    }
    if(remindtype == 10){
      this.smsTempTextRemind10 = "";
    }
    if(remindtype == 7){
      this.smsTempTextRemind7 = "";
    }
    if(remindtype == 5){
      this.smsTempTextRemind5 = "";
    }
    if(remindtype == 3){
      this.smsTempTextRemind3 = "";
    }
    if(remindtype == 1){
      this.smsTempTextRemind = "";
    }
    console.log(this.smsTempTextList);
    for(let i=0; i<this.smsTempTextList.length; i++){
      if(remindtype == 15 && this.smsTempTextList[i]['shortname'] == remindval) {
        this.smsTempTextRemind15 = this.smsTempTextList[i]['en-US']['smstext'];
      }
      if(remindtype == 10 && this.smsTempTextList[i]['shortname'] == remindval) {
        this.smsTempTextRemind10 = this.smsTempTextList[i]['en-US']['smstext'];
      }
      if(remindtype == 7 && this.smsTempTextList[i]['shortname'] == remindval) {
        this.smsTempTextRemind7 = this.smsTempTextList[i]['en-US']['smstext'];
      }
      if(remindtype == 5 && this.smsTempTextList[i]['shortname'] == remindval) {
        this.smsTempTextRemind5 = this.smsTempTextList[i]['en-US']['smstext'];
      }
      if(remindtype == 3 && this.smsTempTextList[i]['shortname'] == remindval) {
        this.smsTempTextRemind3 = this.smsTempTextList[i]['en-US']['smstext'];
      }
      if(remindtype == 1 && this.smsTempTextList[i]['shortname'] == remindval) {
        this.smsTempTextRemind = this.smsTempTextList[i]['en-US']['smstext'];
      }
    }
  }

  smstypereplyChange = (replyval) => {
    console.log(replyval);
    this.smsTempTextReply = "";
    console.log(this.smsTempTextList);
    for(let i=0; i<this.smsTempTextList.length; i++){
      if(this.smsTempTextList[i]['shortname'] == replyval) {
        this.smsTempTextReply = this.smsTempTextList[i]['en-US']['smstext'];
      }
    }
  }

  showCritical: boolean = false;

  getDefaultSettings = () => {
    this.smsTempTextRemind15 = "";
    this.smsTempTextRemind10 = "";
    this.smsTempTextRemind7 = "";
    this.smsTempTextRemind5 = "";
    this.smsTempTextRemind3 = "";
    this.smsTempTextRemind = "";
    this.smsTempTextReply = "";
    this.defaultsettingDetails = "";
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id')
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsettings', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if (response.status === "success") {
        let responsedata = JSON.parse(response.data);
        for(let i=0; i<responsedata[0]['Settings'].length; i++) {
          responsedata[0]['Settings'][i]['global_settings_arr'] = JSON.parse(responsedata[0]['Settings'][i]['global_settings']);
          responsedata[0]['Settings'][i]['merge_settings_arr']={};
          for (let key of Object.keys(responsedata[0]['Settings'][i]['global_settings_arr'])) {
            responsedata[0]['Settings'][i]['merge_settings_arr'][key]=responsedata[0]['Settings'][i]['global_settings_arr'][key];
          }
//           attr == "sysData" and (float(reqval) <= 90 or float(reqval) >= 180)
// attr == "diaData" and (float(reqval) <= 30 or float(reqval) >= 120)
// attr == "pulseData" and (float(reqval) <= 40 or float(reqval) >= 120)
// Shibu Sen
// Pulse Ox - <90% or >101%
// Shibu Sen
// Scale Difference- 5LB
// Temp - >101 or <95
// Blood Glucose - >300 or <54
          if(responsedata[0]['Settings'][i]['critical_settings']) {
            this.showCritical = true;
            responsedata[0]['Settings'][i]['critical_settings_arr'] = JSON.parse(responsedata[0]['Settings'][i]['critical_settings']);
          }
        }
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsmstemplate', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((responsesms: any) => {
          console.log(responsesms);
          this.smsTempTextList = JSON.parse(responsesms.data);
          let defaultsettingsModal = document.getElementById("default-settings-ankerTag") as HTMLElement;
          defaultsettingsModal.click();
          this.defaultsettingDetails = responsedata[0]['Settings'];
          console.log(this.defaultsettingDetails);
          if(responsedata[0]['sms_settings']){
            let sms_settings = JSON.parse(responsedata[0]['sms_settings']);
            setTimeout (() => {
              this.remindersms = sms_settings['remindersms'];
              if(sms_settings['smstyperemind15']) {
                this.smstyperemind15 = sms_settings['smstyperemind15'];
              }
              if(sms_settings['smstyperemind10']) {
                this.smstyperemind10 = sms_settings['smstyperemind10'];
              }
              if(sms_settings['smstyperemind7']) {
                this.smstyperemind7 = sms_settings['smstyperemind7'];
              }
              if(sms_settings['smstyperemind5']) {
                this.smstyperemind5 = sms_settings['smstyperemind5'];
              }
              if(sms_settings['smstyperemind3']) {
                this.smstyperemind3 = sms_settings['smstyperemind3'];
              }
              if(sms_settings['smstyperemind']) {
                this.smstyperemind = sms_settings['smstyperemind'];
              }
              for(let i=0; i<this.smsTempTextList.length; i++){
                if(this.smsTempTextList[i]['shortname'] == this.smstyperemind15) {
                  this.smsTempTextRemind15 = this.smsTempTextList[i]['en-US']['smstext'];
                }
                if(this.smsTempTextList[i]['shortname'] == this.smstyperemind10) {
                  this.smsTempTextRemind10 = this.smsTempTextList[i]['en-US']['smstext'];
                }
                if(this.smsTempTextList[i]['shortname'] == this.smstyperemind7) {
                  this.smsTempTextRemind7 = this.smsTempTextList[i]['en-US']['smstext'];
                }
                if(this.smsTempTextList[i]['shortname'] == this.smstyperemind5) {
                  this.smsTempTextRemind5 = this.smsTempTextList[i]['en-US']['smstext'];
                }
                if(this.smsTempTextList[i]['shortname'] == this.smstyperemind3) {
                  this.smsTempTextRemind3 = this.smsTempTextList[i]['en-US']['smstext'];
                }
                if(this.smsTempTextList[i]['shortname'] == this.smstyperemind) {
                  this.smsTempTextRemind = this.smsTempTextList[i]['en-US']['smstext'];
                }
              }
              this.replysms = sms_settings['replysms'];
              if('replysmsprofile' in sms_settings) {
                this.replysmsprofile = sms_settings['replysmsprofile'];
              }
              if('profilevalidation' in sms_settings) {
                this.profilevalidation = sms_settings['profilevalidation'];
              }
              this.smstypereply = sms_settings['smstypereply'];
              for(let i=0; i<this.smsTempTextList.length; i++){
                if(this.smsTempTextList[i]['shortname'] == this.smstypereply) {
                  this.smsTempTextReply = this.smsTempTextList[i]['en-US']['smstext'];
                }
              }
            }, 100);
          }
          this.screen.style.display = 'none';
        },
        async (error: any) => {
          console.log(error);
          this.screen.style.display = 'none';
        });
      }
      else {
        alert("Could not find Default Settings");
        this.screen.style.display = 'none';
      }
    },
    async (error: any) => {
      alert("Some error occurred. Please try again.");
      this.screen.style.display = 'none';
    });
  }

  validateDefaultSettingForm= ( settingsData ) =>{
    console.log(settingsData);
    // return false;
    let devicealert = [];
    let devicecriticalalert = [];
    let devicealertsettings = [];
    let devicecriticalalertsettings = [];
    let smssettings = {};
    let keyparsearr = ['device_id','device_type_id','enabled'];
    let patientoverwrite = false;
    let smsarr = ["remindersms", "smstyperemind15", "smstyperemind10", "smstyperemind7", "smstyperemind5", "smstyperemind3", "smstyperemind", "replysms", "smstypereply", "replysmsprofile", "profilevalidation"];
    let settingsignorearr = ["patientoverwrite", "patientfix", "remindersms", "smstyperemind15", "smstyperemind10", "smstyperemind7", "smstyperemind5", "smstyperemind3", "smstyperemind", "replysms", "smstypereply", "replysmsprofile", "profilevalidation"];
    for (let key of Object.keys(settingsData)) {
      if(settingsignorearr.indexOf(key) == -1) {
        if(key.indexOf('critical') !== -1){
          let keyarr = key.split("-");
          if(!devicecriticalalert[keyarr[0]]) {
            devicecriticalalert[keyarr[0]] = {};
            devicecriticalalert[keyarr[0]]['device_type_id'] = keyarr[1];
            devicecriticalalert[keyarr[0]]['alert_settings'] = {};
          }
          if(keyparsearr.indexOf(keyarr[2]) > -1) {
            devicecriticalalert[keyarr[0]][keyarr[2]] = settingsData[key];
          }
          else {
            devicecriticalalert[keyarr[0]]['alert_settings'][keyarr[2]] = settingsData[key];
          }
        }
        else {
          let keyarr = key.split("-");
          if(!devicealert[keyarr[0]]) {
            devicealert[keyarr[0]] = {};
            devicealert[keyarr[0]]['device_type_id'] = keyarr[1];
            devicealert[keyarr[0]]['alert_settings'] = {};
          }
          if(keyparsearr.indexOf(keyarr[2]) > -1) {
            devicealert[keyarr[0]][keyarr[2]] = settingsData[key];
          }
          else {
            devicealert[keyarr[0]]['alert_settings'][keyarr[2]] = settingsData[key];
          }
        }
      }
      else if(smsarr.indexOf(key) > -1){
        smssettings[key] = settingsData[key];
      }
      else {
        if(settingsData[key] == true) {
          patientoverwrite = true;
        }
      }
    }
    console.log(devicealert);
    for (let devicekey of Object.keys(devicealert)) {
      devicealertsettings.push(devicealert[devicekey]);
    }
    console.log(devicealertsettings);
    console.log(devicecriticalalert);
    for (let devicekey of Object.keys(devicecriticalalert)) {
      devicecriticalalertsettings.push(devicecriticalalert[devicekey]);
    }
    console.log(devicecriticalalertsettings);
    console.log(smssettings);
    // return false;
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      device_alert_settings: devicealertsettings,
      sms_settings: smssettings
    };
    if(devicecriticalalertsettings.length > 0){
      params['device_critical_alert_settings'] = devicecriticalalertsettings;
    }
    console.log(params);
    // return false;
    let settingClass = this;
    settingClass.screen.style.display = 'block';
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/saveglobalalertsettings', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if(response.status == "success") {
        alert("Global Alert Settings updated successfully");
        if(patientoverwrite) {
          console.log('overwrite');
        }
      }
      else {
        alert("Could not update global alert settings");
      }
      settingClass.screen.style.display = 'none';
    },
    async (error: any) => {
      console.log(error);
      alert("Could not update global alert settings");
      settingClass.screen.style.display = 'none';
    });
  }

  formatDBDateTime = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes();
      var strhour = ''+ hours;
      var strminute = '' + minutes;
      if(hours < 10){
        strhour = '0' + hours;
      }
      if(minutes < 10){
        strminute = '0' + minutes;
      }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-')+" "+strhour+":"+strminute+":00";
  }

  formatMonth = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month].join('-');
  }

  ngOnInit() {
    this.getGlobalConfig();
    this.getCustomList();
    this.defaultdeviceReading = {'diaMax': 'DIA Max', 'diaMin': 'DIA Min', 'pulseMax': 'Pulse Max', 'pulseMin': 'Pulse Min', 'sysMax': 'SYS Max', 'sysMin': 'SYS Min', 'wtzdiffer': 'Weight Differ', 'bmiMax': 'BMI Max', 'bmiMin': 'BMI Min', 'wtMax': 'Weight Max', 'wtMin': 'Weight Min', 'prMax': 'PR Max', 'prMin': 'PR Min', 'spo2Max': 'SPO2 Max', 'spo2Min': 'SPO2 Min', 'tempMax': 'Temp. Max', 'tempMin': 'Temp. Min', 'glucoseAfterMealMax':'After Meal Max', 'glucoseAfterMealMin': 'After Meal Min', 'glucoseFastingMax': 'Fasting Max', 'glucoseFastingMin': 'Fasting Min', 'glucoseNormalTestMax': 'Normal Max', 'glucoseNormalTestMin': 'Normal Min'}
    this.iotPracticeSubs = [];
    this.screen.style.display = 'block';
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      // dom: 'lBfrtip',
      dom: '<"col-sm-2"B><"col-sm-5"l><"col-sm-5"f>rt<"col-sm-5"i><"col-sm-7"p>',
      // Configure the buttons
      buttons: [
        // 'colvis',
        // 'copy',
        // 'print',
        'excel'
      ],
      stateSave: true,
      stateSaveCallback: function(settings,data) {
        sessionStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data));
      },
      stateLoadCallback: function(settings) {
        return JSON.parse(sessionStorage.getItem('DataTables_' + settings.sInstance ))
      }
    };

    this.dtOptionsPayer = {
      // Declare the use of the extension in the dom parameter
      // dom: 'lBfrtip',
      // dom: '<"col-sm-2"B><"col-sm-5"l><"col-sm-5"f>rt<"col-sm-5"i><"col-sm-7"p>',
      dom: '<"col-sm-5"l><"col-sm-5"f>rt<"col-sm-5"i><"col-sm-7"p>',
      // Configure the buttons
      buttons: [
        // 'colvis',
        // 'copy',
        // 'print',
        // 'excel'
      ],
      stateSave: true
    };


    this.dtTrigger.next();
    this.dtTrigger1.next();
    this.dtTrigger2.next();
    this.dtTrigger3.next();
    this.dtTrigger4.next();

    // const params = new HttpParams({
    //   fromObject: {
    //     userpull_id: localStorage.getItem('userpull_id')
    //   }
    // });
    // this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/rdssync', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    // .subscribe((response: any) => { 
      if(sessionStorage.getItem('cpasssub')) {
        let cpassub = sessionStorage.getItem('cpasssub');
        sessionStorage.removeItem("cpasssub");
        sessionStorage.setItem('cpass', 'true');
        setTimeout (() => {
          this.router.navigate(["/patient-detail/" + btoa(cpassub)]);
        }, 500);
      }
      else {
        // this.getPatientList(this);
        // this.resetGlobalSearchForm();
        this.screen.style.display = 'none';
      }
    // },
    // async (error: any) => {
    //   Auth.signOut();
    //   localStorage.removeItem('AUTH');
    //   localStorage.removeItem('AUTH-LIFE');
    //   localStorage.removeItem('AUTH-PROFILE-PIC');
    //   localStorage.removeItem('USER-DETAILS');
    //   //PatientClass.processing.style.display = 'none';
    //   this.screen.style.display = 'none';
    //   this.router.navigateByUrl('/sign-in');
    // });

    if(this.usercheckShow && sessionStorage.getItem('userclass') != null && sessionStorage.getItem('userclass') == 'true') {
      this.userclass = true;
    }
    else{
      this.userclass = false;
      sessionStorage.removeItem('userclass');
    }

    this.PccpatientsyncStatus("load");
  }

  listenerFn:any;

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.dtTrigger1.next();
    this.dtTrigger2.next();
    this.dtTrigger3.next();
    this.dtTrigger4.next();

    this.listenerFn = this.renderer.listenGlobal('document', 'click', (event) => {
      // console.log(event.target);
      if (event.target.hasAttribute("view-person-id")) {
        // console.log("/patient-detail/" + event.target.getAttribute("view-person-id"));
        // this.router.navigate(["/patient-detail/" + event.target.getAttribute("view-person-id")]);
        // window.open("/patient-detail/" + event.target.getAttribute("view-person-id"), "PatientProfile");
        window.open("/patient-detail/" + event.target.getAttribute("view-person-id"));
      }
    });
  }

  ngOnDestroy() {
    this.listenerFn();
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
    this.dtTrigger2.unsubscribe();
    this.dtTrigger3.unsubscribe();
    this.dtTrigger4.unsubscribe();
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  selprovider = (ProviderClass, provider) =>{
    sessionStorage.setItem('DataTables_Provider', provider);
    // this.getPatientList(ProviderClass);
  }

  autoEmailFilter(selectedclass:boolean){
    this.autoEmail = selectedclass;
    this.patientFormEmail = "";
  }

  assigntoall = false;
  onSelectProvider(selclass:boolean){
    this.assigntoall = selclass;
    console.log(this.assigntoall);
  }

  onSelectUser(selectedclass:boolean){
    console.log(selectedclass);
    this.userclass = selectedclass;
    sessionStorage.setItem('userclass', selectedclass.toString());
    // this.getPatientList(this);
  }

  onSelectFilter(selectedclass:boolean,filter:any){
    filter.selectedclass=selectedclass;
    // console.log(filter);
    this.selectedPatientStatus = true;
    if(this.patientList.every(filter=>!filter.selectedclass)){
      this.selectedPatientStatus = false;
    }
    if (selectedclass) {
      let selectedcounter = 0;
      this.patientList.forEach(filter=>{if(filter.selectedclass==true){selectedcounter++}});
      if(selectedcounter == this.patientList.length) {
        this.patientallselected = true;
      }
    }
    else {
      this.patientallselected = false;
    }
    if(this.step == 'Pending' || this.step == 'ActiveNoConsent') {
      this.selectedConsentStatus = this.selectedPatientStatus;
    }
  }

  onSelectAllFilter(selectedall:boolean){
    this.patientallselected = selectedall;    

    // if(selectedall) {
    //   this.patientList.forEach(filter=>{filter.selectedclass=true});
    //   this.selectedPatientStatus = true;
    // }

    if(selectedall) {
      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
        if(dtElement.dtInstance) {
          dtElement.dtInstance.then((dtInstance: any) => {
            if(dtInstance.table().node().id == "patient-table") {
              var indexList = dtInstance.rows( {search: 'applied'} ).toArray();
              for(var index of indexList[0]){
                var temppatient = this.patientList[index];
                temppatient["selectedclass"] = true;
                this.patientList[index] = temppatient;
              }
            }
          });
        }
      });
      this.selectedPatientStatus = true;
    }

    if(!selectedall) {
      this.patientList.forEach(filter=>{filter.selectedclass=false});
      this.selectedPatientStatus = false;
    }
    if(this.step=='Pending' || this.step == 'ActiveNoConsent') {
      this.selectedConsentStatus = this.selectedPatientStatus;
    }
  }

  viewConsent = () =>{
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id')
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getconsent', { params })
    .subscribe((response: any) => {
      if (response.status === "success") {
        this.consentLang = "en-US";
        this.consentdata = {};
        console.log(this.consentdata);
        setTimeout (() => {
          this.consentLang = "en-US";
          this.consentdata = JSON.parse(response.data);
          console.log(this.consentdata);
          let consentModal = document.getElementById("patient-consent-ankerTag") as HTMLElement;
          consentModal.click();
        }, 500);
      }
      this.screen.style.display = 'none';
    },
    async (error: any) => {
      console.log(error);
    });
  }

  consentLangChange = (langval) => {
    console.log(langval);
    this.consentLang = langval;
  }

  formatUTCDateCustom = (date) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const days = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat'
    ]
    var d = new Date(date),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getUTCFullYear(),
      hour = d.getUTCHours(),
      minute = d.getUTCMinutes();
    
    var time = "AM";
    if(hour >= 12) {
      time = "PM";
      if(hour > 12) {
        hour = hour - 12;
      }
    }
    var strhour = ''+ hour;
    if(hour == 0) {
      strhour = '12';
    }
    else if(hour < 10){
      strhour = '0' + hour;
    }
    var strminute = '' + minute;
    if(minute < 10){
      strminute = '0' + minute;
    }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    // return [year, month, day].join('-');
    return strhour+":"+strminute+" "+time+" "+days[d.getUTCDay()]+" "+day+" "+months[d.getUTCMonth()]+" "+year;
  }

  updateConsentEdu = (consentval, eduval) =>{
    this.consentval = consentval;
    this.eduval = eduval;
    let consentEduModal = document.getElementById("consent-edu-update-ankerTag") as HTMLElement;
    consentEduModal.click();
  }

  validateConsentEduUpdateForm = (formval) =>{
    console.log(formval);
    let processedArr = [];
    for(var i=0; i<this.patientList.length; i++) {
      if(this.patientList[i]['selectedclass']) {
        processedArr.push(this.patientList[i]);
      }
    }
    console.log(processedArr);
    if( !formval.consentdate || typeof formval.consentdate === 'undefined' || formval.consentdate==''){
      alert("Please choose the date");
      return false;
    }
    let date_value = formval['consentdate'];
    let formatted_date = "";
    let con_date = date_value.split("-");
    let Date_format = con_date[1]+'/'+con_date[2]+'/'+con_date[0]+' 00:00:00';
    formatted_date = this.formatUTCDateCustom(Date_format);
    let consentDetailsModal = document.getElementById("consent-edu-update-ankerTag") as HTMLElement;
    consentDetailsModal.click();
    if(processedArr.length > 0){
      this.consentStatus = "Running";
      var processedArrLength = processedArr.length;
      let consentDetailsModal = document.getElementById("consent-details-ankerTag") as HTMLElement;
      consentDetailsModal.click();
      this.htmlToAddConsent = "<div><b>Update process started...</b></div>";
      this.htmlToAddConsent += "<div><b>Please wait for the 'Close' button at the end...</b></div>";
      var counter = 0;
      var countersuccess = 0;
      var counterfail = 0;
      for (let k = 0; k < processedArrLength; k++) {
        setTimeout (() => {
          let userData = [];
          if(this.consentval) {
            userData.push({Name:"custom:consent",Value:"1"})
            userData.push({Name:"custom:consentDate",Value:formatted_date})
          }
          if(this.eduval){
            userData.push({Name:"custom:deviceEducationDate",Value:formatted_date})
          }
          console.log(userData);
          let params = {
            userpull_id: localStorage.getItem('userpull_id'),
            group: "Patient",
            sub: processedArr[k]['sub']
          };
          for(var l = 0; l < userData.length; l++) {
            params[userData[l]['Name']] = userData[l]['Value'];
          }
          console.log(params);
          this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
            .subscribe((response: any) => {
              counter++;
              countersuccess++;
              if(response.status == "success") {
                this.htmlToAddConsent += '<div class="success"><b>'+counter+' out of '+processedArrLength+'.</b> '+response.message+'</div>';
              }
              else {
                this.htmlToAddConsent += '<div class="error"><b>'+counter+' out of '+processedArrLength+'.</b> '+response.message+'</div>';
              }
              if(counter == processedArrLength) {
                this.htmlToAddConsent += '<div><b>Update process completed...</b></div>';
                this.consentStatus = "Complete";
              }
            },
            async (error: any) => {
              counter++;
              counterfail++;
              if(counter == processedArrLength) {
                this.consentStatus = "Complete";
              }
            });
        }, 100*(k+1));
      }
    }
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  
  goToLink(url: string){
    // window.open(url, "PatientProfile");
    window.open(url);
  }

  consentTemplateList = [];
  selectedPatientData = {};
  selectedconsenttemplate = "none";

  downloadConsent = (patientdata, templateunique="") =>{
    console.log(patientdata);
    console.log(templateunique);
    if(templateunique == ""){
      this.selectedPatientData = patientdata;
      this.consentTemplateList = [];

      this.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id')
      };
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getconsenttemplate', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
        console.log(response);
        this.screen.style.display = 'none';
        if(response.status == "success") {
          this.consentTemplateList = JSON.parse(response.data);
          console.log(this.consentTemplateList);
          let patientConsentTemplateModal = document.getElementById("patient-consent-template-ankerTag") as HTMLElement;
          patientConsentTemplateModal.click();
        }
      });
    }
    else{
      console.log(this.consentTemplateList);
      if(templateunique == "none") {
        alert("Please select a template");
        return false;
      }
      else {
        var consentlink = document.location.origin+'/patientconsent/'+encodeURIComponent(btoa(localStorage.getItem('userpull_id')))+'/'+encodeURIComponent(btoa(patientdata['sub']))+'/'+encodeURIComponent(btoa(templateunique));
        console.log(consentlink);
        this.goToLink(consentlink);
        let patientConsentTemplateModal = document.getElementById("patient-consent-template-ankerTag") as HTMLElement;
        patientConsentTemplateModal.click();
      }
    }
  }

  patientReading = (patientdata) =>{
    console.log(patientdata);
    this.nonvalidatedLink = "";
    this.validatedLink = "";
    this.readingpatient = {};
    setTimeout (() => {
      this.readingpatient = patientdata;
      this.nonvalidatedLink = document.location.origin+'/profile/'+encodeURIComponent(btoa(localStorage.getItem('userpull_id')))+'/'+encodeURIComponent(btoa(patientdata['sub']+'~false'));
      this.validatedLink = document.location.origin+'/profile/'+encodeURIComponent(btoa(localStorage.getItem('userpull_id')))+'/'+encodeURIComponent(btoa(patientdata['sub']+'~true'));
      let readingDetailsModal = document.getElementById("readinghistory-ankerTag") as HTMLElement;
      readingDetailsModal.click();
    }, 500);
  }

  readingsFormforcevalidate = "forceotpvalidate";

  processReadings = (formval) =>{
    let forcesms = false;
    let forcephone = false;
    let forcevalidate = false;
    let forcevalidatetype = "";
    console.log(formval);
    // return false;
    if(formval.forcesms) {
      forcesms = true;
    }
    if(formval.forcephone) {
      forcephone = true;
    }
    if(formval.forcevalidate && formval.forcevalidate != 'novalidate') {
      forcevalidate = true;
      forcevalidatetype = formval.forcevalidate
    }
    let processedArr = [];
    for(var i=0; i<this.patientList.length; i++) {
      if(this.patientList[i]['selectedclass']) {
        processedArr.push(this.patientList[i]);
      }
    }
    console.log(processedArr);
    let consentDetailsModal = document.getElementById("readings-send-ankerTag") as HTMLElement;
    consentDetailsModal.click();
    // alert("Implementation under progress...");
    if(processedArr.length > 0){
      this.consentStatus = "Running";
      var processedArrLength = processedArr.length;
      let consentDetailsModal = document.getElementById("consent-details-ankerTag") as HTMLElement;
      consentDetailsModal.click();
      this.htmlToAddConsent = "<div><b>Sending Reading Links process started...</b></div>";
      this.htmlToAddConsent += "<div><b>Please wait for the 'Close' button at the end...</b></div>";
      var counter = 0;
      var countersuccess = 0;
      var counterfail = 0;
      for (let k = 0; k < processedArrLength; k++) {
        setTimeout (() => {
          let params = {
            userpull_id: localStorage.getItem('userpull_id'),
            user_sub: localStorage.getItem('user_sub'),
            patient_sub: processedArr[k]['sub'],
            patient_name: processedArr[k]['firstName']+" "+processedArr[k]['lastName'],
            forcesms: forcesms.toString(),
            forcephone: forcephone.toString()
          }
          if(forcevalidatetype == 'forceotpvalidate') {
            params['validate'] = "otp";
          }
          if(forcevalidatetype == 'forcedobvalidate') {
            params['validate'] = "dob";
          }
          params['type'] = "InitiateReadingWithLink";
          console.log(params);
          this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/initiatereadings', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
            .subscribe((response: any) => {
              var responsereadingdata = JSON.parse(response.data);
              counter++;
              countersuccess++;
              if(response.status == "success") {
                this.htmlToAddConsent += '<div class="success"><b>'+counter+' out of '+processedArrLength+'.</b> '+response.message+'</div>';
              }
              else {
                this.htmlToAddConsent += '<div class="error"><b>'+counter+' out of '+processedArrLength+'.</b> '+response.message+'</div>';
              }
              if(counter == processedArrLength) {
                this.htmlToAddConsent += '<div><b>Sending process completed...</b></div>';
                this.consentStatus = "Complete";
              }
            },
            async (error: any) => {
              counter++;
              counterfail++;
              if(counter == processedArrLength) {
                this.consentStatus = "Complete";
              }
            });
        }, 100*(k+1));
      }
    }
  }

  processConsent = (formval) =>{
    let forcesms = false;
    let forcephone = false;
    let forcelang = false;
    if(formval.forcesms) {
      forcesms = true;
    }
    if(formval.forcephone) {
      forcephone = true;
    }
    if(formval.forcelang) {
      forcelang = true;
    }
    let processedArr = [];
    for(var i=0; i<this.patientList.length; i++) {
      if(this.patientList[i]['selectedclass']) {
        processedArr.push(this.patientList[i]);
      }
    }
    console.log(processedArr);
    let consentDetailsModal = document.getElementById("consent-send-ankerTag") as HTMLElement;
    consentDetailsModal.click();
    // alert("Implementation under progress...");
    if(processedArr.length > 0){
      this.consentStatus = "Running";
      var processedArrLength = processedArr.length;
      let consentDetailsModal = document.getElementById("consent-details-ankerTag") as HTMLElement;
      consentDetailsModal.click();
      this.htmlToAddConsent = "<div><b>Consent process started...</b></div>";
      this.htmlToAddConsent += "<div><b>Please wait for the 'Close' button at the end...</b></div>";
      var counter = 0;
      var countersuccess = 0;
      var counterfail = 0;
      for (let k = 0; k < processedArrLength; k++) {
        setTimeout (() => {
          let params = {
            userpull_id: localStorage.getItem('userpull_id'),
            user_sub: localStorage.getItem('user_sub'),
            patient_sub: processedArr[k]['sub'],
            patient_name: processedArr[k]['firstName']+" "+processedArr[k]['lastName'],
            consent_link: document.location.origin+'/consent/'+encodeURIComponent(btoa(localStorage.getItem('userpull_id')))+'/'+encodeURIComponent(btoa(processedArr[k]['sub'])),
            forcesms: forcesms.toString(),
            forcephone: forcephone.toString(),
            forcelang: forcelang.toString()
          }
          console.log(params);
          this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/initiateconsent', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
            .subscribe((response: any) => {
              var responseconsentdata = JSON.parse(response.data);
              counter++;
              countersuccess++;
              if(response.status == "success") {
                this.htmlToAddConsent += '<div class="success"><b>'+counter+' out of '+processedArrLength+'.</b> '+response.message+'</div>';
              }
              else {
                this.htmlToAddConsent += '<div class="error"><b>'+counter+' out of '+processedArrLength+'.</b> '+response.message+'</div>';
              }
              if(counter == processedArrLength) {
                this.htmlToAddConsent += '<div><b>Sending process completed...</b></div>';
                this.consentStatus = "Complete";
              }
            },
            async (error: any) => {
              counter++;
              counterfail++;
              if(counter == processedArrLength) {
                this.consentStatus = "Complete";
              }
            });
        }, 100*(k+1));
      }
    }
  }  

  processSMS = (formval) =>{
    let forcesms = false;
    let forcephone = false;
    if(formval.forcesms) {
      forcesms = true;
    }
    if(formval.forcephone) {
      forcephone = true;
    }

    if (formval.smstype != undefined) {
      this.smsTypeError = false;
    }else{
      this.smsTypeError = true;
    }

    if (formval.smsText !== ''){
      this.smsError = false;
    }else{
      this.smsError = true;
    }

    if (formval.smsText !== '' && formval.smstype != undefined) {
      this.smsError = false;
      let processedArr = [];
      for(var i=0; i<this.patientList.length; i++) {
        if(this.patientList[i]['selectedclass']) {
          processedArr.push(this.patientList[i]);
        }
      }
      let smsDetailsModal = document.getElementById("sms-send-ankerTag") as HTMLElement;
      smsDetailsModal.click();
      // alert("Implementation under progress...");
      if(processedArr.length > 0){
        this.smsStatus = "Running";
        var processedArrLength = processedArr.length;
        let smsDetailsModal = document.getElementById("sms-details-ankerTag") as HTMLElement;
        smsDetailsModal.click();
        this.htmlToAddSMS = "<div><b>SMS process started...</b></div>";
        this.htmlToAddSMS += "<div><b>Please wait for the 'Close' button at the end...</b></div>";
        var counter = 0;
        var countersuccess = 0;
        var counterfail = 0;
        for (let k = 0; k < processedArrLength; k++) {
          setTimeout (() => {
            console.log(this.smsTextList);
            console.log(formval);
            for(var temp of this.smsTextList){
              if (formval.smstype != 'Custom' && temp.shortname == formval.smstype){
                formval.smsText = temp['en-US'].smstext;
                if(temp[processedArr[k]['p_language']] && formval.patientlang) {
                  formval.smsText = temp[processedArr[k]['p_language']].smstext;
                }
                break;
              }
            }
            let params = {
              userpull_id: localStorage.getItem('userpull_id'),
              user_sub: localStorage.getItem('user_sub'),
              patient_sub: processedArr[k]['sub'],
              patient_name: processedArr[k]['firstName']+" "+processedArr[k]['lastName'],
              smstext: formval.smsText,
              forcesms: forcesms.toString(),
              forcephone: forcephone.toString(),
            }
            console.log(params);
            this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/initiatesms', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
              .subscribe((response: any) => {
                var responsesmsdata = JSON.parse(response.data);
                counter++;
                countersuccess++;
                if(response.status == "success") {
                  this.htmlToAddSMS += '<div class="success"><b>'+counter+' out of '+processedArrLength+'.</b> '+response.message+'</div>';
                }
                else {
                  this.htmlToAddSMS += '<div class="error"><b>'+counter+' out of '+processedArrLength+'.</b> '+response.message+'</div>';
                }
                if(counter == processedArrLength) {
                  this.htmlToAddSMS += '<div><b>Sending process completed...</b></div>';
                  this.smsStatus = "Complete";
                }
              },
              async (error: any) => {
                counter++;
                counterfail++;
                if(counter == processedArrLength) {
                  this.smsStatus = "Complete";
                }
              });
          }, 100*(k+1));
        }
      }
    }
  }

  summarySMS = () =>{
    this.patientlangArr = [];
    this.patientlang = false;
    this.showpatientlang = false;
    let getSmsClass = this;
    getSmsClass.screen.style.display = 'block';    
    this.smstype = undefined;
    this.smsText = "";
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
      }
    });
    // this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsmstemplatelist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getsmstemplate', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response)
      if (response.status === "success") {
        getSmsClass.screen.style.display = 'none';
        this.smsTextList = JSON.parse(response.data);
        // this.smsTextList.push({ id: 0, shortname: "Custom" })
        this.smsTextList.push({ shortname: "Custom" })
        let patientSmsModal = document.getElementById("sms-send-ankerTag") as HTMLElement;
        patientSmsModal.click();    
      }
    },
    async (error: any) => {
      console.log(error);
    });
  }

  smstypeChange = (smstype) => {
    // if (smstype > 0){
    //   for(var temp of this.smsTextList){
    //     if (temp.id == smstype){
    //       this.smsText = temp.smstext;
    //       this.smsTypeError = false;
    //       this.smsError = false;
    //       break;
    //     }
    //   }
    // }
    this.smsText = "";
    this.showpatientlang = false;
    this.patientlang = false;
    this.patientlangArr = [];
    if (smstype != "Custom"){
      for(var temp of this.smsTextList){
        if (temp.shortname == smstype){
          this.patlang = "en-US";
          this.smsText = temp['en-US'].smstext;
          this.patientlangArr.push('en-US');
          for(var i=0; i<this.patientList.length; i++) {
            if(this.patientList[i]['selectedclass']) {
              if(this.patientList[i]['p_language'] != 'en-US' && temp[this.patientList[i]['p_language']] && this.patientlangArr.indexOf(this.patientList[i]['p_language']) === -1) {
                this.showpatientlang = true;
                this.patientlangArr.push(this.patientList[i]['p_language']);
              }
            }
          }
          this.smsTypeError = false;
          this.smsError = false;
          break;
        }
      }
    }else{
      this.smsText = "";
      this.smsTypeError = false;
    }
  }

  smstextlangChange = (smslang) => {
    console.log(smslang);
    for(var temp of this.smsTextList){
      if (temp.shortname == smslang.smstype){
        this.patlang = "";
        this.smsText = "";
        if(!smslang.patientlang){
          this.patlang = "en-US";
          this.smsText = temp['en-US'].smstext;
        }
        this.smsTypeError = false;
        this.smsError = false;
        break;
      }
    }
  }

  smstextlanguageChange = (smslang) => {
    console.log(smslang);
    console.log(this.smsTextList);
    console.log(this.patlang);
    for(var temp of this.smsTextList){
      if (temp.shortname == smslang.smstype){
        this.smsText = temp['en-US'].smstext;
        if(temp[this.patlang] && smslang.patlangoption) {
          this.smsText = temp[this.patlang].smstext;
        }
        this.smsTypeError = false;
        this.smsError = false;
        break;
      }
    }
  }

  smstextChange = (smsText) => {
    if (smsText !== ''){
      this.smsError = false;
    }
  }
  paging = 0;
  allpage:any;
  users = [];

  make_number_mask = (num, masklength=7) =>{
    var numstr = num.toString();
    if(this.ismasking == false){
      return numstr;
    }
    var masked_num = numstr;
    if(numstr.length > masklength){
      masked_num = numstr.substr(0,numstr.length-masklength)+Array(masklength+1).join('x');
    }
    return masked_num;
  }

  partLoading = async (loadtype='single') => {
    if(loadtype != 'allpage') {
      this.screen.style.display = 'block';
    }
    console.log("paging--->"+(this.paging+1));
    console.log("allpage--->"+this.allpage);
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        group: 'Patient',
        user_status: this.step,
        provider: this.selectedprovider.split('-').join('_'),
        page: String(this.paging+1),
        customlist : this.selectedCustomList,
        firstName: this.Global_firstName,
        lastName : this.Global_lastName,
        birthdate : this.Global_birthdate,
        gender : this.Global_gender,
      }
    });

    if(sessionStorage.getItem('userclass') != null && sessionStorage.getItem('userclass') == 'true'){
      var logedInUserDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));
      if ('custom:group' in logedInUserDetails && logedInUserDetails['custom:group'] == 'SystemAdmin') {
        params = new HttpParams({
          fromObject: {
            userpull_id: localStorage.getItem('userpull_id'),
            useronly: logedInUserDetails['sub'],
            group: 'Patient',
            user_status: this.step,
            provider: this.selectedprovider.split('-').join('_'),
            page: String(this.paging+1),
            customlist : this.selectedCustomList,
            firstName: this.Global_firstName,
            lastName : this.Global_lastName,
            birthdate : this.Global_birthdate,
            gender : this.Global_gender,
          }
        });
      }
    }
    // console.log(params);
    // let response = {'status':'', 'pages':'', 'data':'', 'LanguageList':'', 'ProviderList':'', 'practice':''};
    let response: any = await this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/userlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) }).toPromise();
    // .subscribe((response: any) => {
    // console.log(response);
    this.patientallselected = false;
    if (response.status === "success") {
      this.allpage = Number(response.pages);
      let responsedata = JSON.parse(response.data);
      console.log(responsedata);
      this.languageList = JSON.parse(response.LanguageList);
      // console.log(PatientClass.languageList);
      this.providerlist = JSON.parse(response.ProviderList);
      // console.log(PatientClass.providerlist);
      if((this.providerlist).length > 55) {
        console.log((this.providerlist).length);
        this.providercountexceed = true;
      }
      if (responsedata.length > 0) {
        for (var j = 0; j < responsedata.length; j++) {
          responsedata[j].masked_phone_number="";
          if(responsedata[j].phone_number && responsedata[j].phone_number.length > 0){
            var phonestr = responsedata[j].phone_number.toString();
            responsedata[j].masked_phone_number = this.make_number_mask(phonestr);
          }
          responsedata[j].masked_phoneNoSms="";
          if(responsedata[j].phoneNoSms && responsedata[j].phoneNoSms.length > 0){
            var smsphonestr = responsedata[j].phoneNoSms.toString();
            responsedata[j].masked_phoneNoSms = this.make_number_mask(smsphonestr);
          }
          responsedata[j]['patientStatus'] = "";
          responsedata[j]['patientEduStatus'] = "";
          responsedata[j]['patientSettingsStatus'] = "";
          responsedata[j]['patientEligibilityStatus'] = "";
          if(responsedata[j]['deviceEducationDate'] != '0000-00-00 00:00:00'){
            responsedata[j]['patientEduStatus'] = "true";
          }
          else {
            responsedata[j]['patientEduStatus'] = "false";
          }
          if(responsedata[j]['getuserDevices'].length > 0){
            responsedata[j]['patientSettingsStatus'] = "true";
          }
          else{
            responsedata[j]['patientSettingsStatus'] = "false";
          }
          if(responsedata[j]['eligibility_status'] && responsedata[j]['eligibility_status']=="Yes"){
            responsedata[j]['patientEligibilityStatus'] = "true";
          }
          else{
            responsedata[j]['patientEligibilityStatus'] = "false";
          }
          var patientEligibiLity = [];
          if(responsedata[j]['is_save_primary']== 0){
            patientEligibiLity.push("Primary - Missing")
          }
          if(responsedata[j]['is_eligibled']== 1){
            if (responsedata[j]['primary_payername'] != null){
              patientEligibiLity.push("Primary("+responsedata[j]['primary_payername']+") - Active");
            }else{
              patientEligibiLity.push("Primary - Active");
            }
          }else{
            if(responsedata[j]['is_save_primary']== 1){
              if (responsedata[j]['primary_payername'] != null){
                patientEligibiLity.push("Primary("+responsedata[j]['primary_payername']+") - Inactive");
              }else{
                patientEligibiLity.push("Primary - Inactive");
              }
            }
          }
          if(responsedata[j]['is_ppo']== 1){
            patientEligibiLity.push("PPO")
          }
          if(responsedata[j]['is_msp']== 1){
            patientEligibiLity.push("MSP")
          }
          if(responsedata[j]['is_hmo']== 1){
            patientEligibiLity.push("HMO")
          }
          //Secondary
          if(responsedata[j]['is_save_secondary']== 0){
            responsedata[j]['patientEligibilitistr_secondary'] = "Secondary - Missing";
          }
          if(responsedata[j]['is_save_secondary'] == 1 && responsedata[j]['is_secondary_ins']== 0){
            if (responsedata[j]['secondary_payername'] != null ){
              responsedata[j]['patientEligibilitistr_secondary'] = "Secondary("+responsedata[j]['secondary_payername']+") - Inactive";
            }else{
              responsedata[j]['patientEligibilitistr_secondary'] = "Secondary - Inactive";
            }
          }
          if(responsedata[j]['is_secondary_ins']== 1){
            if (responsedata[j]['secondary_payername'] != null){
              responsedata[j]['patientEligibilitistr_secondary'] = "Secondary("+responsedata[j]['secondary_payername']+") - Active";
            }else{
              responsedata[j]['patientEligibilitistr_secondary'] = "Secondary - Active";
            }
          }
          responsedata[j]['patientEligibilitistr_primary'] = patientEligibiLity.join(" & ");
          if(responsedata[j]['dataTransmissionDate'] != '0000-00-00 00:00:00' && responsedata[j]['assign'] != ""){
            responsedata[j]['patientStatus'] = "Active";
          }
          else if(responsedata[j]['assign'] == ""){
            responsedata[j]['patientStatus'] = "Inactive";
          }
          else if(responsedata[j]['consent'] == 1 && responsedata[j]['dataTransmissionDate'] == '0000-00-00 00:00:00'){
            responsedata[j]['patientStatus'] = "Consented";
          }
          else if(responsedata[j]['consent'] == 0 && responsedata[j]['dataTransmissionDate'] == '0000-00-00 00:00:00' && responsedata[j]['assign'] != ""){
            responsedata[j]['patientStatus'] = "Registered";
          }
          for(var x=0; x<this.languageList.length; x++) {
            if(this.languageList[x]['language_code'] == responsedata[j]['p_language']) {
              var lang = this.languageList[x]['language_value'];
              if(lang.indexOf( "(" ) > -1) {
                lang = lang.substring(0, lang.indexOf( "(" ));
              }
              responsedata[j]['language'] = lang.trim();
            }
          }
          responsedata[j]['consent_txt'] = responsedata[j]['consent_status']['consent_msg'];
          if(responsedata[j]['consent_status']['consent_date'] && responsedata[j]['consent_status']['consent_date'] != ""){
            responsedata[j]['consent_txt'] = responsedata[j]['consent_txt'] + " on " + this.formatTimeM(responsedata[j]['consent_status']['consent_date'].replaceAll('-','/') + " UTC");
          }
          if(responsedata[j]['consent_txt'].trim() == "NA" && responsedata[j]['consent'] > 0) {
            responsedata[j]['consent_txt'] = "Manually Consented";
            if(responsedata[j]['consentDate'] != "0000-00-00 00:00:00") {
              responsedata[j]['consent_txt'] = responsedata[j]['consent_txt'] + " on " + this.formatDateMSlash(responsedata[j]['consentDate'].replaceAll('-','/') + " UTC");
            }
          }
          if(responsedata[j].LatestReview && responsedata[j].LatestReview.reviewtime && responsedata[j].LatestReview.reviewtime != "None") {
            responsedata[j].LatestReview.reviewtime = this.formatDateCustom(responsedata[j].LatestReview.reviewtime);
          }
          responsedata[j]['UnAssignProviderList'] = [];
          for(var x=0; x<this.providerlist.length; x++) {
            let counter = 0;
            if(responsedata[j]['AssignProviderList']) {
              for(var y=0; y<responsedata[j]['AssignProviderList'].length; y++) {
                if(this.providerlist[x]['sub'] == responsedata[j]['AssignProviderList'][y]['sub']) {
                  counter++;
                }
              }
            }
            if(counter == 0) {
              responsedata[j]['UnAssignProviderList'].push(this.providerlist[x]);
            }
          }
          // console.log(responsedata[j]['UnAssignProviderList']);
          if(responsedata[j]['register_comment']) {
            console.log(responsedata[j]['register_comment']);
            try{
              responsedata[j]['register_comment'] = JSON.parse(responsedata[j]['register_comment'].replace('\n', '\\n').replace('/', '\/'));
            }
            catch {
              responsedata[j]['register_comment'] = "";
            }
          }
          if(responsedata[j]['gender']) {
            let str = responsedata[j]['gender'];
            // responsedata[j]['gender'] = str.charAt(0).toUpperCase() + str.slice(1);
            responsedata[j]['gender'] = str.charAt(0).toUpperCase();
          }
          if(responsedata[j]['birthdate']) {
            responsedata[j]['birthdate']=this.formatDateMSlash(responsedata[j]['birthdate']+ " 00:00:00");
            responsedata[j]['masked_birthdate']=this.make_number_mask(responsedata[j]['birthdate'].toString(),4);
            let timeDiff = Math.abs(Date.now() - new Date(responsedata[j]['birthdate']).getTime());
            let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
            responsedata[j]['age']=age;
          }
          if(responsedata[j]['dataTransmissionDate'] && responsedata[j]['dataTransmissionDate'] != "0000-00-00 00:00:00") {
            responsedata[j]['transdate'] = this.formatDateMSlash(responsedata[j]['dataTransmissionDate'].replaceAll('-','/') + " UTC");
          }
          else {
            responsedata[j]['transdate'] = "";
          }
          if(responsedata[j].Alerts) {
            for (var i = 0; i < responsedata[j].Alerts.length; i++) {
              if(responsedata[j].Alerts[i]) {
                responsedata[j].Alerts[i]['timestamp'] = this.formatDateM(responsedata[j].Alerts[i]['timestamp']);
                // var settingjsonarr = responsedata[j].Alerts[i]['alert_setting'].trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
                var settingjsonarr = responsedata[j].Alerts[i]['alert_setting'].trim().split(" ");
                var settingjson = "";
                for(var k = 0; k < settingjsonarr.length; k++) {
                  if(settingjsonarr[k].trim() != '') {
                    if(settingjson) {
                      if(settingjsonarr[k].trim() != ":" && settingjsonarr[k].trim().slice(-1) != '"') {
                        settingjson = settingjson + settingjsonarr[k].trim() + ',';
                      }
                      else {
                        settingjson = settingjson + settingjsonarr[k].trim();
                      }
                    }
                    else {
                      settingjson = settingjson + settingjsonarr[k].trim();
                    }
                  }
                }
                if(settingjson.slice(-1) == ",") {
                  settingjson = settingjson.slice(0, -1);
                }
                settingjson = '{' + settingjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
                // console.log(settingjson);
                var detailjsonarr = responsedata[j].Alerts[i]['detailed_value'].trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
                var detailjson = "";
                for(var k = 0; k < detailjsonarr.length; k++) {
                  if(detailjsonarr[k].trim() != '') {
                    if(detailjson) {
                      detailjson = detailjson + ',' + detailjsonarr[k].trim();
                    }
                    else {
                      detailjson = detailjson + detailjsonarr[k].trim();
                    }
                  }
                }
                var detailjson = '{' + detailjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
                if(responsedata[j].Alerts[i]['device_name'].trim() == "BPM" || responsedata[j].Alerts[i]['device_name'].trim() == "Oximeter" || responsedata[j].Alerts[i]['device_name'].trim() == "Weight" || responsedata[j].Alerts[i]['device_name'].trim() == "Blood Glucose" || responsedata[j].Alerts[i]['device_name'].trim() == "Thermometer") {
                  console.log(settingjson);
                  responsedata[j].Alerts[i]['alert_setting'] = JSON.parse(settingjson);
                  responsedata[j].Alerts[i]['detailed_value'] = JSON.parse(detailjson);
                  if(responsedata[j].Alerts[i]['detailed_value']['weight']) {
                    responsedata[j].Alerts[i]['detailed_value']['weight'] = Math.floor(responsedata[j].Alerts[i]['detailed_value']['weight'])+" lbs";
                  }
                  if(responsedata[j].Alerts[i]['detailed_value']['bmi']) {
                    responsedata[j].Alerts[i]['detailed_value']['bmi'] = Math.floor(responsedata[j].Alerts[i]['detailed_value']['bmi']);
                  }
                }
              }
            }
          }
          if(responsedata[j].DeviceEducation) {
            for (var i = 0; i < responsedata[j].DeviceEducation.length; i++) {
              responsedata[j].DeviceEducation[i]['timestamp'] = this.formatDateM(responsedata[j].DeviceEducation[i]['timestamp']);
            }
          }
          if(responsedata[j].TransmissionDate && responsedata[j].TransmissionDate.StartDate) {
            responsedata[j].TransmissionDate.StartDate = this.formatDateMSlash(responsedata[j].TransmissionDate.StartDate);
          }
          if(responsedata[j].TransmissionDate && responsedata[j].TransmissionDate.StartDate && responsedata[j].TransmissionDate.EndDate) {
            responsedata[j].TransmissionDate.EndDate = this.formatDateMSlash(responsedata[j].TransmissionDate.EndDate);
          }
          if(responsedata[j].Review && responsedata[j].Review.Current_Month) {
            this.currentDuration = 0;
            for (var i = 0; i < responsedata[j].Review.Current_Month.length; i++) {
              this.currentDuration = this.currentDuration + Number(responsedata[j].Review.Current_Month[i].duration);
              responsedata[j].Review.Current_Month[i].timestamp = this.formatDateCustom(responsedata[j].Review.Current_Month[i].timestamp);
            }
            responsedata[j].Review.Current_Month_Duration = this.currentDuration;
          }
          if(responsedata[j].Review && responsedata[j].Review.Previous_Month) {
            this.previousDuration = 0;
            for (var i = 0; i < responsedata[j].Review.Previous_Month.length; i++) {
              this.previousDuration = this.previousDuration + Number(responsedata[j].Review.Previous_Month[i].duration);
              responsedata[j].Review.Previous_Month[i].timestamp = this.formatDateCustom(responsedata[j].Review.Previous_Month[i].timestamp);
            }
            responsedata[j].Review.Previous_Month_Duration = this.previousDuration;
          }
          responsedata[j]['sub_btoa'] = btoa(responsedata[j]['sub']);
          responsedata[j]['profile_url'] = document.location.origin+'/profile/'+encodeURIComponent(btoa(localStorage.getItem('userpull_id')))+'/'+encodeURIComponent(btoa(responsedata[j]['sub']));
          responsedata[j]['userdevices'] = responsedata[j]['getuserDevices'].join(", ");
          if(responsedata[j].hasIoT && responsedata[j].hasIoT==true){
            this.iotPracticeSubs.push(responsedata[j]);
          }
          this.users.push(responsedata[j]);
        }
      }
      this.paging++;
      // console.log(this.iotPracticeSubs);
      // users = responsedata;
      this.patientList = this.users;
      // if(paging == allpage || responsedata.length == 0) {
      // if(this.paging == 1) {
      console.log(loadtype);
      console.log(this.paging);
      console.log(this.allpage);
      if(loadtype != 'allpage' || (loadtype == 'allpage' && this.paging == this.allpage)) {
        this.screen.style.display = 'none';
        // PatientClass.dtTrigger.next();

        this.getAllPayerList();
        this.practice = response.practice;
        this.patientFormCountry = "United States";
        sessionStorage.setItem('DataTables_User_Status', this.step);
      }
        if(this.loadListing) {
          console.log("load");
          // if(this.dtElement && this.dtElement.dtInstance) {
          //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          //     dtInstance.destroy();
          //     this.dtTrigger.next();
          //   });
          // }
          // else {
          //   this.dtTrigger.next();
          // }

          
          this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "patient-table") {
                dtInstance.destroy();
                this.dtTrigger.next();
              }
            });
            }
            else {
              this.dtTrigger.next();
            }
          });
        }
        if (responsedata.length == 0) {
          console.log("no data");
          // break;
        }
      // }
      // if(this.viewmodalid) {
      //   let detailsPatientModal: HTMLElement = document.getElementById(this.viewmodalid) as HTMLElement;
      //   detailsPatientModal.click();
      //   this.viewmodalid = '';
      // }
      // console.log(allpage);
      // console.log(paging);
    }
  }

  stepstep = "";

  partLoadingAll = async() =>{
    do{
      this.screen.style.display = 'block';
      await this.partLoading('allpage');
    }
    while (this.paging != this.allpage);
  }

  getPatientList = async (PatientClass, user_status='') =>{
    console.log(localStorage);
    this.selectedPatientStatus = false;
    this.selectedConsentStatus = false;
    PatientClass.screen.style.display = 'block';

    console.log(sessionStorage.getItem('userclass'));

    if(user_status != '') {
      sessionStorage.removeItem('DataTables_patient-table');
    }
    if(user_status == '' && sessionStorage.getItem('DataTables_User_Status') != null) {
      user_status = sessionStorage.getItem('DataTables_User_Status');
    }
    else if(user_status == '') {
      user_status = 'Active';
      sessionStorage.removeItem('DataTables_patient-table');
    }
    this.step = user_status;

    this.stepstep = this.step.substring(0,3);

    if(sessionStorage.getItem('DataTables_Provider') == null) {
      this.selectedprovider = 'all';
    }
    else {
      this.selectedprovider = sessionStorage.getItem('DataTables_Provider');
      if(this.providerlist && this.providerlist.length > 0){
        var found = 0;
        for(var pl=0; pl <this.providerlist.length; pl++){
          if(this.providerlist[pl]['sub'] == this.selectedprovider) {
            found = 1;
          }
        }
        if(found == 0){
          this.selectedprovider = 'all';
        }
      }
    }

    this.languageArr = {};
    if(localStorage.getItem('LanguageList')) {
      let langList = JSON.parse(localStorage.getItem('LanguageList'));
      for(var j=0; j<langList.length; j++) {
        var langval = langList[j]['language_value'];
        if(langval.indexOf("(") > 0) {
          this.languageArr[langList[j]['language_code']] = langval.substring(0, langval.indexOf("(")-1);
        }
      }
    }
    console.log(this.languageArr);
    do{
      this.paging = 0;
      this.allpage = undefined;
      this.users = [];
      // this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      //   if(dtElement.dtInstance) {
      //   dtElement.dtInstance.then((dtInstance: any) => {
      //     if(dtInstance.table().node().id == "patient-table") {
      //       dtInstance.destroy();
      //       this.dtTrigger.next();
      //     }
      //   });
      //   }
      //   else {
      //     this.dtTrigger.next();
      //   }
      // });
      this.partLoading();
      this.loadListing = true;
      // });
      // },
      // async (error: any) => {
      //   Auth.signOut();
      //   localStorage.removeItem('AUTH');
      //   localStorage.removeItem('AUTH-LIFE');
      //   localStorage.removeItem('AUTH-PROFILE-PIC');
      //   localStorage.removeItem('USER-DETAILS');
      //   //PatientClass.processing.style.display = 'none';
      //   PatientClass.screen.style.display = 'none';
      //   PatientClass.router.navigateByUrl('/sign-in');
      // });
      // console.log(allpage);
      // console.log(paging);
    } 
    // while (paging != allpage);
    while (this.paging == 1);
  }

  syncIOTTransmission = (subDetails = 'all') => {
    // if(this.step == "Active") {
    if(this.stepActiveArr.indexOf(this.step) > -1) {
      var confirmMsg = "Do you want to sync data transmission of all active IOT users?"
      if(subDetails != 'all') {
        confirmMsg = "Do you want to sync data transmission of this user?"
      }
      if(confirm(confirmMsg)) {
        var patientListNow = this.iotPracticeSubs;
        if(subDetails != 'all') {
          patientListNow = [subDetails];
        }
        console.log(patientListNow);
        var patientlength = patientListNow.length;
        let syncDetailsModal = document.getElementById("sync-details-ankerTag") as HTMLElement;
        syncDetailsModal.click();
        this.htmlToAdd = "<div><b>Syncing process started...</b></div>";
        this.htmlToAdd += "<div><b>Please wait for the 'Close' button at the end...</b></div>";
        this.syncStatus = "Running";
        var counter = 0;
        var successcounter = 0;
        var errorcounter = 0;
        for(var k = 0; k < patientListNow.length; k++) {
          var patientDetail = patientListNow[k];
          let params = {
            userpull_id: localStorage.getItem('userpull_id'),
            sub: patientDetail['sub'],
            userauth: localStorage.getItem('AUTH-TOKEN')
          };
          this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/iotsync', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
            .subscribe((response: any) => {
              counter++;
              if(response.status == "success") {
                successcounter++;
                // this.htmlToAdd += '<div class="success"><b>'+counter+' out of '+patientlength+'.</b> Transmission syncing successful for '+response.subname+'</div>';
              }
              else {
                errorcounter++;
                // this.htmlToAdd += '<div class="error"><b>'+counter+' out of '+patientlength+'.</b> Could not sync transmission for '+response.subname+'</div>';
              }
              this.htmlToAdd += '<div><b>Success:</b> '+successcounter+', <b>Error:</b> '+errorcounter;
              if(counter == patientlength) {
                this.htmlToAdd += '<div><b>Syncing process completed...</b></div>';
                this.syncStatus = "Complete";
              }
          });
        }
      }
    }
    else {
      alert("Don't try anything fishy.");
    }
  }

  syncTransmission = async (subDetails = 'all') => {
    // if(this.step == "Active") {
    if(this.stepActiveArr.indexOf(this.step) > -1) {
      var confirmMsg = "Do you want to sync data transmission of all active users?"
      if(subDetails != 'all') {
        confirmMsg = "Do you want to sync data transmission of this user?"
      }
      if(confirm(confirmMsg)) {
        var patientListNow = this.patientList;
        if(subDetails != 'all') {
          patientListNow = [subDetails];
        }
        console.log(patientListNow);
        var patientlength = patientListNow.length;
        let syncDetailsModal = document.getElementById("sync-details-ankerTag") as HTMLElement;
        syncDetailsModal.click();
        this.htmlToAdd = "<div><b>Syncing process started...</b></div>";
        this.htmlToAdd += "<div><b>Please wait for the 'Close' button at the end...</b></div>";
        this.syncStatus = "Running";
        var counter = 0;
        for(var k = 0; k < patientListNow.length; k++) {
          let patientDetail = patientListNow[k];
          await this.delay(200);
          setTimeout (() => {
            let params = {
              userpull_id: localStorage.getItem('userpull_id'),
              sub: patientDetail['sub'],
              subname: patientDetail['firstName']+" "+patientDetail['lastName'],
              userauth: localStorage.getItem('AUTH-TOKEN')
            };
            this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/synctransmission', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
              .subscribe((response: any) => {
                counter++;
                if(response.status == "success") {
                  this.htmlToAdd += '<div class="success"><b>'+counter+' out of '+patientlength+'.</b> Transmission syncing successful for '+response.subname+'</div>';
                }
                else {
                  console.log(response);
                  this.htmlToAdd += '<div class="error"><b>'+counter+' out of '+patientlength+'.</b> Could not sync transmission for '+response.subname+'</div>';
                }
                if(counter == patientlength) {
                  this.htmlToAdd += '<div><b>Syncing process completed...</b></div>';
                  this.syncStatus = "Complete";
                }
            });
          }, 2000);
        }
      }
    }
    else {
      alert("Don't try anything fishy.");
    }
  }

  patientDetails = (data = "") => {
    this.userDetails = data;
    this.userDetails.currentReviews = this.userDetails.Review.Current_Month; 
    this.userDetails.pastMonthReviews = this.userDetails.Review.Previous_Month; 
  	let patientDetailsModal = document.getElementById("patient-details-ankerTag") as HTMLElement;
  	patientDetailsModal.click();
    this.formInvalids = false;
    this.formInvalidMsgs = false;
  }

  unassignProvider = (data, provider) => {
    console.log(data);
    console.log(provider);
    let unassignClass = this;
    unassignClass.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      sub: data.sub,
      provider: provider
    };
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/unenrollprovider', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        unassignClass.screen.style.display = 'none';
        if(response.status == "success") {
          alert("Provider unenrolled successfully");
          location.reload();
        }
        else {
          alert("Could not unenroll provider");
        }
      },
      async (error: any) => {
        unassignClass.screen.style.display = 'none';
        console.log(error);
      });
  }

  validateAssignPatientForm = ( assignData ) =>{
    console.log(assignData);
    if(!assignData.provider) {
      alert("Please choose Provider");
      return false;
    }
    if(assignData.provider.length == 0) {
      alert("Please choose atleast one Provider");
      return false;
    }
    let providerlist = "";
    for(let i=0; i<assignData.provider.length; i++){
      if(providerlist) {
        providerlist = providerlist + "," + assignData.provider[i];
      }
      else {
        providerlist = providerlist + assignData.provider[i];
      }
    }
    let assignClass = this;
    assignClass.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      sub: assignData.sub,
      provider: providerlist
    };
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/enrollprovider', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        assignClass.screen.style.display = 'none';
        if(response.status == "success") {
          alert("Provider assigned successfully");
          location.reload();
        }
        else {
          alert("Could not assign provider");
        }
      },
      async (error: any) => {
        assignClass.screen.style.display = 'none';
        console.log(error);
      });
  }

  validateMRNForm = ( mrnData ) =>{
    let MrnClass = this;
    //if(mrnData.mrn) {
      MrnClass.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        group: "Patient",
        sub: (mrnData.sub)?mrnData.sub:'',
        "custom:mrn1": mrnData.mrn
      };
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          console.log(response);
          MrnClass.screen.style.display = 'none';
          if(response.status == "success") {
            alert("MRN2 updated");
          }
          else {
            alert("MRN2 could not be updated");
          }
        },
        async (error: any) => {
          MrnClass.screen.style.display = 'none';
          console.log(error);
        });
    // }
    // else {
    //   alert("Please provide MRN");
    // }
  }

  validatePasswordForm = ( passwordData ) =>{
    let PasswordClass = this;
    PasswordClass.formInvalids = false;
    PasswordClass.formInvalidMsgs = false;
    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(passwordData.new_password)) {
      if(passwordData.new_password == passwordData.confirm_password) {
        console.log(passwordData);
        PasswordClass.screen.style.display = 'block';
    
        // let params = new HttpParams({
        //   fromObject: {
        //     userpull_id: localStorage.getItem('userpull_id'),
        //     sub: passwordData.sub,
        //     password: passwordData.new_password
        //   }
        // });
        let params = {
          userpull_id: localStorage.getItem('userpull_id'),
          sub: passwordData.sub,
          password: passwordData.new_password
        };
        this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/changepassword', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          console.log(response);
          PasswordClass.screen.style.display = 'none';
          if(response.status == "success") {
            alert("Password changed successfully");
          }
          else {
            alert("Password could not be changed");
          }
        },
        async (error: any) => {
          Auth.signOut();
                  localStorage.removeItem('AUTH');
                  localStorage.removeItem('AUTH-LIFE');
                  localStorage.removeItem('AUTH-PROFILE-PIC');
                  localStorage.removeItem('USER-DETAILS');
                  //PatientClass.processing.style.display = 'none';
                  PasswordClass.screen.style.display = 'none';
                  PasswordClass.router.navigateByUrl('/sign-in');
        });
      }
      else {
        console.log('Error');
        PasswordClass.formInvalids = true;
        PasswordClass.formInvalidMsgs = false;
      }
    }
    else {
      console.log('Error');
      PasswordClass.formInvalidMsgs = true;
      PasswordClass.formInvalids = false;
    }
  }

  validateAddReviewForm = ( addreviewData ) =>{
    console.log(addreviewData);
    if(addreviewData.patient_sub == undefined || addreviewData.patient_sub.trim() == "") {
      alert("Some error occurred. Please refresh the page and try again.");
      return false;
    }
    if(addreviewData.provider_sub == undefined || addreviewData.provider_sub.trim() == "") {
      alert("Please choose Provider.");
      return false;
    }
    if(addreviewData.mode == undefined || addreviewData.mode.trim() == "") {
      alert("Please choose mode.");
      return false;
    }
    /*if(addreviewData.duration == undefined || addreviewData.duration == "") {
      alert("Please provide duration.");
      return false;
    }
    if(addreviewData.duration < 1) {
      alert("Duration must be greater or equal to 1 minute.");
      return false;
    }*/
    if(addreviewData.review == undefined || addreviewData.review.trim() == "") {
      alert("Please provide review text.");
      return false;
    }
    var reviewDataArr = addreviewData.reviewtime.split("-");
    if(reviewDataArr.length != 3) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[0].length != 4) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[0] < 1900 || reviewDataArr[0] > (new Date()).getFullYear()) {
      alert("Please provide Valid Time, not more than current year");
    }
    else if(reviewDataArr[1] > 12) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[0] == (new Date()).getFullYear() && reviewDataArr[1] > new Date().getMonth() + 1){
      alert("Please provide Valid Time, not more than current month and year");
    }
    else if(reviewDataArr[2].split("T")[0] > 31) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[2].split("T")[1] && reviewDataArr[2].split("T")[1].split(":")[0] && reviewDataArr[2].split("T")[1].split(":")[0] > 23) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else if(reviewDataArr[2].split("T")[1] && reviewDataArr[2].split("T")[1].split(":")[1] && reviewDataArr[2].split("T")[1].split(":")[1] > 59) {
      alert("Please provide Valid Time in YYYY-MM-DDTHH:MM format");
    }
    else {
      let addreviewClass = this;
      addreviewClass.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        user_sub: localStorage.getItem('user_sub'),
        user_group: this.userGroup,
        patient_sub: addreviewData.patient_sub,
        provider_sub: addreviewData.provider_sub,
        mode: addreviewData.mode,
        duration: (addreviewData.duration)?addreviewData.duration:'',
        // month: addreviewData.forreviewmonth.split("-")[1],
        // year: addreviewData.forreviewmonth.split("-")[0],
        timestamp: this.formatDBDateTime(addreviewData.reviewtime),
        review: addreviewData.review
      };
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addrpmreview', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          console.log(response);
          addreviewClass.screen.style.display = 'none';
          if(response.status == "success") {
            alert("Review added successfully");
            let addReviewFormCollapse = document.getElementById("add_review_form_button") as HTMLElement;
            addReviewFormCollapse.click();
            this.loadListing = true;
            let detailsPatientModalClose: HTMLElement = document.getElementById('patient-details') as HTMLElement;
            detailsPatientModalClose.click();
            this.viewmodalid = 'view_'+addreviewData.patient_sub;
            console.log(this.viewmodalid);
            this.getPatientList(this);
          }
          else {
            alert("Could not add review");
          }
        },
        async (error: any) => {
          addreviewClass.screen.style.display = 'none';
          console.log(error);
        });
    }
  }

  validateReviewForm = ( reviewData ) =>{
    console.log(reviewData);
    // return false;
    let reviewClass = this;
    reviewClass.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      user_sub: localStorage.getItem('user_sub'),
      user_group: this.userGroup,
      rpmreview_id: reviewData.rpmreview_id,
      review: reviewData.review
    };
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addreview ', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        reviewClass.screen.style.display = 'none';
        if(response.status == "success") {
          alert("Review added successfully");
        }
        else {
          alert("Could not add review");
        }
      },
      async (error: any) => {
        reviewClass.screen.style.display = 'none';
        console.log(error);
      });
  }

  validatedeviceEduForm = ( deviceEduData ) =>{
    console.log(deviceEduData);
    // if(deviceEduData.device_education_id == undefined || deviceEduData.device_education_id.trim() == "") {
    //   alert("Some error occurred. Please refresh the page and try again.");
    //   return false;
    // }
    // if(deviceEduData.primary_diagnosis == undefined || deviceEduData.primary_diagnosis.trim() == "") {
    //   alert("Please Provide Primary Diagnosis.");
    //   return false;
    // }
    // if(deviceEduData.secondary_diagnosis == undefined || deviceEduData.secondary_diagnosis.trim() == "") {
    //   alert("Please Provide Primary Diagnosis.");
    //   return false;
    // }
    let deviceEduClass = this;
    deviceEduClass.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        device_education_id: deviceEduData.device_education_id,
        primary_diagnosis: deviceEduData.primary_diagnosis,
        secondary_diagnosis: deviceEduData.secondary_diagnosis
      };
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/adddiagnosis', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          console.log(response);
          deviceEduClass.screen.style.display = 'none';
          if(response.status == "success") {
            alert("Updated successfully");
          }
          else {
            alert("Could not update");
          }
        },
        async (error: any) => {
          deviceEduClass.screen.style.display = 'none';
          console.log(error);
        });
  }

  formatDateM = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('-');
  }

  formatDateMSlash = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('/');
  }

  formatTimeM = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes();
    var time = "AM";
    if(hour >= 12) {
      time = "PM";
      if(hour > 12) {
        hour = hour - 12;
      }
    }
    var strhour = ''+ hour;
    if(hour == 0) {
      strhour = '12';
    }
    else if(hour < 10){
      strhour = '0' + hour;
    }
    var strminute = '' + minute;
    if(minute < 10){
      strminute = '0' + minute;
    }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('/')+' '+strhour+":"+strminute+" "+time;
  }

  formatDateCustom = (date) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const days = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat'
    ]
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes();
    var time = "AM";
    if(hour >= 12) {
      time = "PM";
      if(hour > 12) {
        hour = hour - 12;
      }
    }
    var strhour = ''+ hour;
    if(hour == 0) {
      strhour = '12';
    }
    else if(hour < 10){
      strhour = '0' + hour;
    }
    var strminute = '' + minute;
    if(minute < 10){
      strminute = '0' + minute;
    }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    // return [year, month, day].join('-');
    return strhour+":"+strminute+" "+time+" "+days[d.getDay()]+" "+day+" "+months[d.getMonth()]+" "+year;
  }

  validatePatientForm = ( patientData ) =>{
    
    patientData['custom-country'] = "United States";
    // return false;

    let PatientClass = this;
    let todaydate = new Date();

    let assign_sub = JSON.parse(localStorage.getItem('USER-DETAILS'));
    let sub = assign_sub['sub'];
    sub = sub.replace(/-/g, '_');

    PatientClass.processing.style.display = 'block';
    let createPatientModalClose: HTMLElement = document.getElementById('create-Patient') as HTMLElement;

    /////// Validition Process Start Here ////////

    if(!patientData.assign || typeof patientData.assign === 'undefined' || patientData.assign==''){
      this.assignInvalid     = true;
      this.assignError       = 'Please choose Provider';
      PatientClass.processing.style.display = 'none';
      return false;
    }else{
      this.assignInvalid     = false;
      this.assignError       = '';
    }

    let nameAlphabets = /^[A-Za-z]+$/;
    let nameAlphaNumeric = /^[A-Za-z0-9 ]+$/;
    if(!patientData.given_name || typeof patientData.given_name === 'undefined' || !patientData.given_name.match(nameAlphaNumeric)){
      this.givenNameInvalid = true;
      this.givenNameError   = 'Invalid first name.';
      PatientClass.processing.style.display = 'none';
      return false;
    }else{
       this.givenNameInvalid = false;
       this.givenNameError   = '';
    }

    /*if(!typeof patientData.middle_name || typeof patientData.middle_name === 'undefined' || !patientData.middle_name.match(nameAlphabets)){
      checkValid = false;
      this.middleNameInvalid = true;
      this.middleNameError   = 'Invalid middle name.';
      PatientClass.processing.style.display = 'none';
      return false;
    }else{
       checkValid = true;
       this.middleNameInvalid = false;
       this.middleNameError   = '';
    }*/

    if(!patientData.family_name || typeof patientData.family_name === 'undefined' || !patientData.family_name.match(nameAlphaNumeric)){
      this.familyNameInvalid = true;
      this.familyNameError   = 'Invalid last name.';
      PatientClass.processing.style.display = 'none';
      return false;
    }else{
     this.familyNameInvalid = false;
     this.familyNameError   = '';
    }

    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if(!this.autoEmail && (!patientData.email || typeof patientData.email === 'undefined' || !patientData.email.match(emailPattern))){
      this.emailInvalid = true;
      this.emailError   = 'Invalid email.';
      PatientClass.processing.style.display = 'none';
      return false;
    }else{
      this.emailInvalid = false;
      this.emailError   = '';
    }

    let phone_numberPattern = /^[+]\d+$/;                ///// Checking for +Number /////
    if(!patientData.phone_number || typeof patientData.phone_number === 'undefined' || !patientData.phone_number.match(phone_numberPattern)){
      this.phoneNumberInvalid = true;
      this.phoneNumberError   = 'Invalid Phone number.';
      PatientClass.processing.style.display = 'none';
      return false;
    }else{
      this.phoneNumberInvalid = false;
      this.phoneNumberError   = '';
    }

    if(patientData['custom-phoneNo2'] && patientData['custom-phoneNo2'] != '' && !patientData['custom-phoneNo2'].match(phone_numberPattern) && !patientData['custom-phoneNo2'].match(emailPattern)){
      this.facetimeInvalid = true;
      this.facetimeError   = 'Invalid FaceTime Phone number.';
      PatientClass.processing.style.display = 'none';
      return false;
    }else{
      this.facetimeInvalid = false;
      this.facetimeError   = '';
    }

    if( !patientData.birthdate || typeof patientData.birthdate === 'undefined' || patientData.birthdate==''){
      this.birthdateInvalid   = true;
      this.birthdateError     = 'Choose Birthdate.'; 
      PatientClass.processing.style.display = 'none';
      return false;
    }else{
      this.birthdateInvalid   = false;
      this.birthdateError    = ''; 
    }

    if(!patientData.gender || typeof patientData.gender === 'undefined'){
      this.genderInvalid     = true;
      this.genderError       = 'Select Gender.';
      PatientClass.processing.style.display = 'none';
      return false;
    }else{
      this.genderInvalid      = false;
      this.genderError        = '';
    }

    if(!patientData['custom-height-feet'] || typeof patientData['custom-height-feet'] === 'undefined' || patientData['custom-height-feet']=="" || patientData['custom-height-feet']<1){
      this.heightInvalid     = true;
      this.heightError       = 'Invalid Height.';
      PatientClass.processing.style.display = 'none';
      return false;
    }else{
      this.heightInvalid     = false;
      this.heightError       = '';
    }
    if(typeof patientData['custom-height-inches'] === 'undefined' || Number(patientData['custom-height-inches'])<0){
      this.heightInvalid     = true;
      this.heightError       = 'Invalid Height.';
      PatientClass.processing.style.display = 'none';
      return false;
    }else{
      this.heightInvalid     = false;
      this.heightError       = '';
    }

    if(!patientData.address || typeof patientData.address === 'undefined' || patientData.address==''){
      this.addressInvalid     = true;
      this.addressError       = 'Invalid Address.';
      PatientClass.processing.style.display = 'none';
      return false;
    }else{
      this.addressInvalid     = false;
      this.addressError       = '';
    }

    // if(patientData['custom-country']){
    //   this.countryInvalid     = false;
    //   this.countryError       = '';
    // }else{
    //      this.countryInvalid     = true;
    //      this.countryError       = 'Select Country.';
    //      PatientClass.processing.style.display = 'none';
    //   	 return false;
    // }

    /*if(!checkValid)
    {
      PatientClass.processing.style.display = 'none';
      return false;
    }*/

    let userData = [];
    let custom_height = 0;
    for(let k in patientData){
      let Key = k.replace("-", ":");
      let key_value = (patientData[k])?patientData[k].toString():'';
      // if(Key == 'phone_number'){
      //   let phone_number = this.updatePhoneNumber(patientData['custom-country'],patientData['phone_number']);
      //   userData.push({Name:Key,Value:phone_number});
      // }
      // else 
      if(Key == 'assign'){
        let assignsub = "";
        for (var i = 0; i < patientData['assign'].length; i++) {
          if(i == 0) {
            assignsub = patientData['assign'][i].replace(/-/g, '_');
          }
          else {
            assignsub = assignsub +','+ patientData['assign'][i].replace(/-/g, '_');
          }
        }
        userData.push({Name:"custom:assign",Value:assignsub});
      }
      else if(Key == "birthdate"){
        let date_value = patientData['birthdate'];
        let con_date = date_value.split("-");
        //let Date_format = con_date[2]+'-'+con_date[1]+'-'+con_date[0];
        let Date_format = con_date[1]+'-'+con_date[2]+'-'+con_date[0];
        userData.push({Name:Key,Value:Date_format});
      }	
      else if(Key == "custom:height-feet" || Key == "custom:height-inches") {
        if(Key == "custom:height-feet") {
          custom_height = custom_height + (Number(key_value)*12);
        }
        else {
          custom_height = custom_height + (Number(key_value));
        }
      }
      else{
        userData.push({Name:Key,Value:key_value});
      }
    }

    userData.push({Name:"custom:height",Value: custom_height.toString()},{Name:"custom:group",Value: 'Patient'},{Name:"email_verified",Value: 'TRUE'},{Name:"phone_number_verified",Value: 'TRUE'},{Name:"custom:isOnline",Value: '1'},{Name:"custom:enrollDate",Value: this.formatDateCustom(todaydate)});
    // let randomPassword = this.generatePassword();
    let randomPassword = "Test@123";
    console.log(userData);
    // return false;
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      group: "Patient",
    };
    for(var k = 0; k < userData.length; k++) {
      params[userData[k]['Name']] = userData[k]['Value'];
    }
    console.log(params);
    // return false;
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/createuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      if(response.status=="success") {
        PatientClass.formInvalid = false;
        PatientClass.formInvalidMsg = false;
        PatientClass.formInvalidMsgTxt = '';
        createPatientModalClose.click();
        sessionStorage.setItem('flashSuccess', 'true');
        // PatientClass.processing.style.display = 'none';
        // location.reload();
        if(response.data!="") {
          let responsedata = JSON.parse(response.data);
          if(responsedata.usersub) {
            sessionStorage.setItem('cpasssub', responsedata.usersub);
          }
          let params = new HttpParams({
            fromObject: {
              userpull_id: localStorage.getItem('userpull_id'),
              patient_sub: responsedata.usersub
            }
          });
          this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/rdssync', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
            location.reload();
          });
        }
        else {
          location.reload();
        }
      }
      else if(response.message != "") {
        PatientClass.formInvalidMsg = true;
        PatientClass.formInvalidMsgTxt = response.message;
        PatientClass.processing.style.display = 'none';
      }
      else {
        PatientClass.formInvalid = true;
        PatientClass.processing.style.display = 'none';
      }
    },
    async (error: any) => {
      console.log(error);
      PatientClass.formInvalid = true;
      PatientClass.processing.style.display = 'none';
    });
    // return false;
    ////////// Create User in Cognito ///////////////////

  }

  resetReviewForm = (form: NgForm) =>{
    // let patientSub = form.form.value.patient_sub;
    // form.resetForm();
    form.controls['provider_sub'].reset();
    form.controls['mode'].reset();
    form.controls['duration'].reset();
    form.controls['review'].reset();
    // form.form.value.patient_sub = patientSub;
    // console.log(form);
  }

  resetPatientForm = (form: NgForm) =>{

    this.formInvalid = false;
    this.formInvalidMsg = false;
    this.formInvalidMsgTxt = '';

    this.givenNameInvalid = false;
    this.givenNameError   = '';

    this.middleNameInvalid =  false;
    this.middleNameError   = '';

    this.familyNameInvalid = false;
    this.familyNameError   = '';

    this.emailInvalid      = false;
    this.emailError        = '';

    this.phoneNumberInvalid = false;
    this.phoneNumberError   = ''; 
    
    this.birthdateInvalid   = false;
    this.birthdateError     = ''; 

    this.addressInvalid     = false;
    this.addressError       = '';

    this.genderInvalid      = false;
    this.genderError        = '';

    this.heightInvalid      = false;
    this.heightError        = '';

    this.countryInvalid     = false;
    this.countryError       = '';

    this.assignInvalid = false;
    this.assignError   = '';

    form.resetForm();
  }

  generatePassword = (length=8) =>{
    let chars = "abcdefghijklmnop!@#$%^&*<>ABCDEFGHIJKLMNOP1234567890";
    let pass = "";
    for (let x = 0; x < 8; x++) {
        let i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
    }
    if(pass.match("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")){
      return pass;
    }
    else{
     this.generatePassword();
    }
  }

  removeSession = () =>{
    if(sessionStorage.getItem('flashSuccess')){
      sessionStorage.removeItem('flashSuccess');
    }
  }

  updatePhoneNumber = ( country_val,phone_number ) => {
    let country_dial_code = "";
    let country_code = "";
    if(country_val)
    {
      for(let key in this.country_list_code){
        if(this.country_list_code[key]['name'] == country_val){
          country_dial_code = this.country_list_code[key]['dial_code'];
          country_code = this.country_list_code[key]['code'];
          break;
        }
      }
      return country_dial_code+phone_number;
    }
    else{
      return phone_number;
    }
  }

  patientInventory = (patient) => {
    console.log("patientInventory")
    console.log(patient)

    this.inventoryItemList = [];
    this.inventoryItemOptionList = [];

    this.inventoryType = "";
    this.inventoryItem = 0;
    this.inventorySerial = "";
    this.inventoryNewSerial = "";
    this.inventoryItemOption = "";

    this.inventoryTypeError = false;
    this.inventoryItemError = false;
    this.inventoryOptionError = false;
    this.inventorySerialError = false;
    this.inventoryNewSerialError = false;
    this.inventoryisSerial = false;
    this.inventoryisQuantity = false;
    this.inventoryQuantityError = false;

    this.patientItemList = [];

    this.addInventoryPatient = patient

    let getInventoryClass = this;
    getInventoryClass.screen.style.display = 'block';

    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        patient_sub: patient.sub
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpatientstock', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response)
      getInventoryClass.screen.style.display = 'none';
      if (response.status === "success") { 
        this.patientItemList = JSON.parse(response.data);

        let patientSmsModal = document.getElementById("patient-add-inventory-ankerTag") as HTMLElement;
        patientSmsModal.click();   
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });

  }

  addInventoryTypeChange = (typeTemp) => {
    this.inventoryTypeError = false;
    this.inventoryType = typeTemp;

    this.inventoryItemOptionList = [];

    this.inventoryItem = 0;
    this.inventorySerial = "";
    this.inventoryNewSerial = "";
    this.inventoryItemOption = "";

    this.inventoryItemError = false;
    this.inventoryOptionError = false;
    this.inventorySerialError = false;
    this.inventoryNewSerialError = false;
    this.inventoryisSerial = false;
    this.inventoryisQuantity = false;
    this.inventoryQuantityError = false;

    this.loadInventoryItem();
  }

  loadInventoryItem = () => {
    this.inventoryItemList = [];
    
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
      }
    });
    
    let getInventoryClass = this;
    getInventoryClass.screen.style.display = 'block';
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getinventoryitem', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response)
      getInventoryClass.screen.style.display = 'none';
      if (response.status === "success") {  
        let tempItems = JSON.parse(response.data);
        
        for (var tempItem of tempItems){
          var isPresent = this.patientItemList.some(function(el){ return el.id === tempItem.id});
          if (this.inventoryType != 'Issue'){
            if (isPresent){
              this.inventoryItemList.push(tempItem)
            }
          }else if(this.inventoryType == 'Issue'){
            if (isPresent == false){
              this.inventoryItemList.push(tempItem)
            }else if (tempItem.quantity === 1){
              this.inventoryItemList.push(tempItem)
            }
          }
        }

        console.log(this.inventoryItemList)
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }  

  addInventoryItemChange = (item) => {
    console.log(item)

    if (item >= 0) {
      this.inventoryItemError = false;
      this.inventoryItem = item;
    }else{
      this.inventoryItemError = true;
    }


    for(var temp of this.inventoryItemList){
      if (temp.id == item){
        if (temp.serial === 1){
          this.inventoryisSerial = true;
          if(this.inventoryType != 'Issue'){            
            for(var temppatientItem of this.patientItemList){
              if (temppatientItem.id == item){
                this.inventorySerial = temppatientItem.serial;
              }
            }
          }
        }else{
          this.inventoryisSerial = false;
        }
        if (temp.quantity === 1){
          this.inventoryisQuantity = true;
          if(this.inventoryType != 'Issue'){            
            for(var temppatientItem of this.patientItemList){
              if (temppatientItem.id == item){
                // this.InventoryQuantity = temppatientItem.quantity;
                this.InventoryMaxQuantity = temppatientItem.quantity;
              }
            }
          }
        }else{
          this.inventoryisQuantity = false;
        }
      }
    }

    this.loadInventoryOption();
    
  }

  loadInventoryOption = () => {
    this.inventoryItemOptionList = [];
    if (this.inventoryType !== "Issue" &&  this.inventoryItem > 0) {

      let getInventoryClass = this;
      getInventoryClass.screen.style.display = 'block';    

      let params = new HttpParams({
        fromObject: {
          id: this.inventoryItem.toString(),
          type: this.inventoryType
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getinventoryitemoption', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response)
        getInventoryClass.screen.style.display = 'none';
        if (response.status === "success") {
          this.inventoryItemOptionList = JSON.parse(response.data);
          // this.inventoryItemOptionList.unshift({ id: 0, name: "Select Option" })
        }
      },
      async (error: any) => {
        await Auth.signOut({
          global: true
        });
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH-LIFE');
        localStorage.removeItem('AUTH-PROFILE-PIC');
        localStorage.removeItem('USER-DETAILS');
        this.screen.style.display = 'none';
        this.router.navigateByUrl('/sign-in');
      });    
    }
  }

  addInventoryQuantityChange = (quantitytemp) => {
    console.log("addInventoryQuantityChange")
    console.log(quantitytemp)
    if (quantitytemp > 0){
      this.inventoryQuantityError = false;
      if (quantitytemp > this.InventoryMaxQuantity){
        this.inventoryQuantityError = true;
        this.inventoryQuantityErrorStr = "Quantity is less than equal to Issued Quantity.";
      }else{
        this.inventoryQuantityError = false;
      }
    }else{
      this.inventoryQuantityError = true;
      this.inventoryQuantityErrorStr = "Quantity is required.";
    }
  }

  addInventoryOptionChange = (optionTemp) => {
    console.log("addInventoryOptionChange")
    console.log(optionTemp)
    if (optionTemp > 0){
      this.inventoryOptionError = false;
    }else{
      this.inventoryOptionError = true;
    }
  }

  addInventorySerialChange = (serialtemp) => {
    console.log("addInventorySerialChange")
    this.inventorySerial = serialtemp;
    if (this.inventoryType === "Issue") {
      if (serialtemp !== ''){
        this.inventorySerialError = false;
      }else{
        this.inventorySerialError = true;
      }
    }
  }

  addInventoryNewSerialChange = (serialtemp) => {
    console.log("addInventoryNewSerialChange")
    this.inventoryNewSerial = serialtemp;
    if (serialtemp !== ''){
      this.inventoryNewSerialError = false;
    }else{
      this.inventoryNewSerialError = true;
    }
  }

  patientAddInventorySubmitButtonClick = async (addInventoryForm) => {
    console.log("patientAddInventorySubmitButtonClicks")
    console.log(addInventoryForm)
    console.log(this.addInventoryPatient)

    let usersub = ""

    if (localStorage.getItem('USER-DETAILS')) {
  		let userDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));

      if(userDetails.sub){
  			usersub = userDetails.sub;
      }
    }

    if (addInventoryForm.inventoryType !== '' && addInventoryForm.inventoryType !== undefined){
      this.inventoryTypeError = false;
    }else{
      this.inventoryTypeError = true;
    }
    

    if (addInventoryForm.inventoryItem > 0) {
      this.inventoryItemError = false;
    }else{
      this.inventoryItemError = true;
    }

    if (this.inventoryisSerial){
      if (this.inventoryType === "Issue") {
        if (addInventoryForm.inventorySerial !== '' && addInventoryForm.inventorySerial !== undefined){
          this.inventorySerialError = false;
        }else{
          this.inventorySerialError = true;
        }
      }

      if (this.inventoryType === "Exchange") {
        if (addInventoryForm.inventoryNewSerial !== '' && addInventoryForm.inventoryNewSerial !== undefined){
          this.inventoryNewSerialError = false;
        }else{
          this.inventoryNewSerialError = true;
        }
      }else{
        this.inventoryNewSerialError = false;
      }
    }else{
      this.inventorySerialError = false;
      this.inventoryNewSerialError = false;
    }

    // if (addInventoryForm.inventoryType !== '' && addInventoryForm.inventoryType !== "Issue"){
    //   if (Number(addInventoryForm.inventoryItemOption) >= 0) {
    //     this.inventoryOptionError = false;
    //   }else{
    //     this.inventoryOptionError = true;
    //   }
    // }else{
    //   this.inventoryOptionError = false;
    // }

    if (this.inventoryItemOption != ''){
      this.inventoryOptionError = false;
    }else{
      this.inventoryOptionError = true;
      if (this.inventoryType == "Issue"){
        this.inventoryOptionError = false;
      }
    }

    let InventoryQuantity = 1;

    if (this.inventoryisQuantity){
      if (addInventoryForm.inventoryQuantity > 0){
        this.inventoryQuantityError = false;
        if (addInventoryForm.inventoryQuantity > this.InventoryMaxQuantity){
          this.inventoryQuantityError = true;
          this.inventoryQuantityErrorStr = "Quantity is less than equal to Issued Quantity.";
        }else{
          this.inventoryQuantityError = false;
        }
        InventoryQuantity = addInventoryForm.inventoryQuantity
      }else{
        this.inventoryQuantityError = true;
        this.inventoryQuantityErrorStr = "Quantity is required.";
      }
    }else{
      this.inventoryQuantityError = false;
    }

    if (this.inventoryItemError == false && this.inventoryOptionError == false && this.inventorySerialError == false && this.inventoryNewSerialError == false && this.inventoryQuantityError == false) {

      let addInventoryParams = {
        id_items: addInventoryForm.inventoryItem,
        id_options: addInventoryForm.inventoryItemOption,
        inventory_type: this.inventoryType,
        user_id: usersub,
        user_type: "user",
        userpull_id: localStorage.getItem('userpull_id'),
        patient_id: this.addInventoryPatient.sub,
        serial: addInventoryForm.inventorySerial,
        new_serial: addInventoryForm.inventoryNewSerial,
        userauth: localStorage.getItem('AUTH-TOKEN'),
        quantity: InventoryQuantity
      };

      if ((this.inventoryType == 'Issue' || this.inventoryType == 'Exchange') && (addInventoryForm.inventoryItem != '1' && addInventoryForm.inventorySerial !== '' && addInventoryForm.inventorySerial !== undefined)){
        let iGlocoseParams = {
          api_key: "E54E547C-5E9D-4103-AF25-0EB971E93BDF-1623765760"
        };

        if (this.inventoryType == 'Issue'){
          iGlocoseParams['device_id'] = addInventoryForm.inventorySerial;
        }else if (this.inventoryType == 'Exchange'){
          iGlocoseParams['device_id'] = addInventoryForm.inventoryNewSerial;
        }

        this.screen.style.display = 'block';

        
        this.httpClient.post('https://api.iglucose.com/devices/validate/', iGlocoseParams)
        .subscribe( (response: any) => {
          this.screen.style.display = 'none';
          console.log("response");
          console.log(response)

          if (response.is_valid == true){
            addInventoryParams['item_active'] = 1;
            this.patientAddInventoryApiCall(addInventoryParams);
          }else if(response.is_valid == false){
            var confirmMsg = "This device is not active  you want to continue";
            if(confirm(confirmMsg)) {
              addInventoryParams['item_active'] = 0;
              this.patientAddInventoryApiCall(addInventoryParams);
            }
          }

        },(error: any) => {
          console.log(error);
          this.screen.style.display = 'none';          
        });
      }else{
        addInventoryParams['item_active'] = 1;
        this.patientAddInventoryApiCall(addInventoryParams);
      }      
      
    }
  }

  patientAddInventoryApiCall = (addInventoryParams) => {
    console.log("patientAddInventoryApiCall");



    console.log("final api call call");

    console.log("final api parameter", addInventoryParams);



    this.screen.style.display = 'block';

    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addinventory', addInventoryParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe( async (response: any) => {
      console.log("response");
      console.log(response)
      this.screen.style.display = 'none';
      if (response.status === "success") {
        alert("Inventory save successfully");
        let patientSmsModal = document.getElementById("patient-add-inventory-modal-close-button") as HTMLElement;
        patientSmsModal.click();
        
        this.patientInventory(this.addInventoryPatient);
      }else{
        alert(response.message);
      }
    },
    async (error: any) => {
      console.log(error);
      alert("Could not save inventory. Please try again.");
      this.screen.style.display = 'none';
    });

  }

  clickfrom = "";
  devicedeviceName = "";
  deviceEscalationItemList = [];
  deviceEscalationSettingList = [];

  viewDeviceescalation = (comefrom, deviceid, deviceName) =>{
    console.log("viewDeviceescalation")
    console.log(deviceid)

    this.clickfrom = comefrom

    this.deviceEscalationItemList = [];
    this.deviceEscalationSettingList = [];

    this.devicedeviceName = deviceName;

    this.screen.style.display = 'block';

    //Setting Popup close
    if (comefrom === 'Setting'){
      let settingModal = document.getElementById("default-settings-details-modal-close-button") as HTMLElement;
      settingModal.click();
    }

    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      device: deviceid
    };
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpracticedeviceglobalsettings', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
      console.log(response);
      this.screen.style.display = 'none';
      if(response.status == "success") {
        var tempescalationSettingList = JSON.parse(response.data);

        let esclationLabel = {          

          //{"bmiMax": 25, "bmiMin": 18, "wtMax": 200, "wtMin": 10}

          bmiMax: "BMI (Max)",
          bmiMin: "BMI (Min)",
          wtMax: "Weight (Max)",
          wtMin: "Weight (Min)",

          //{"diaMax": 80, "diaMin": 60, "pulseMax": 100, "pulseMin": 60, "sysMax": 85, "sysMin": 55}

          diaMax: "DIA (Max)",
          diaMin: "DIA (Min)",
          pulseMax: "PULSE (Max)",
          pulseMin: "PULSE (Min)",
          sysMax: "SYS (Max)",
          sysMin: "SYS (Min)",

          //{"tempMax": 99, "tempMin": 97}

          tempMax: "Temp (Max)",
          tempMin: "Temp (Min)",

          //{"prMax": 120, "prMin": 60, "spo2Max": 99, "spo2Min": 92}

          prMax: "PR (Max)",
          prMin: "PR (Min)",
          spo2Max: "SPO2 (Max)",
          spo2Min: "SPO2 (Min)",

          //{"glucoseAfterMealMax": 250, "glucoseAfterMealMin": 80, "glucoseFastingMax": 160, "glucoseFastingMin": 40, "glucoseNormalTestMax": 214, "glucoseNormalTestMin": 60}

          glucoseAfterMealMax: "Within 2hr. Meal (Max)",
          glucoseAfterMealMin: "Within 2hr. Meal (Min)",
          glucoseFastingMax: "Fasting (Max)",
          glucoseFastingMin: "Fasting (Min)",
          glucoseNormalTestMax: "Normal (Max)",
          glucoseNormalTestMin: "Normal (Min)"
        };

        for(var temp of tempescalationSettingList){
          console.log(temp.name)
          console.log(temp.value)

          this.deviceEscalationSettingList.push({ name : esclationLabel[temp.name], value: temp.value });
        }
        

        //List
        this.screen.style.display = 'block';
        let params = {
          userpull_id: localStorage.getItem('userpull_id'),
          device: deviceid
        };
        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getescalation', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
          .subscribe((response: any) => {
          console.log(response);
          this.screen.style.display = 'none';
          if(response.status == "success") {
            this.deviceEscalationItemList = JSON.parse(response.data);
          }
        },
        async (error: any) => {
          Auth.signOut();
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.screen.style.display = 'none';
          this.router.navigateByUrl('/sign-in');
        });
      }
    },
    async (error: any) => {
      Auth.signOut();
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });

    let patientSmsModal = document.getElementById("device-escalation-ankerTag") as HTMLElement;
    patientSmsModal.click();
    
  }

  deviceEscalationModalClosoButtonClick = (clickfrom) => {
    console.log("deviceEscalationModalClosoButtonClick");
    console.log(clickfrom);

    let patientSmsModal = document.getElementById("device-escalation-modal-close-button") as HTMLElement;
    patientSmsModal.click();

    if (clickfrom == 'Setting'){
      this.getDefaultSettings();
    }    

  }

  

  PayerList = [];

  eligibilitystoreData: any;
  eligibilityfileUploaded: File;
  eligibilityworksheet: any;

  eligibilityerrorUploadMsg: string = '';
  eligibilitysuccessUploadMsg: string = '';

  eligibilitysearchstr: string = '';

  EligibilitythisDate: any;

  eligibilityuploadedFile(event) {
    console.log("onchange");

    this.eligibilityerrorUploadMsg = "";
    this.eligibilityfileUploaded = event.target.files[0];
    if(this.eligibilityfileUploaded && this.eligibilityfileUploaded.type && this.eligibilityfileUploaded.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.eligibilityreadExcel();
    }
    else if(this.eligibilityfileUploaded && this.eligibilityfileUploaded.type){
      alert("Please choose valid excel file");
      this.eligibilityworksheet = "";
      this.myInputVariable.nativeElement.value = "";
    }
    else {
      this.eligibilityworksheet = "";
      this.myInputVariable.nativeElement.value = "";
    }
  }

  eligibilityreadExcel() {
    let readFile = new FileReader();
    readFile.onload = (e) => {
      this.eligibilitystoreData = readFile.result;
      var data = new Uint8Array(this.eligibilitystoreData);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      this.eligibilityworksheet = workbook.Sheets[first_sheet_name];
      this.eligibilityerrorUploadMsg = '';
      this.eligibilitysuccessUploadMsg = '';
    }
    readFile.readAsArrayBuffer(this.eligibilityfileUploaded);
  }

  eligibilityreadAsJson() {

    let jsonData = XLSX.utils.sheet_to_json(this.eligibilityworksheet, { raw: false });
    console.log(jsonData);
    this.eligibilityerrorUploadMsg = '';
    this.eligibilitysuccessUploadMsg = '';
    let onlyAlphabets = /^[A-Za-z]+$/;
    let onlyAlphaNumeric = /^[A-Za-z0-9 ]+$/;
    let domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
    let prefixval = (<HTMLInputElement>document.getElementById('emailPrefix')).value;
    let domainval = (<HTMLInputElement>document.getElementById('emailDomain')).value;
    let providercheckval = (<HTMLInputElement>document.getElementById('assigntoprovider')).checked;

    
    // if(!prefixval || !prefixval.match(onlyAlphabets)) {
    //   this.eligibilityerrorUploadMsg += '<br>Invalid Email Prefix';
    // }
    // if(!domainval || !domainval.match(domainRegex)) {
    //   this.eligibilityerrorUploadMsg += '<br>Invalid Email Domain';
    // }
    if(this.eligibilityerrorUploadMsg) {
      var errMsg = 'There is some error. Please check following:';
      this.eligibilityerrorUploadMsg = errMsg + this.eligibilityerrorUploadMsg;
      return false;
    }
    if(jsonData.length > 0){
      let userDataArr = []
      for(var i=0; i<jsonData.length; i++) {
        console.log(jsonData[i]);
        if(!jsonData[i]['First Name *'] || typeof jsonData[i]['First Name *'] === 'undefined' || !jsonData[i]['First Name *'].match(onlyAlphaNumeric)){
          this.eligibilityerrorUploadMsg += '<br>Invalid First Name';
        }

        if(!jsonData[i]['Last Name *'] || typeof jsonData[i]['Last Name *'] === 'undefined' || !jsonData[i]['Last Name *'].match(onlyAlphaNumeric)){
          this.eligibilityerrorUploadMsg += '<br>Invalid Last Name';
        }

        let phone_numberPattern = /^[+]\d+$/;                ///// Checking for +Number /////
        if(!jsonData[i]['Phone Number * (ex: +19876543210)'] || typeof jsonData[i]['Phone Number * (ex: +19876543210)'] === 'undefined' || !jsonData[i]['Phone Number * (ex: +19876543210)'].match(phone_numberPattern)){
          this.eligibilityerrorUploadMsg += '<br>Phone Number should be in "+19876543210" format';
        }

        // let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        // if(jsonData[i]['FaceTime (Phone Number / Email)'] && jsonData[i]['FaceTime (Phone Number / Email)'] != '' && !jsonData[i]['FaceTime (Phone Number / Email)'].match(phone_numberPattern) && !jsonData[i]['FaceTime (Phone Number / Email)'].match(emailPattern)){
        //   this.eligibilityerrorUploadMsg += '<br>FaceTime should be in "+19876543210" Phone Number format or any valid email format';
        // }

        if( !jsonData[i]['DOB * (MM/DD/YYYY)']  || typeof jsonData[i]['DOB * (MM/DD/YYYY)'] === 'undefined' || jsonData[i]['DOB * (MM/DD/YYYY)']==''){
          this.eligibilityerrorUploadMsg += '<br>Please provide DOB in "MM/DD/YYYY" format';
        }
        else {
          if(!this.datevalidate(jsonData[i]['DOB * (MM/DD/YYYY)'])) {
            console.log(jsonData[i]['DOB * (MM/DD/YYYY)']);
            this.eligibilityerrorUploadMsg += '<br>Please provide valid DOB in "MM/DD/YYYY" format';
          }
          else {
            var dateinput = new Date( jsonData[i]['DOB * (MM/DD/YYYY)'] );
            console.log(dateinput);
            var datecurrent = new Date();
            console.log(datecurrent);
            if (dateinput >= datecurrent) {
              this.eligibilityerrorUploadMsg += '<br>DOB should be less than or equal to today';
            }
          }
        }

        if(!jsonData[i]['Gender * (M / F)'] || typeof jsonData[i]['Gender * (M / F)'] === 'undefined' || (jsonData[i]['Gender * (M / F)'] && jsonData[i]['Gender * (M / F)'] != "M" && jsonData[i]['Gender * (M / F)'] != "F")){
          this.eligibilityerrorUploadMsg += '<br>Please provide valid Gender';
        }

        // if(!jsonData[i]['Height * (in inches)'] || typeof jsonData[i]['Height * (in inches)'] === 'undefined' || jsonData[i]['Height * (in inches)']=="" || Number(jsonData[i]['Height * (in inches)'])<1){
        //   this.eligibilityerrorUploadMsg += '<br>Please provide Height';
        // }

        // if(!jsonData[i]['Address *'] || typeof jsonData[i]['Address *'] === 'undefined' || jsonData[i]['Address *']==''){
        //   this.eligibilityerrorUploadMsg += '<br>Please provide Address';
        // }

        if(!jsonData[i]['Primary Payer Code *'] || typeof jsonData[i]['Primary Payer Code *'] === 'undefined' || jsonData[i]['Primary Payer Code *']==''){
          this.eligibilityerrorUploadMsg += '<br>Please provide Primary Payer Code';
        }else{
          if (this.PayerList.some(function(el){ return el.payerCode === jsonData[i]['Primary Payer Code *'] })){
            var tempprimarypayer = this.PayerList.find(x => x.payerCode === jsonData[i]['Primary Payer Code *']);
            jsonData[i]['primary_payername'] = tempprimarypayer['payerName'];
          }else{
            this.eligibilityerrorUploadMsg += '<br>Please enter proper Primary Payer Code';
          }
        }

        if(!jsonData[i]['Primary Member ID *'] || typeof jsonData[i]['Primary Member ID *'] === 'undefined' || jsonData[i]['Primary Member ID *']==''){
          this.eligibilityerrorUploadMsg += '<br>Please provide Primary Member ID';
        }

        //Secondary Checking
        if(jsonData[i]['Supplemental Payer Code'] != undefined && jsonData[i]['Supplemental Payer Code'] != '') {
          if(!jsonData[i]['Supplemental Payer Code'] || typeof jsonData[i]['Supplemental Payer Code'] === 'undefined' || jsonData[i]['Supplemental Payer Code']==''){
            this.eligibilityerrorUploadMsg += '<br>Please provide Supplemental Payer Code';
          }else{
            if (this.PayerList.some(function(el){ return el.payerCode === jsonData[i]['Supplemental Payer Code'] })){
              var tempprimarypayer = this.PayerList.find(x => x.payerCode === jsonData[i]['Supplemental Payer Code']);
              jsonData[i]['secondary_payername'] = tempprimarypayer['payerName'];
            }else{
              this.eligibilityerrorUploadMsg += '<br>Please enter proper Supplemental Payer Code';
            }
          }
        }

        if(jsonData[i]['Supplemental Member ID'] != undefined && jsonData[i]['Supplemental Member ID'] != '') {
          if(!jsonData[i]['Supplemental Member ID'] || typeof jsonData[i]['Supplemental Member ID'] === 'undefined' || jsonData[i]['Supplemental Member ID']==''){
            this.eligibilityerrorUploadMsg += '<br>Please provide Supplemental Member ID';
          }
        }

        if(this.eligibilityerrorUploadMsg) {
          var errMsg = 'There is some error on line no. '+String(parseInt(jsonData[i]['__rowNum__'])+1)+'. Please check following:';
          this.eligibilityerrorUploadMsg = errMsg + this.eligibilityerrorUploadMsg;
          this.eligibilityworksheet = "";
          this.myInputVariable.nativeElement.value = "";
          return false;
        }

        
        let userData = {};
        
        userData['firstName'] = jsonData[i]['First Name *'];
        userData['lastName'] = jsonData[i]['Last Name *'];
        userData['phone_number'] = jsonData[i]['Phone Number * (ex: +19876543210)'];
        userData['birthdate'] = jsonData[i]['DOB * (MM/DD/YYYY)'].replaceAll('/','-');

        userData['temp_dob'] = jsonData[i]['DOB * (MM/DD/YYYY)'];

        if(jsonData[i]['Gender * (M / F)'] == "M") {
          userData['gender'] = "Male";
        }
        else if(jsonData[i]['Gender * (M / F)'] == "F") {
          userData['gender'] = "Female";
        }        

        userData['primary_memberid'] = jsonData[i]['Primary Member ID *'];
        userData['primary_payercode'] = jsonData[i]['Primary Payer Code *'];
        userData['primary_payername'] = jsonData[i]['primary_payername'];

        // userData['si'] = jsonData[i]['Supplemental Insurance'].trim();secondary_payercode
        // userData['secondary_memberid'] = jsonData[i]['Supplemental Insurance ID'];secondary_payername

        if(jsonData[i]['Supplemental Payer Code'] != undefined && jsonData[i]['Supplemental Payer Code'] != '') {
          userData['secondary_payercode'] = jsonData[i]['Supplemental Payer Code'].trim();
          userData['secondary_payername'] = jsonData[i]['secondary_payername'].trim();
        }else{
          userData['si'] = '';
        }

        if(jsonData[i]['Supplemental Member ID'] != undefined && jsonData[i]['Supplemental Member ID'] != '') {
          userData['secondary_memberid'] = jsonData[i]['Supplemental Member ID'].trim();
        }else{
          userData['secondary_memberid'] = '';
        }

        console.log(userData);
        userDataArr.push(userData);
      }
      console.log(userDataArr);
      
      if (userDataArr.length > 0){
        this.eligibilityUploadSubmit(userDataArr);
      }
      
    }
    else {
      var errMsg = 'There is some error. Please check following:';
      this.eligibilityerrorUploadMsg = errMsg+"<br>Please choose correct file";
      return false;
    }
    this.eligibilityworksheet = "";
    this.myInputVariable.nativeElement.value = "";
  }

  getAllPayerList = () => {
    console.log("getAllPayerList");

    this.screen.style.display = 'block';

    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/geteligibilityallpayerlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      this.screen.style.display = 'none';
      this.PayerList = JSON.parse(response.data);
      this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
        if(dtElement.dtInstance) {
          dtElement.dtInstance.then((dtInstance: any) => {
            if(dtInstance.table().node().id == "patient-payer-table") {
              dtInstance.destroy();
              this.dtTrigger3.next();
            }
          });
        }
        else {
          this.dtTrigger3.next();
        }
      });
    },
    async (error: any) => {
      console.log(error);
      this.screen.style.display = 'none';
    });
  }

  

  eligibilityUploadSubmit = (patientList) => {
    let usersub = ""

    if (localStorage.getItem('USER-DETAILS')) {
      let userDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));

      if(userDetails.sub){
        usersub = userDetails.sub;
      }
    }

    var tempDate = this.EligibilitythisDate.split("-");

    let addInventoryParams = {
      user_id: usersub,
      user_type: "user",
      doS_Date: tempDate[1] + "-" + tempDate[2] + "-" + tempDate[0],
      userpull_id: localStorage.getItem('userpull_id'),
      patients: patientList
    };

    this.screen.style.display = 'block';

    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/patientseligibilityupload', addInventoryParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe( async (response: any) => {
      console.log("response");
      console.log(response)
      this.screen.style.display = 'none';
      if (response.status === "success") {
        var eligibilityResponce = JSON.parse(response.data);
        
        alert("upload save successfully");
        alert("Excel Total Patient : " + eligibilityResponce['excel_patient'] + "\n Found in Practice Patient : " + eligibilityResponce['found_patient'] + " \n Already Eligibility Patient : " + eligibilityResponce['exist_patient'] + " \n New Eligibility Saved Patient : " + eligibilityResponce['eligibility_patient'])
       
      }else{
        alert(response.message);
      }
    },
    async (error: any) => {
      console.log(error);
      alert("Could not save upload. Please try again.");
      this.screen.style.display = 'none';
    });

  }

  
  downloadAveryExcel = () => {
    console.log("downloadAveryExcel");

    var ExcelPatientList = [];

    for(var patient of this.patientList){
      if(patient.selectedclass){
        var temppatient = {}
        var Str = patient.firstName + " " + patient.lastName;
        if(patient.address != ''){
          Str += ", "+patient.address;
        }
        if(patient.home_address1 != ''){
          Str += ", "+patient.home_address1;
        }
        if(patient.home_city != ''){
          Str += ", "+patient.home_city;
        }
        if(patient.home_state != ''){
          Str += ", "+patient.home_state;
        }
        if(patient.home_pin != ''){
          Str += ", "+patient.home_pin;
        }
        temppatient["Name_Address"] = Str;
        ExcelPatientList.push(temppatient);
      }
    }

    /* table id is passed over here */   
    const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(ExcelPatientList);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, "Shipping_Label.xlsx");
  }

  RiskPatient: any;
  RiskList = [{id: "", name: "None"}, {id: "Mild", name: "Mild"}, {id: "Moderate", name: "Moderate"},{id: "High", name: "High"}];
  lace_risk = "";
  ascvd_risk = "";
  stroke_risk = "";

  RiskbuttonClick = (patient) => {
    console.log("RiskbuttonClick");
    console.log(patient);

    this.RiskPatient = patient;

    this.lace_risk = "";
    this.ascvd_risk = "";
    this.stroke_risk = "";

    if(patient.lace_risk != ''){
      this.lace_risk = patient.lace_risk;
    }
    if(patient.ascvd_risk != ''){
      this.ascvd_risk = patient.ascvd_risk;
    }
    if(patient.stroke_risk != ''){
      this.stroke_risk = patient.stroke_risk;
    }

    let patientSmsModal = document.getElementById("patient-risk-ankerTag") as HTMLElement;
    patientSmsModal.click();

  }

  patientRiskSubmitButtonClick = (riskForm) => {
    console.log("patientRiskSubmitButtonClick");
    console.log(riskForm)

    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      sub: this.RiskPatient.sub,
      group: "Patient",
      ascvd_risk: riskForm.ascvd_risk,
      lace_risk: riskForm.lace_risk,
      stroke_risk: riskForm.stroke_risk
    };

    console.log("params", params);
    // return
    this.screen.style.display = 'block';

    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      if(response.status == "success") {
        alert("Patient risk updated successfully");
        location.reload();
      }
      else {
        this.screen.style.display = 'none';
        alert("Patient risk could not be updated");
      }
    },
    async (error: any) => {
      console.log(error);
      this.screen.style.display = 'none';
      alert("Patient risk could not be updated. Please try again.");
    });
  }

  addTicketPatient: any;
  PriorityList = [{id: "High", name: "High"}, {id: "Medium", name: "Medium"}, {id: "Low", name: "Low"}];

  addTicketpriorityError = false;
  addTicketsubjectError = false;
  addTicketDescriptionError = false;
  addTicketavailabilityError = false;

  patientTicketList = [];

  Availability_List = [{id: "Yes", name: "Yes"}, {id: "No", name: "No"}];

  AddZohoTicket = (patient) => {
    console.log("AddZohoTicket");
    console.log(patient);

    this.addTicketpriorityError = false;
    this.addTicketsubjectError = false;
    this.addTicketDescriptionError = false;
    this.addTicketavailabilityError = false;

    this.patientTicketList = [];

    this.addTicketPatient = patient;

    let patientSmsModal = document.getElementById("patient-add-ticket-modal-close-button") as HTMLElement;
    patientSmsModal.click();

    this.screen.style.display = 'block';

    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        patient_sub: patient.sub
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpatientzohoticketlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response)
      this.screen.style.display = 'none';
      if (response.status === "success") { 
        var temppatientTicketList = JSON.parse(response.data);

        for (var tempItem of temppatientTicketList){
          
          if (tempItem['Created_Time']){
            var tempCreated_Time = tempItem['Created_Time'].split('T');
            tempItem['Created_Time_str'] = tempCreated_Time[0];
          }

          this.patientTicketList.push(tempItem);
        }

        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
          if(dtElement.dtInstance) {
            dtElement.dtInstance.then((dtInstance: any) => {
              if(dtInstance.table().node().id == "zoho-table") {
                dtInstance.destroy();
                this.dtTrigger4.next();
              }
            });
          }
          else {
            this.dtTrigger4.next();
          }
        });

        let patientaddTicketModal = document.getElementById("patient-add-ticket-ankerTag") as HTMLElement;
        patientaddTicketModal.click();   
      }
    },
    async (error: any) => {
      await Auth.signOut({
        global: true
      });
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      this.screen.style.display = 'none';
      this.router.navigateByUrl('/sign-in');
    });
  }

  addTicketDescriptionChange = (value) => {
    console.log("addTicketDescriptionChange");
    console.log(value);

    if (value !== '' && value !== undefined){
      this.addTicketDescriptionError = false;
    }else{
      this.addTicketDescriptionError = true;
    }
  }

  addTicketSubjectChange = (value) => {
    console.log("addTicketSubjectChange");
    console.log(value);

    if (value !== '' && value !== undefined){
      this.addTicketsubjectError = false;
    }else{
      this.addTicketsubjectError = true;
    }
  }

  addTicketPriorityChange = (value) => {
    console.log("addTicketPriorityChange");
    console.log(value);

    this.addTicketpriorityError = false;
  }


  addTicketAvailabilityChange = (value) => {
    console.log("addTicketAvailabilityChange");
    console.log(value);

    if (value !== '' && value !== undefined){
      this.addTicketavailabilityError = false;
    }else{
      this.addTicketavailabilityError = true;
    }
  }

  patientAddTicketSubmitButtonClick = (addTicketForm) => {
    console.log("patientAddTicketSubmitButtonClick");

    console.log(addTicketForm);

    if (addTicketForm.priority !== '' && addTicketForm.priority !== undefined){
      this.addTicketpriorityError = false;
    }else{
      this.addTicketpriorityError = true;
    }

    if (addTicketForm.subject !== '' && addTicketForm.subject !== undefined){
      this.addTicketsubjectError = false;
    }else{
      this.addTicketsubjectError = true;
    }

    if (addTicketForm.description !== '' && addTicketForm.description !== undefined){
      this.addTicketDescriptionError = false;
    }else{
      this.addTicketDescriptionError = true;
    }

    if (addTicketForm.availability !== '' && addTicketForm.availability !== undefined){
      this.addTicketavailabilityError = false;
    }else{
      this.addTicketavailabilityError = true;
    }

    if(this.addTicketpriorityError == false && this.addTicketsubjectError == false && this.addTicketDescriptionError == false && this.addTicketavailabilityError == false){
      let addTicketParams = {
        userpull_id: localStorage.getItem('userpull_id'),
        
        Priority: addTicketForm.priority,
        Subject: addTicketForm.subject,
        Description: addTicketForm.description,
        availability: addTicketForm.availability,
        patient_id: this.addTicketPatient.sub,
        start_date:  this.formatUTCDBDateTime(this.ZohoTicketDate+" 00:00:00"),
        end_date:  this.formatUTCDBDateTime(this.ZohoTicketDate+" 23:59:59")
      };

      console.log(addTicketParams);
      // return;

      this.screen.style.display = 'block';

      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/addzohoticket', addTicketParams, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe( async (response: any) => {
        console.log("response");
        console.log(response)
        this.screen.style.display = 'none';
        if (response.status === "success") {
          alert("Ticket save successfully");
          let patientSmsModal = document.getElementById("patient-add-ticket-modal-close-button") as HTMLElement;
          patientSmsModal.click();
          
          this.AddZohoTicket(this.addTicketPatient);
        }else{
          alert(response.message);
        }
      },
      async (error: any) => {
        console.log(error);
        alert("Could not save Ticket. Please try again.");
        this.screen.style.display = 'none';
      });
    }

  }


  addZohoTicketModalClosoButtonClick = () =>{
    this.patientTicketList = [];

    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      if(dtElement.dtInstance) {
      dtElement.dtInstance.then((dtInstance: any) => {
        if(dtInstance.table().node().id == "zoho-table") {
          dtInstance.destroy();
          this.dtTrigger4.next();
        }
      });
      }
      else {
        this.dtTrigger4.next();
      }
    });

    let patientzohoModal = document.getElementById("patient-add-ticket-modal-close-button") as HTMLElement;
    patientzohoModal.click();
  }

  RefreshZohoTicketList = (patient) => {
    this.addZohoTicketModalClosoButtonClick();

    this.AddZohoTicket(patient);
  }


  formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  formatDateMEligibility = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('-');
  }

  formatUTCDBDateTime = (date) => {
    var d = new Date(date),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getUTCFullYear(),
      hours = d.getUTCHours(),
      minutes = d.getUTCMinutes(),
      seconds = d.getUTCSeconds();
      var strhour = ''+ hours;
      var strminute = '' + minutes;
      var strsecond = '' + seconds;
      if(hours < 10){
        strhour = '0' + hours;
      }
      if(minutes < 10){
        strminute = '0' + minutes;
      }
      if(seconds < 10){
        strsecond = '0' + seconds;
      }
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-')+" "+strhour+":"+strminute+":"+strsecond;
  }

  registerstatus = 0;
  registerpatientdetail: any;
  historypatient: any;
  historypatientdetail: any;
  hardshipfileToUpload: File | null = null;
  customfileToUpload: File | null = null;

  selregisterstatus = (registerstat, patientdetail) => {
    this.hardshipfileToUpload = null;
    this.customfileToUpload = null;
    console.log(registerstat.target.value);
    this.registerstatus = registerstat.target.value;
    console.log(patientdetail);
    this.registerpatientdetail = patientdetail;
    let patientRegStatModal = document.getElementById("register-ankerTag") as HTMLElement;
    patientRegStatModal.click();
  }

  ccm_registerstatus = 0;
  ccm_registerpatientdetail: any;
  ccm_historypatient: any;
  ccm_historypatientdetail: any;

  ccm_selregisterstatus = (registerstat, patientdetail) => {
    console.log(registerstat.target.value);
    this.ccm_registerstatus = registerstat.target.value;
    console.log(patientdetail);
    this.ccm_registerpatientdetail = patientdetail;
    let patientRegStatModal = document.getElementById("ccm_register-ankerTag") as HTMLElement;
    patientRegStatModal.click();
  }

  registerhistory = (patientdetail) =>{
    console.log(patientdetail);
    this.historypatient = [];
    for(var reghistory of patientdetail['register_history']){
      reghistory['lctimestamp'] = this.formatTimeM(reghistory['timestamp'].replaceAll('-','/') + " UTC");
      this.historypatient.push(reghistory);
    }
    this.historypatientdetail = patientdetail;
    let patientRegHistModal = document.getElementById("registerhistory-ankerTag") as HTMLElement;
    patientRegHistModal.click();
  }

  ccm_registerhistory = (patientdetail) =>{
    console.log(patientdetail);
    this.ccm_historypatient = [];
    for(var reghistory of patientdetail['ccm_register_history']){
      reghistory['lctimestamp'] = this.formatTimeM(reghistory['timestamp'].replaceAll('-','/') + " UTC");
      this.ccm_historypatient.push(reghistory);
    }
    this.ccm_historypatientdetail = patientdetail;
    let patientRegHistModal = document.getElementById("ccm_registerhistory-ankerTag") as HTMLElement;
    patientRegHistModal.click();
  }

  tcm_historypatient: any;
  tcm_historypatientdetail: any;

  tcm_registerhistory = (patientdetail) =>{
    console.log(patientdetail);
    this.tcm_historypatient = [];
    for(var reghistory of patientdetail['tcm_register_history']){
      reghistory['lctimestamp'] = this.formatTimeM(reghistory['timestamp'].replaceAll('-','/') + " UTC");
      this.tcm_historypatient.push(reghistory);
    }
    this.tcm_historypatientdetail = patientdetail;
    let patientRegHistModal = document.getElementById("tcm_registerhistory-ankerTag") as HTMLElement;
    patientRegHistModal.click();
  }

  mtm_historypatient: any;
  mtm_historypatientdetail: any;

  mtm_registerhistory = (patientdetail) =>{
    console.log("mtm_registerhistory")
    this.mtm_historypatient = [];
    for(var reghistory of patientdetail['mtm_register_history']){
      reghistory['lctimestamp'] = this.formatTimeM(reghistory['timestamp'].replaceAll('-','/') + " UTC");
      this.mtm_historypatient.push(reghistory);
    }
    this.mtm_historypatientdetail = patientdetail;
    let patientRegHistModal = document.getElementById("mtm_registerhistory-ankerTag") as HTMLElement;
    patientRegHistModal.click();
  }

  registerreset = (patientdetail) =>{
    console.log(patientdetail);
    patientdetail.register_status=0;
    patientdetail.register_comment={};
  }

  handleFileInput(files: FileList) {
    console.log(files);
    this.hardshipfileToUpload = files.item(0);
  }

  handleCustomFileInput(files: FileList) {
    console.log(files);
    this.customfileToUpload = files.item(0);
  }

  scrollview = () => {
    setTimeout (() => {
      document.getElementById("check-box-"+this.registerpatientdetail['sub']).scrollIntoView();
    }, 500);
  }

  validateRegisterStatForm = (formval) => {
    console.log(formval);
    console.log(this.registerstatus);
    console.log(this.registerpatientdetail);
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      group: "Patient",
      sub: this.registerpatientdetail['sub'],
      register_status: String(this.registerstatus),
      user_group: this.userGroup,
      user_sub: this.userSub
    };
    let register_comment_params = {};
    if(formval['note']) {
      register_comment_params['note'] = formval['note'];
    }
    if(formval['devicetype']) {
      register_comment_params['devicetype'] = formval['devicetype'];
    }
    if(formval['devicebpm']) {
      register_comment_params['devicebpm'] = formval['devicebpm'];
    }
    if(formval['deviceglucose']) {
      register_comment_params['deviceglucose'] = formval['deviceglucose'];
    }
    if(formval['deviceoximeter']) {
      register_comment_params['deviceoximeter'] = formval['deviceoximeter'];
    }
    if(formval['devicescale']) {
      register_comment_params['devicescale'] = formval['devicescale'];
    }
    if(formval['track']) {
      register_comment_params['track'] = formval['track'];
    }
    register_comment_params['user_sub']= localStorage.getItem('user_sub');
    if(register_comment_params) {
      params['register_comment'] = JSON.stringify(register_comment_params);
    }
    console.log(params);
    console.log(this.hardshipfileToUpload);
    console.log(this.customfileToUpload);
    let formData: FormData = new FormData();
    if(this.hardshipfileToUpload || this.customfileToUpload) {
      // formData.append('fileKey', this.hardshipfileToUpload, this.hardshipfileToUpload.name);
      params['fileupload'] = "TRUE";
    }
    for(var param in params){
      console.log(param);
      console.log(params[param]);
      formData.append(param, params[param]);
    }
    let thisscreen = this.screen;
    let thisclient = this.httpClient;
    thisscreen.style.display = 'block';
    // this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', formData, { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data', 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    thisclient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        console.log(this.hardshipfileToUpload);
        console.log(this.customfileToUpload);
        let thishardshipfileToUpload = this.hardshipfileToUpload;
        let thiscustomfileToUpload = this.customfileToUpload;
        let thisregisterpatientsub = this.registerpatientdetail['sub'];
        if(response.status === "success") {
          if(thishardshipfileToUpload) {
            var awsinfo = JSON.parse(response.data);
            AWSServices.config.credentials = new AWSServices.Credentials(awsinfo.access, awsinfo.secret);
            var bucket = new AWSServices.S3({params: {Bucket: 'registercontent'}});
            var parameters = { Bucket: 'registercontent', Key: localStorage.getItem('userpull_id')+'/'+thisregisterpatientsub+'/hardshipfile/'+thishardshipfileToUpload.name, Body: thishardshipfileToUpload, ContentType: thishardshipfileToUpload.type };
            bucket.upload(parameters, function (err, data) {
              console.log(err, data);
              console.log(data);
              if('fileupload' in params) {
                delete params['fileupload'];
              }
              register_comment_params['hardshippath'] = parameters['Key'];
              console.log(thiscustomfileToUpload);
              if(thiscustomfileToUpload){
                var parameterscustom = { Bucket: 'registercontent', Key: localStorage.getItem('userpull_id')+'/'+thisregisterpatientsub+'/customfile/'+thiscustomfileToUpload.name, Body: thiscustomfileToUpload, ContentType: thiscustomfileToUpload.type };
                bucket.upload(parameterscustom, function (err, data) {
                  console.log(err, data);
                  console.log(data);
                  register_comment_params['custompath'] = parameterscustom['Key'];
                  params['register_comment'] = JSON.stringify(register_comment_params);
                  thisclient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
                    .subscribe((response: any) => {
                      console.log(response);
                      for(var k = 0; k < this.patientList.length; k++) {
                        if(this.registerpatientdetail['sub'] == this.patientList[k]['sub']){
                          this.patientList[k]['register_status'] = String(this.registerstatus);
                          this.patientList[k]['register_comment'] = register_comment_params;
                          var historyparam = register_comment_params;
                          historyparam['register_status'] = String(this.registerstatus);
                          historyparam['timestamp'] = this.formatUTCDBDateTime(new Date());
                          if(!this.patientList[k]['register_history']) {
                            this.patientList[k]['register_history'] = []
                          }
                          this.patientList[k]['register_history'].push(historyparam);
                        }
                      }
                      thisscreen.style.display = 'none';
                      let patientRegStatModal = document.getElementById("patient-register-modal-close-button") as HTMLElement;
                      patientRegStatModal.click();
                    },
                    async (error: any) => {
                      console.log(error);
                      thisscreen.style.display = 'none';
                      let patientRegStatModal = document.getElementById("patient-register-modal-close-button") as HTMLElement;
                      patientRegStatModal.click();
                    });
                });
              }
              else {
                params['register_comment'] = JSON.stringify(register_comment_params);
                thisclient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
                  .subscribe((responseup: any) => {
                    console.log(responseup);
                    for(var k = 0; k < this.patientList.length; k++) {
                      if(this.registerpatientdetail['sub'] == this.patientList[k]['sub']){
                        this.patientList[k]['register_status'] = String(this.registerstatus);
                        this.patientList[k]['register_comment'] = register_comment_params;
                        var historyparam = register_comment_params;
                        historyparam['register_status'] = String(this.registerstatus);
                        historyparam['timestamp'] = this.formatUTCDBDateTime(new Date());
                        if(!this.patientList[k]['register_history']) {
                          this.patientList[k]['register_history'] = []
                        }
                        this.patientList[k]['register_history'].push(historyparam);
                      }
                    }
                    thisscreen.style.display = 'none';
                    let patientRegStatModal = document.getElementById("patient-register-modal-close-button") as HTMLElement;
                    patientRegStatModal.click();
                  },
                  async (error: any) => {
                    console.log(error);
                    thisscreen.style.display = 'none';
                    let patientRegStatModal = document.getElementById("patient-register-modal-close-button") as HTMLElement;
                    patientRegStatModal.click();
                  });
              }
            });
          }
          if(thiscustomfileToUpload && !thishardshipfileToUpload) {
            var awsinfo = JSON.parse(response.data);
            AWSServices.config.credentials = new AWSServices.Credentials(awsinfo.access, awsinfo.secret);
            var bucket = new AWSServices.S3({params: {Bucket: 'registercontent'}});
            var parameters = { Bucket: 'registercontent', Key: localStorage.getItem('userpull_id')+'/'+thisregisterpatientsub+'/customfile/'+thiscustomfileToUpload.name, Body: thiscustomfileToUpload, ContentType: thiscustomfileToUpload.type };
            bucket.upload(parameters, function (err, data) {
              console.log(err, data);
              console.log(data);
              if('fileupload' in params) {
                delete params['fileupload'];
              }
              register_comment_params['custompath'] = parameters['Key'];
              params['register_comment'] = JSON.stringify(register_comment_params);
              thisclient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
                .subscribe((response: any) => {
                  console.log(response);
                  for(var k = 0; k < this.patientList.length; k++) {
                    if(this.registerpatientdetail['sub'] == this.patientList[k]['sub']){
                      this.patientList[k]['register_status'] = String(this.registerstatus);
                      this.patientList[k]['register_comment'] = register_comment_params;
                      var historyparam = register_comment_params;
                      historyparam['register_status'] = String(this.registerstatus);
                      historyparam['timestamp'] = this.formatUTCDBDateTime(new Date());
                      if(!this.patientList[k]['register_history']) {
                        this.patientList[k]['register_history'] = []
                      }
                      this.patientList[k]['register_history'].push(historyparam);
                    }
                  }
                  thisscreen.style.display = 'none';
                  let patientRegStatModal = document.getElementById("patient-register-modal-close-button") as HTMLElement;
                  patientRegStatModal.click();
                },
                async (error: any) => {
                  console.log(error);
                  thisscreen.style.display = 'none';
                  let patientRegStatModal = document.getElementById("patient-register-modal-close-button") as HTMLElement;
                  patientRegStatModal.click();
                });
            });
          }
          if(!thishardshipfileToUpload && !this.customfileToUpload) {
            for(var k = 0; k < this.patientList.length; k++) {
              if(this.registerpatientdetail['sub'] == this.patientList[k]['sub']){
                this.patientList[k]['register_status'] = String(this.registerstatus);
                this.patientList[k]['register_comment'] = register_comment_params;
                var historyparam = register_comment_params;
                historyparam['register_status'] = String(this.registerstatus);
                historyparam['timestamp'] = this.formatUTCDBDateTime(new Date());
                if(!this.patientList[k]['register_history']) {
                  this.patientList[k]['register_history'] = []
                }
                this.patientList[k]['register_history'].push(historyparam);
              }
            }
            thisscreen.style.display = 'none';
            let patientRegStatModal = document.getElementById("patient-register-modal-close-button") as HTMLElement;
            patientRegStatModal.click();
          }
        }
        else {
          thisscreen.style.display = 'none';
          let patientRegStatModal = document.getElementById("patient-register-modal-close-button") as HTMLElement;
          patientRegStatModal.click();
        }
      },
      async (error: any) => {
        console.log(error);
        alert('Could not update Register Status');
        // location.reload();
      });
  }

  ccm_validateRegisterStatForm = (formval) => {
    console.log(formval);
    console.log(this.ccm_registerstatus);
    console.log(this.ccm_registerpatientdetail);
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      group: "Patient",
      sub: this.ccm_registerpatientdetail['sub'],
      ccm_register_status: String(this.ccm_registerstatus)
    };
    let register_comment_params = {};
    if(formval['note']) {
      register_comment_params['note'] = formval['note'];
    }
    register_comment_params['user_sub']= localStorage.getItem('user_sub');
    if(register_comment_params) {
      params['ccm_register_comment'] = JSON.stringify(register_comment_params);
    }
    console.log(params);
    
    // return
    let thisscreen = this.screen;
    let thisclient = this.httpClient;
    thisscreen.style.display = 'block';

    thisclient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        if(response.status === "success") {
          thisscreen.style.display = 'none';
          let patientRegStatModal = document.getElementById("ccm-patient-register-modal-close-button") as HTMLElement;
          patientRegStatModal.click();
        }
        else {
          thisscreen.style.display = 'none';
          alert('Could not update CCM Status');
        }
      },
      async (error: any) => {
        console.log(error);
        alert('Could not update CCM Status');
        // location.reload();
      });
  }
  
}

import { Component, OnInit, ViewChild , OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AWS } from '@aws-amplify/core';
import { DataTableDirective } from 'angular-datatables';
import { Auth } from 'aws-amplify';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { empty, Subject } from 'rxjs';

import * as country_list_data from 'src/environments/country_list.json';
declare var $: any;


@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css']
})
export class ProviderComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  processing: HTMLElement = document.getElementById('cover') as HTMLElement;
  screen: HTMLElement = document.getElementById('screen') as HTMLElement;
  providerList: any;
  country_list_code: any = (country_list_data as any).default;
  formInvalid:boolean = false;
  formInvalidMsg:boolean = false;
  flashSuccess:boolean = false;

  cpas:boolean = false;

  allmedicareservices: any;

  providerEditMode:boolean = false;

  givenNameInvalid: boolean = false;
  givenNameError: string = '';

  middleNameInvalid: boolean = false;
  middleNameError: string = '';

  familyNameInvalid: boolean = false;
  familyNameError: string = '';

  emailInvalid: boolean = false;
  emailError: string = '';

  phoneNumberInvalid: boolean = false;
  phoneNumberError: string = ''; 

  facetimeInvalid: boolean = false;
  facetimeError: string = ''; 
  
  birthdateInvalid: boolean = false;
  birthdateError: string = ''; 

  addressInvalid: boolean = false;
  addressError: string = '';

  genderInvalid: boolean = false;
  genderError: string = '';

  countryInvalid: boolean = false;
  countryError: string = '';  

  formInvalids:boolean = false;
  formInvalidMsgs:boolean = false;


  dtSystemAdmin: DataTables.Settings = {};
  dtTrigger: Subject<ProviderComponent> = new Subject();

  userDetails:any;
  userEditDetails:any;

  cloneId: any;
  cloneName: any;

  npi = "";
  npi_pin = "";
  npiProvider = "";

  mySubscription: any;

  constructor(private router: Router, private httpClient: HttpClient) {
    if(sessionStorage.getItem('flashSuccess')){
      this.flashSuccess = true;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
   }

  ngOnInit() {
    this.screen.style.display = 'block';
    const params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id')
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/rdssync', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => { 
      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id')
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getallmedicareservices', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        if (response.status === "success") {
          this.allmedicareservices = JSON.parse(response.data);
        }
        this.getProviderList(this);
      },
      async (error: any) => {
      });
    },
    async (error: any) => {
      // Auth.signOut();
      // localStorage.removeItem('AUTH');
      // localStorage.removeItem('AUTH-LIFE');
      // localStorage.removeItem('AUTH-PROFILE-PIC');
      // localStorage.removeItem('USER-DETAILS');
      //PatientClass.processing.style.display = 'none';
      // this.screen.style.display = 'none';
      // this.router.navigateByUrl('/sign-in');
      let params = new HttpParams({
        fromObject: {
          userpull_id: localStorage.getItem('userpull_id')
        }
      });
      this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getallmedicareservices', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        if (response.status === "success") {
          this.allmedicareservices = JSON.parse(response.data);
        }
        this.getProviderList(this);
      },
      async (error: any) => {
        this.screen.style.display = 'none';
      });
    });
  }

  AllReportingProvider = () =>{
    if(confirm("Confirm setting this provider as the reporting provider for all active patients?")) {

      console.log(this.userDetails)

      let syncClass = this;
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        provider_sub: this.userDetails.sub
      };
      syncClass.screen.style.display = 'block';
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/allpatientreportingprovider', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        console.log(response);
        syncClass.screen.style.display = 'none';
        if(response.status == "success") {
          alert("Reporting provider update successfully");
        }
        else {
          alert("could not save");
        }
      },
      async (error: any) => {
        Auth.signOut();
                localStorage.removeItem('AUTH');
                localStorage.removeItem('AUTH-LIFE');
                localStorage.removeItem('AUTH-PROFILE-PIC');
                localStorage.removeItem('USER-DETAILS');
                //PatientClass.processing.style.display = 'none';
                syncClass.screen.style.display = 'none';
                syncClass.router.navigateByUrl('/sign-in');
      });

    }
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  getProviderList = (ProviderClass) => {

  	// ProviderClass.processing.style.display = 'block';
    ProviderClass.screen.style.display = 'block';
    
    let params = new HttpParams({
      fromObject: {
        userpull_id: localStorage.getItem('userpull_id'),
        group: 'Provider'
      }
    });

    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getpracticedefaultnpi', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((responsenpi: any) => {
      if (responsenpi.status === "success") {
        let responsedatanpi = JSON.parse(responsenpi.data);
        if (responsedatanpi[0].npi != ''){
          
          this.npi = responsedatanpi[0].npi;
          this.npiProvider = responsedatanpi[0].provider_id;
          this.npi_pin = responsedatanpi[0].npi_pin;

          console.log("npi", this.npi);
          console.log("npiProvider", this.npiProvider);
          console.log("npi_pin", this.npi_pin);
        }

        this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/userlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          console.log(response);
          if (response.status === "success") {
            let responsedata = JSON.parse(response.data);
            for(var i=0; i<responsedata.length; i++){
              responsedata[i]['medicare_codes_assigned_arr'] = responsedata[i]['medicare_codes_assigned'].split(",");
            }
            console.log(responsedata);
            let users = [];
            if (responsedata.length > 0) {
              users = responsedata;
              ProviderClass.providerList = users;
            }
            ProviderClass.screen.style.display = 'none';
            ProviderClass.dtTrigger.next();
            if(sessionStorage.getItem('cpasspsub')) {
              let cpaspsub = sessionStorage.getItem('cpasspsub');
              sessionStorage.removeItem("cpasspsub");
              let providersubdetail = Object();
              for(var i=0; i<responsedata.length; i++){
                if(responsedata[i]['sub'] == cpaspsub) {
                  providersubdetail = responsedata[i];
                }
              }
              if(Object.keys(providersubdetail).length > 0) {
                console.log(providersubdetail);
                setTimeout (() => {
                  this.cpas = true;
                  this.providerDetails(providersubdetail, true);
                }, 500);
              }
            }
          }
        },
        async (error: any) => {
          Auth.signOut();
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          //ProviderClass.processing.style.display = 'none';
          ProviderClass.screen.style.display = 'none';
          ProviderClass.router.navigateByUrl('/sign-in');
        });
      }
    },
    async (error: any) => {
      Auth.signOut();
      localStorage.removeItem('AUTH');
      localStorage.removeItem('AUTH-LIFE');
      localStorage.removeItem('AUTH-PROFILE-PIC');
      localStorage.removeItem('USER-DETAILS');
      //ProviderClass.processing.style.display = 'none';
      ProviderClass.screen.style.display = 'none';
      ProviderClass.router.navigateByUrl('/sign-in');
    });

  	let paramstr = {
  		UserPoolId: localStorage.getItem('userpull_id'),
  		GroupName: 'Provider'
  	};

	
  }

  syncPatients = (data = "") => {
    let syncClass = this;
    console.log(data);
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      provider_sub: data.sub
    };
    syncClass.screen.style.display = 'block';
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/createchannelsync', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      syncClass.screen.style.display = 'none';
      if(response.status == "success") {
        alert("Sync successfully");
      }
      else {
        alert("could not sync");
      }
    },
    async (error: any) => {
      Auth.signOut();
              localStorage.removeItem('AUTH');
              localStorage.removeItem('AUTH-LIFE');
              localStorage.removeItem('AUTH-PROFILE-PIC');
              localStorage.removeItem('USER-DETAILS');
              //PatientClass.processing.style.display = 'none';
              syncClass.screen.style.display = 'none';
              syncClass.router.navigateByUrl('/sign-in');
    });
  }

  patientlist = (ProviderClass, provider) => {
    if(provider.sub) {
      sessionStorage.setItem('DataTables_Provider', provider.sub);
      ProviderClass.router.navigateByUrl('/patient');
    }
  }

  providerDetails = (data = "", cpass=false) => {
    console.log(data);
  	this.userDetails = data;
    this.userEditDetails = data;
  	let providerDetailsModal = document.getElementById("provider-details-ankerTag") as HTMLElement;
  	providerDetailsModal.click();
    if(cpass){
      setTimeout (() => {
        document.getElementById("collapse1").scrollIntoView();
      }, 500);
    }
    else {
      this.cpas = false;
    }
  }

  cloneprovider = (data = "") => {
    this.cloneId = "";
    this.cloneName = "";
    // console.log(data);
    if(data && data['sub']) {
      this.cloneId = data['sub'];
      this.cloneName = data['firstName'] + " " + data['lastName'];
    }
  	let providerCreateModal = document.getElementById("create-provider-ankerTag") as HTMLElement;
  	providerCreateModal.click();
  }

  validateProviderUpdateForm = ( providerData ) =>{
    let ProviderClass = this;
    // console.log(providerData);
    // return false;
    ProviderClass.screen.style.display = 'block';
    let checkValid = true;

    /////// Validition Process Start Here ////////

    let nameAlphabetsNumeric = /^[A-Za-z0-9]+$/;

    if(typeof providerData.given_name === 'undefined' || !providerData.given_name.match(nameAlphabetsNumeric)){
      checkValid = false;
      this.givenNameInvalid = true;
      this.givenNameError   = 'Invalid first name.';
    }else{
      //  checkValid = true;
       this.givenNameInvalid = false;
       this.givenNameError   = '';
    }

    if(typeof providerData.family_name === 'undefined' || !providerData.family_name.match(nameAlphabetsNumeric)){
      checkValid = false;
      this.familyNameInvalid = true;
      this.familyNameError   = 'Invalid last name.';
    }else{
    //  checkValid = true;
     this.familyNameInvalid = false;
     this.familyNameError   = '';
    }
    
    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // if(typeof providerData.email === 'undefined' || !providerData.email.match(emailPattern)){
    //   checkValid = false;
    //   this.emailInvalid = true;
    //   this.emailError   = 'Invalid email.';
    // }else{
    //   // checkValid = true;
    //   this.emailInvalid = false;
    //   this.emailError   = '';
    // }

    let phone_numberPattern = /^[+]\d+$/;                ///// Checking for Number /////
    if(typeof providerData.phone_number === 'undefined' || !providerData.phone_number.match(phone_numberPattern)){
      checkValid = false;
      this.phoneNumberInvalid = true;
      this.phoneNumberError   = 'Invalid Phone number.';
    }else{
      // checkValid = true;
      this.phoneNumberInvalid = false;
      this.phoneNumberError   = '';
    }

    if(providerData['custom-phoneNo2'] && providerData['custom-phoneNo2'] != '' && !providerData['custom-phoneNo2'].match(phone_numberPattern) && !providerData['custom-phoneNo2'].match(emailPattern)){
      checkValid = false;
      this.facetimeInvalid = true;
      this.facetimeError   = 'Invalid FaceTime Phone number.';
    }else{
      // checkValid = true;
      this.facetimeInvalid = false;
      this.facetimeError   = '';
    }

    if(typeof providerData.address === 'undefined' || providerData.address==''){
      checkValid = false;
      this.addressInvalid     = true;
      this.addressError       = 'Invalid Address.';
    }else{
      // checkValid = true;
      this.addressInvalid     = false;
      this.addressError       = '';
    }

    if(typeof providerData.gender === 'undefined'){
      checkValid              = false;
      this.genderInvalid     = true;
      this.genderError       = 'Select Gender.';
    }else{
      // checkValid              = true;
      this.genderInvalid      = false;
      this.genderError        = '';
    }
    console.log(checkValid);
    if(!checkValid)
    {
      ProviderClass.screen.style.display = 'none';
      return false;
    }

    let userData = [];
    let sms_allowed = 0;
    let televisit_flag = 0;
    let medicare_codes_assigned = "";
    for(let k in providerData){
      let Key = k.replace("-", ":");
      let key_value = (providerData[k])?providerData[k].toString():'';
      if(Key == "custom:isSmsAllowed") {
        sms_allowed = Number(key_value)
      }
      else if(Key == "televisit_flag") {
        televisit_flag = Number(key_value)
      }
      else if(Key == "medicare_codes_assigned") {
        medicare_codes_assigned = key_value;
      }
      else{
        userData.push({Name:Key,Value:key_value});
      }
    }
    userData.push({Name:"custom:isSmsAllowed",Value: sms_allowed.toString()});
    userData.push({Name:"televisit_flag",Value: televisit_flag.toString()});
    userData.push({Name:"medicare_codes_assigned",Value: medicare_codes_assigned.toString()});
    // console.log(userData);
    // console.log(this.userDetails);
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      group: "Provider",
      sub: this.userDetails.sub
    };
    
    for(var k = 0; k < userData.length; k++) {
      params[userData[k]['Name']] = userData[k]['Value'];
    }
    // console.log(params); return false;
    
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
      .subscribe((response: any) => {
        if(response.status == "success") {
          alert("Provider updated successfully");
          // location.reload();
          ProviderClass.screen.style.display = 'none';
          this.providerEditMode = false;
          for(let k in providerData){
            let Key = k.replace("custom-", "");
            if(Key == "phoneNo2") {
              Key = "facetime";
            }
            if(Key == "given_name") {
              Key = "firstName";
            }
            if(Key == "family_name") {
              Key = "lastName";
            }
            // console.log(Key);
            // console.log(providerData[k]);
            this.userDetails[Key] = providerData[k];
          }
        }
        else {
          ProviderClass.screen.style.display = 'none';
          alert("Provider could not be updated");
        }
      },
      async (error: any) => {
        console.log(error);
        ProviderClass.screen.style.display = 'none';
        alert("Provider could not be updated. Please try again.");
      });
  }

  validateProviderForm = ( providerData ) =>{
    let ProviderClass = this;
    providerData['custom-country'] = "United States";
    console.log(providerData);
    // return false;
    ProviderClass.processing.style.display = 'block';
    let createProviderModalClose: HTMLElement = document.getElementById('create-provider') as HTMLElement;
    let checkValid = true;

    /////// Validition Process Start Here ////////

    let nameAlphabets = /^[A-Za-z]+$/;
    let nameAlphabetsNumeric = /^[A-Za-z0-9]+$/;
    if(typeof providerData.given_name === 'undefined' || !providerData.given_name.match(nameAlphabetsNumeric)){
      checkValid = false;
      this.givenNameInvalid = true;
      this.givenNameError   = 'Invalid first name.';
    }else{
      //  checkValid = true;
       this.givenNameInvalid = false;
       this.givenNameError   = '';
    }

    // if(typeof providerData.middle_name === 'undefined' || !providerData.middle_name.match(nameAlphabets)){
    //   checkValid = false;
    //   this.middleNameInvalid = true;
    //   this.middleNameError   = 'Invalid middle name.';
    // }else{
    //    checkValid = true;
    //    this.middleNameInvalid = false;
    //    this.middleNameError   = '';
    // }

    if(typeof providerData.family_name === 'undefined' || !providerData.family_name.match(nameAlphabetsNumeric)){
      checkValid = false;
      this.familyNameInvalid = true;
      this.familyNameError   = 'Invalid last name.';
    }else{
    //  checkValid = true;
     this.familyNameInvalid = false;
     this.familyNameError   = '';
    }

    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if(typeof providerData.email === 'undefined' || !providerData.email.match(emailPattern)){
      checkValid = false;
      this.emailInvalid = true;
      this.emailError   = 'Invalid email.';
    }else{
      // checkValid = true;
      this.emailInvalid = false;
      this.emailError   = '';
    }

    let phone_numberPattern = /^[+]\d+$/;                ///// Checking for Number /////
    if(typeof providerData.phone_number === 'undefined' || !providerData.phone_number.match(phone_numberPattern)){
      checkValid = false;
      this.phoneNumberInvalid = true;
      this.phoneNumberError   = 'Invalid Phone number.';
    }else{
      // checkValid = true;
      this.phoneNumberInvalid = false;
      this.phoneNumberError   = '';
    }

    if(providerData['custom-phoneNo2'] && providerData['custom-phoneNo2'] != '' && !providerData['custom-phoneNo2'].match(phone_numberPattern) && !providerData['custom-phoneNo2'].match(emailPattern)){
      checkValid = false;
      this.facetimeInvalid = true;
      this.facetimeError   = 'Invalid FaceTime Phone number.';
    }else{
      // checkValid = true;
      this.facetimeInvalid = false;
      this.facetimeError   = '';
    }

    // if(typeof providerData.birthdate === 'undefined' || providerData.birthdate==''){
    //   checkValid = false;
    //   this.birthdateInvalid   = true;
    //   this.birthdateError     = 'Choose Birthdate.'; 
    // }else{
    //   checkValid = true;
    //   this.birthdateInvalid   = false;
    //   this.birthdateError    = ''; 
    // }

    if(typeof providerData.address === 'undefined' || providerData.address==''){
      checkValid = false;
      this.addressInvalid     = true;
      this.addressError       = 'Invalid Address.';
    }else{
      // checkValid = true;
      this.addressInvalid     = false;
      this.addressError       = '';
    }

    if(typeof providerData.gender === 'undefined'){
      checkValid              = false;
      this.genderInvalid     = true;
      this.genderError       = 'Select Gender.';
    }else{
      // checkValid              = true;
      this.genderInvalid      = false;
      this.genderError        = '';
    }

    // if(providerData['custom-country']){
    //   checkValid              = true;
    //   this.countryInvalid     = false;
    //   this.countryError       = '';
    // }else{
    //      checkValid              = false;
    //      this.countryInvalid     = true;
    //      this.countryError       = 'Select Country.';
    // }

    if(!checkValid)
    {
      ProviderClass.processing.style.display = 'none';
      return false;
    }

  	
    let userData = [];
    for(let k in providerData){
      let Key = k.replace("-", ":");
      let key_value = (providerData[k])?providerData[k].toString():'';
      // if(Key == 'phone_number'){
      //   let phone_number = this.updatePhoneNumber(providerData['custom-country'],providerData['phone_number']);
      //   userData.push({Name:Key,Value:phone_number});
      // }
      // if(Key == "birthdate"){
      //   let date_value = providerData['birthdate'];
      //   let con_date = date_value.split("-");
      //   //let Date_format = con_date[2]+'-'+con_date[1]+'-'+con_date[0];
      //   let Date_format = con_date[1]+'-'+con_date[2]+'-'+con_date[0];
      //   userData.push({Name:Key,Value:Date_format});
      // }else{
        userData.push({Name:Key,Value:key_value});
      // }
    }

    userData.push({Name:"custom:group",Value: 'Provider'},{Name:"email_verified",Value: 'true'},{Name:"phone_number_verified",Value: 'true'},{Name:"birthdate",Value: '01-01-1970'});
    let randomPassword = this.generatePassword();
    console.log(userData);
    
    ////////// Create User in Cognito ///////////////////

    // let paramstr = {
    //   UserPoolId: localStorage.getItem('userpull_id'),
    //   Username: providerData.email,
    //   DesiredDeliveryMediums: ['EMAIL'],
    //   ForceAliasCreation: false,
    //   TemporaryPassword: randomPassword,
    //   UserAttributes: userData
    // };
    
    // var cognitoidentityserviceprovider = new CognitoIdentityServiceProvider();
    // cognitoidentityserviceprovider.adminCreateUser(paramstr, function(err, data) {
    //   if (err) {
    //     console.log(err);
    //     ProviderClass.formInvalid = true;
    //     ProviderClass.processing.style.display = 'none';
    //   }
    //   else {
    //      let paramstr = {
    //           GroupName: 'Provider',
    //           UserPoolId: localStorage.getItem('userpull_id'),
    //           Username: data.User.Username
    //         };
    //         cognitoidentityserviceprovider.adminAddUserToGroup(paramstr, function(group_err, group_data) {
    //           if (group_err){
    //             ProviderClass.formInvalid = true;
    //             ProviderClass.processing.style.display = 'none'; 
    //           } 
    //           else{
    //             ProviderClass.formInvalid = false;
    //             createProviderModalClose.click();
    //             sessionStorage.setItem('flashSuccess', 'true');
    //             ProviderClass.processing.style.display = 'none';
    //             location.reload();
    //           }              
    //         });         
    //   }   
    // });
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      group: "Provider",
    };
    for(var k = 0; k < userData.length; k++) {
      params[userData[k]['Name']] = userData[k]['Value'];
    }
    console.log(params);

    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/createuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      console.log(response);
      if(response.status=="success") {
        ProviderClass.formInvalid = false;
        ProviderClass.formInvalidMsg = false;
        createProviderModalClose.click();
        sessionStorage.setItem('flashSuccess', 'true');
        // ProviderClass.processing.style.display = 'none';
        if(response.data!="") {
          let responsedata = JSON.parse(response.data);
          console.log(responsedata);
          if(responsedata.usersub) {
            sessionStorage.setItem('cpasspsub', responsedata.usersub);
          }
        }
        location.reload();
      }
      else if(response.message == "Email already exists!") {
        ProviderClass.formInvalidMsg = true;
        ProviderClass.processing.style.display = 'none';
      }
      else {
        ProviderClass.formInvalid = true;
        ProviderClass.processing.style.display = 'none';
      }
    },
    async (error: any) => {
      console.log(error);
      ProviderClass.formInvalid = true;
      ProviderClass.processing.style.display = 'none';
    });
  }


  generatePassword = (length=8) =>{
    let chars = "abcdefghijklmnop!@#$%^&*<>ABCDEFGHIJKLMNOP1234567890";
    let pass = "";
    for (let x = 0; x < 8; x++) {
        let i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
    }
    if(pass.match("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")){
      return pass;
    }
    else{
     this.generatePassword();
    }
  }

  updatePhoneNumber = ( country_val,phone_number ) => {
    let country_dial_code = "";
    let country_code = "";
    if(country_val)
    {
      for(let key in this.country_list_code){
        if(this.country_list_code[key]['name'] == country_val){
          country_dial_code = this.country_list_code[key]['dial_code'];
          country_code = this.country_list_code[key]['code'];
          break;
        }
      }
      return country_dial_code+phone_number;
    }
    else{
      return phone_number;
    }
  }

  removeSession = () =>{
    if(sessionStorage.getItem('flashSuccess')){
      sessionStorage.removeItem('flashSuccess');
    }
  }

  resetProviderForm = () =>{

    this.formInvalid = false;
    this.formInvalidMsg = false;

    this.givenNameInvalid = false;
    this.givenNameError   = '';

    this.middleNameInvalid =  false;
    this.middleNameError   = '';

    this.familyNameInvalid = false;
    this.familyNameError   = '';

    this.emailInvalid      = false;
    this.emailError        = '';

    this.phoneNumberInvalid = false;
    this.phoneNumberError   = ''; 
    
    this.birthdateInvalid   = false;
    this.birthdateError     = ''; 

    this.addressInvalid     = false;
    this.addressError       = '';

    this.genderInvalid      = false;
    this.genderError        = '';

    this.countryInvalid     = false;
    this.countryError       = '';

  }

  validatePasswordForm = ( passwordData ) =>{
    let PasswordClass = this;
    PasswordClass.formInvalids = false;
    PasswordClass.formInvalidMsgs = false;
    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(passwordData.new_password)) {
      if(passwordData.new_password == passwordData.confirm_password) {
        console.log(passwordData);
        PasswordClass.screen.style.display = 'block';
        let params = {
          userpull_id: localStorage.getItem('userpull_id'),
          sub: passwordData.sub,
          password: passwordData.new_password
        };
        this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/changepassword', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          console.log(response);
          PasswordClass.screen.style.display = 'none';
          if(response.status == "success") {
            alert("Password changed successfully");
          }
          else {
            alert("Password could not be changed");
          }
        },
        async (error: any) => {
          Auth.signOut();
                  localStorage.removeItem('AUTH');
                  localStorage.removeItem('AUTH-LIFE');
                  localStorage.removeItem('AUTH-PROFILE-PIC');
                  localStorage.removeItem('USER-DETAILS');
                  //PatientClass.processing.style.display = 'none';
                  PasswordClass.screen.style.display = 'none';
                  PasswordClass.router.navigateByUrl('/sign-in');
        });
      }
      else {
        console.log('Error');
        PasswordClass.formInvalids = true;
        PasswordClass.formInvalidMsgs = false;
      }
    }
    else {
      console.log('Error');
      PasswordClass.formInvalidMsgs = true;
      PasswordClass.formInvalids = false;
    }
  }

  unassignPatients = (provider, disablestat) => {
    var confirmMsg = "";
    var disableparam = "";
    if(disablestat) {
      disableparam = "False";
      confirmMsg = "Are you sure you would like to unenroll all patients from this provider and disable the provider?"
    }
    else {
      disableparam = "True";
      confirmMsg = "Are you sure you would like to unenroll all patients from this provider?"
    }
    if(confirm(confirmMsg)) {
      let unassignClass = this;
      unassignClass.screen.style.display = 'block';
      let params = {
        userpull_id: localStorage.getItem('userpull_id'),
        provider: provider,
        enabled: disableparam
      };
      this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/unenrollpatients', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          console.log(response);
          unassignClass.screen.style.display = 'none';
          if(response.status == "success") {
            alert("All patients unenrolled successfully");
            location.reload();
          }
          else {
            alert("Could not unenroll all patients");
          }
        },
        async (error: any) => {
          alert("Could not unenroll all patients");
          unassignClass.screen.style.display = 'none';
          console.log(error);
          // location.reload();
        });
    }
  }

  enableprovider = (provider) => {
    this.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      group: "Provider",
      sub: provider['sub'],
      enabled: "True"
    };
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/updateuser', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
        .subscribe((response: any) => {
          alert("Successfully updated provider");
          location.reload();
        },
        async (error: any) => {
          alert("Could not enable provider");
          this.screen.style.display = 'none';
          console.log(error);
        });
  }

  npidefaultforprcticeclick = (provider) => {
    console.log("npidefaultforprcticeclick");
    console.log(provider);

    let usersub = ""

    if (localStorage.getItem('USER-DETAILS')) {
      let userDetails = JSON.parse(localStorage.getItem('USER-DETAILS'));

      if(userDetails.sub){
        usersub = userDetails.sub;
      }
    }

    this.screen.style.display = 'block';
    let params = {
      userpull_id: localStorage.getItem('userpull_id'),
      usersub: usersub,
      providersub: provider['sub'],
      firstName: provider['firstName'],
      lastName: provider['lastName'],
      npi: provider['npi'],
      npi_pin: provider['npi_pin']
    };
    this.httpClient.post('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/npidefaultforpractice', params, { headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
    .subscribe((response: any) => {
      this.screen.style.display = 'none';
      if(response.status == "success") {
        alert("Successfully updated provider");
        location.reload();
      }
      else {
        alert("Could not save npi");
      }
    },
    async (error: any) => {
      alert("Could not enable provider");
      this.screen.style.display = 'none';
      console.log(error);
    });
  }

}

import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  upmessage;
  constructor() { }

  uploadFile(file, s3bucket, folderName, newFileName) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: 'AKIAQPNC7OTHCFBFD5UM',
        secretAccessKey: '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',
        region: 'us-east-1'
      }
    );
    const params = {
      Bucket: s3bucket,
      Key: folderName + newFileName,
      Body: file,
      ACL: 'private',
      ContentType: contentType
    };
    // bucket.upload(params, function (err, data) {
    //   if (err) {
    //       console.log('There was an error uploading your file: ', err);
    //       return false;
    //   }
    //   console.log('Successfully uploaded file.', data);
    //   return true;
    // });
    //for upload progress   
    bucket.upload(params).on('httpUploadProgress', function (evt) {
      console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
      this.upmessage = 'Uploading '+evt.loaded + ' of ' + evt.total + ' Bytes';
    }).send(function (err, data) {
      if (err) {
        this.upmessage = 'There was an error uploading your file: '+err;
        console.log('There was an error uploading your file: ', err);
        return false;
      }
      this.upmessage = '';
      console.log('Successfully uploaded file.', data);
      alert('Successfully uploaded file.');
      return true;
    });
  }
}

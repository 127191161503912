import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-patient-symptom',
  templateUrl: './patient-symptom.component.html',
  styleUrls: ['./patient-symptom.component.css']
})
export class PatientSymptomComponent implements OnInit {

  body: HTMLElement = document.getElementById('body') as HTMLElement;
  screen: HTMLElement = document.getElementById('screen') as HTMLElement;
  processing: HTMLElement = document.getElementById('cover') as HTMLElement;

  practiceId: any;
  subId: any;
  readingId: any;

  constructor(private route: ActivatedRoute, private router: Router, private httpClient: HttpClient) { }

  ngOnInit() {
    // this.body.classList.add('login-page');
    this.practiceId = atob(this.route.snapshot.paramMap.get('practice'));
    this.subId = atob(this.route.snapshot.paramMap.get('sub'));
    this.readingId = atob(this.route.snapshot.paramMap.get('reading'));
  }

}

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_8whlhJLl8",
    "aws_user_pools_web_client_id": "7oa40s6o6on4ckbvkif7185vd9",
    "oauth": {}
};


export default awsmobile;

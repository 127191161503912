import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { AWS } from '@aws-amplify/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit {

  body: HTMLElement = document.getElementById('body') as HTMLElement;
  screen: HTMLElement = document.getElementById('screen') as HTMLElement;
  processing: HTMLElement = document.getElementById('cover') as HTMLElement;

  private cognitoUser:any;

  loginEmail: string = '';
  loginPassword: string = '';

  emailInvalid: boolean = false;
  emailError: string = '';
  passwordInvalid: boolean = false;
  passwordError: string = '';
  authFailed: boolean = false;
  authError: string = '';
  forgotPasswordEmailInvalid: boolean = false;
  forgotPasswordEmailError: string = '';
  forgotPasswordEmailValid: boolean = false;
  forgotPasswordEmailValidMessage: string = '';
  newPasswordInvalid: boolean = false;
  newPasswordError: string = '';
  changePasswordInvalid: boolean = false;
  changePasswordError: string = '';
  changeConfPasswordInvalid: boolean = false;
  changeConfPasswordError: string = '';

  verificationCode: string = '';
  verificationCodeInvalid: boolean = false;
  verificationCodeError: string = '';
  newPassword: string = '';
  newChangePassword: string = '';
  newChangeConfPassword: string = '';
  forgotPasswordError: boolean = false;
  forgotPasswordSuccess: boolean = false;
  forgotPasswordMessage: string = '';
  passText: string = 'password';
  newPassText: string = 'password';
  changePassText: string = 'password';
  changeConfPassText: string = 'password';
  changePassSuccess: boolean = false;
  changePassMessage: string = '';
  changePassError: boolean = false;
  changePassErrorMessage: string = '';

  changeConVerifyCode: string = '';
  changeConVerifyCodeInvalid: boolean = false;
  changeConVerifyCodeError: string = '';

  forChangePass : boolean = false;

  constructor(private router: Router, private httpClient: HttpClient) {
    if (localStorage.getItem('AUTH')) {
      let authData = JSON.parse(localStorage.getItem('AUTH'));
      let userGroup = authData.group;
      if (userGroup === 'Provider'){
        this.router.navigateByUrl('/dashboard');
      }else{
        this.router.navigateByUrl('/admin');
      }
    } else {
      if (this.router.url === '/' || this.router.url === '') {
        this.router.navigateByUrl('/sign-in');
      }
    }
  }

  ngOnInit() {
    this.body.classList.add('login-page');
    this.body.style.overflow = 'hidden';
    //document.getElementById('particles').style.display = 'block';
    this.screen.removeAttribute('style');
    this.screen.style.display = 'none';

    sessionStorage.setItem('currentComponent', 'sign-in');
  }

  preventExecution = () => {
    let style = window.getComputedStyle(this.processing);
    if (style.display !== 'none' || this.processing.offsetParent) {
      return true;
    }

    return false;
  }

  handleEnter = (element: any, event: any) => {
    let ele: HTMLElement;
    if (event.key === 'Enter') {
      if (element.name === 'sign-in-email') {
        ele = document.getElementById('password') as HTMLElement;
        ele.focus();
      } else if (element.name === 'sign-in-password') {
        ele = document.getElementById('signInButton') as HTMLElement;
        ele.click();
      } else if (element.name === 'forgot-email') {
        ele = document.getElementById('forgotEmailSubmit') as HTMLElement;
        ele.click();
      } else if (element.name === 'forgot-code') {
        ele = document.getElementById('newPassword') as HTMLElement;
        ele.focus();
      } else if (element.name === 'forgot-password') {
        ele = document.getElementById('forgotPasswordSubmit') as HTMLElement;
        ele.click();
      }
    }
  }

  onKeyUp = (event1: any, event2: any) => {
    if (this.preventExecution()) return false;
    if (event2) {
      if (event2 === 'verificationCode') {
        this.verificationCode = event1.value;
          if(/^[0-9]{6,6}$/.test(this.verificationCode)){
            this.changeConVerifyCode = event1.value;
            this.verificationCodeInvalid = false;
            this.verificationCodeError = '';
          }else
          {
            this.verificationCodeInvalid = true;
            this.verificationCodeError = 'verification Code must be 6 digis and numbers.';
          }

      } else if (event2 === 'newPassword') {
        this.newPassword = event1.value;
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(this.newPassword)) {
          this.newPasswordInvalid = false;
          this.newPasswordError = '';
        } else {
          this.newPasswordInvalid = true;
          this.newPasswordError = 'Password minimum 8 characters which contain at least one lowercase letter  one uppercase letter  one numeric digit and one special character.';
        }

      } else if (event2 === 'newChangePassword') {
        this.newChangePassword = event1.value;
        if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(this.newChangePassword)) {
          this.changePasswordInvalid = false;
          this.changePasswordError = '';
        } else {
          this.changePasswordInvalid = true;
          this.changePasswordError = 'Password minimum 8 characters which contain at least one lowercase letter one uppercase letter one numeric digit and one special character.';
        }
      } else if (event2 === 'newChangeConfPassword') {
        this.newChangeConfPassword = event1.value;
        if (this.newChangePassword === this.newChangeConfPassword) {
          this.changeConfPasswordInvalid = false;
          this.changeConfPasswordError = '';
        } else {
          this.changeConfPasswordInvalid = true;
          this.changeConfPasswordError = 'Password mismatch!';
        }
      }

    } else {
      if (event1.value !== '') {
        if (event1.type === 'email') {
          this.loginEmail = event1.value;
          this.emailInvalid = false;
          this.emailError = '';
        } else if (event1.type === 'password') {
          this.loginPassword = event1.value;
          this.passwordInvalid = false;
          this.passwordError = '';
        }
      }
    }
  }

 checkConfCode = (event1: any, event2: any) => {
      if(event2 == 'changeConVerifyCode')
      {
        this.changeConVerifyCode = event1.value;
        if(/^[0-9]{6,6}$/.test(this.changeConVerifyCode)){
          this.changeConVerifyCode = event1.value;
          this.changeConVerifyCodeInvalid = false;
          this.changeConVerifyCodeError = '';
        }else
        {
          this.changeConVerifyCodeInvalid = true;
          this.changeConVerifyCodeError = 'verification Code must be 6 digis and numbers.';
        }
      }
 } 

  resetModal = () => {
    this.forgotPasswordEmailInvalid = false;
    this.forgotPasswordEmailError = '';
    this.forgotPasswordEmailValid = false;
    this.forgotPasswordEmailValidMessage = '';
    this.newPasswordInvalid = false;
    this.newPasswordError = '';
    this.changePasswordInvalid = false;
    this.changePasswordError = '';
    this.changeConfPasswordInvalid = false;
    this.changeConfPasswordError = '';
    this.verificationCode = '';
    this.newPassword = '';
    this.newChangePassword = '';
    this.newChangeConfPassword = '';
    this.changeConVerifyCode = '';
    this.changeConVerifyCodeInvalid = false;
    this.changeConVerifyCodeError = '';
    this.verificationCodeInvalid= false;
    this.verificationCodeError= '';

    let ele: HTMLElement = document.getElementById('newPassLockUnlock') as HTMLElement;
    if (ele && ele.offsetParent) {
      ele.classList.remove('fa-unlock');
      ele.classList.add('fa-lock');
      this.newPassText = 'password';
    }
    ele = document.getElementById('changePassLockUnlock') as HTMLElement;
    if (ele && ele.offsetParent) {
      ele.classList.remove('fa-unlock');
      ele.classList.add('fa-lock');
      this.changePassText = 'password';
    }
    ele = document.getElementById('changeConfPassLockUnlock') as HTMLElement;
    if (ele && ele.offsetParent) {
      ele.classList.remove('fa-unlock');
      ele.classList.add('fa-lock');
      this.changeConfPassText = 'password';
    }
  }

  titleCase = (string) => {
    let sentence = string.toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
  }

  validateSignIn = async (email, password) => {
    if (this.preventExecution()) return false;
    if (email.value === '') {
      this.emailInvalid = true;
      this.emailError = 'Enter your email.';
    } else {
      /*if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
        this.emailInvalid = false;
        this.emailError = '';
      } else {
        this.emailInvalid = true;
        this.emailError = 'Invalid email format!';
      }*/
    }

    if (password.value === '') {
      this.passwordInvalid = true;
      this.passwordError = 'Enter your password.';
    }

    if (!this.emailInvalid && !this.passwordInvalid) {
      this.processing.style.display = 'block';
      this.newPasswordInvalid = false;
      this.newPasswordError = '';
      this.changePasswordInvalid = false;
      this.changePasswordError = '';
      this.changeConfPasswordInvalid = false;
      this.changeConfPasswordError = '';
      this.forgotPasswordSuccess = false;
      this.forgotPasswordError = false;
      this.authFailed = false;

      this.forChangePass = false;
      
      await Auth.signIn(email.value, password.value)
      .then(async (user) => {
        // console.log(user);
        let groups = ['Provider', 'Superadmin', 'SystemAdmin'];
        let group;
        let checkGroup;
          
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                group = user.challengeParam.userAttributes['custom:group'];              
                checkGroup = groups.find((ele) => {
                  return ele === group;
                });
                if (checkGroup) {
                  this.cognitoUser = user; 
                  this.forChangePass = true;
                  //localStorage.setItem('CHANGE_PASSWORD_EMAIL', email.value);          
                  this.processing.style.display = 'none';
                  let changePasswordModal: HTMLElement = document.getElementById('changePassword') as HTMLElement;
                  changePasswordModal.click();

                } else {
                  await Auth.signOut({
                    global: true
                  });
                  this.processing.style.display = 'none';
                  this.authError = 'Invalid credential!';
                  this.authFailed = true;
                }

          } 
          else
          {
            group = user.attributes['custom:group'];
            checkGroup = groups.find((ele) => {
              return ele === group;
            });
            
          if (checkGroup) {
            let token = user.signInUserSession.idToken.jwtToken;
            localStorage.setItem('AUTH-TOKEN', token);
            let date = new Date();
            localStorage.setItem('AUTH-LIFE', date.getTime().toString());
            localStorage.setItem('USER-DETAILS', JSON.stringify(user.attributes));

            let userObj = { 
              id: user.username,
              group: user.attributes['custom:group'],
              name: user.attributes['given_name'],
              first_name: user.attributes['given_name'],
              middle_name: user.attributes['middle_name'],
              last_name: user.attributes['given_name'],
              email: user.attributes['email'],
              mobile: user.attributes['phone_number'],
              dob: user.attributes['birthdate'],
              created: '',
              username: user.attributes['given_name']
            };
            localStorage.setItem('AUTH', JSON.stringify(userObj));
            localStorage.setItem('user_sub', user.attributes['sub']);

            if(user.attributes['custom:userpull_id'] != "" && user.attributes['custom:userpull_id'] != undefined){
              localStorage.setItem('userpull_id', user.attributes['custom:userpull_id']);
            }else{
              localStorage.setItem('userpull_id', "");
            }

            if (checkGroup === 'Provider'){

              let params:any;
              // console.log(user.attributes['sub']);
              params = new HttpParams({
                fromObject: {
                  userpull_id: user.attributes['custom:userpull_id'],
                  userauth: token
                }
              });
              this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/webuserlist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
              .subscribe((response: any) => {
                if (response.status === "success") {
                  localStorage.setItem('POOL-USERS', response.data);
                  let responsedata = JSON.parse(response.data);
                  console.log(responsedata);
                  if (responsedata.length > 0) {
                    for (var j = 0; j < responsedata.length; j++) {
                      if(responsedata[j]['email'] && responsedata[j]['email'] == user.attributes.email) {
                        if(responsedata[j]['custom:manage_users'] && responsedata[j]['custom:manage_users'] == "yes"){
                          localStorage.setItem('MANAGE-USER', 'yes');
                        }
                        else {
                          localStorage.setItem('MANAGE-USER', 'no');
                        }
                        console.log(responsedata[j]['custom:provider_sub']);
                        if(responsedata[j]['custom:provider_sub'] && responsedata[j]['custom:provider_sub'] != ""){
                          sessionStorage.setItem('Review_Provider', responsedata[j]['custom:provider_sub']);
                        }
                        else if(sessionStorage.getItem('Review_Provider')) {
                          sessionStorage.removeItem('Review_Provider');
                        }
                        console.log(sessionStorage.getItem('Review_Provider'));
                      }
                    }
                  }
                  if(response.pubnubdata) {
                    sessionStorage.setItem('pubnubcred', response.pubnubdata);
                  }
                  if(response.LanguageList) {
                    localStorage.setItem('LanguageList', response.LanguageList);
                  }
                }
              },
              async (error: any) => {
                console.log(error);
              });

              params = new HttpParams({
                fromObject: {
                  userpull_id: user.attributes['custom:userpull_id']
                }
              });
              this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getreviewtemplatelist', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
              .subscribe((responsetemp: any) => {
                if (responsetemp.status === "success") {
                  sessionStorage.setItem('reviewtemplate', responsetemp.data);
                }
              },
              async (error: any) => {
                console.log(error);
              });

              params = new HttpParams({
                fromObject: {
                  userpull_id: user.attributes['custom:userpull_id']
                }
              });
              this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/gettaskitems', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
              .subscribe((responsetemp: any) => {
                console.log(responsetemp);
                if (responsetemp.status === "success") {
                  sessionStorage.setItem('taskitems', responsetemp.data);
                }
              },
              async (error: any) => {
                console.log(error);
              });

              params = new HttpParams({
                fromObject: {
                  userpull_id: user.attributes['custom:userpull_id']
                }
              });
              this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/rdssync', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
              .subscribe((response: any) => {
                  var Mask = "yes"

                  if(user.attributes['custom:mask_non_admin'] != "" && user.attributes['custom:mask_non_admin'] != undefined && user.attributes['custom:mask_non_admin'].toLowerCase() == 'yes'){
                    Mask = 'yes';
                  }else if(user.attributes['custom:mask_non_admin'] != "" && user.attributes['custom:mask_non_admin'] != undefined && user.attributes['custom:mask_non_admin'].toLowerCase() == 'no'){
                    Mask = 'no';
                  }

                  localStorage.setItem('Mask', Mask);
                  
                  this.processing.style.display = 'none';
                  this.body.classList.remove('login-page');
                  this.router.navigateByUrl('/dashboard'); 
                },
                async (error: any) => {
                  // await Auth.signOut({
                  //   global: true
                  // });
                  // this.processing.style.display = 'none';
                  // this.authError = 'An internal error occurred!';
                  // this.authFailed = true;

                  var Mask = "yes"

                  if(user.attributes['custom:mask_non_admin'] != "" && user.attributes['custom:mask_non_admin'] != undefined && user.attributes['custom:mask_non_admin'].toLowerCase() == 'yes'){
                    Mask = 'yes';
                  }else if(user.attributes['custom:mask_non_admin'] != "" && user.attributes['custom:mask_non_admin'] != undefined && user.attributes['custom:mask_non_admin'].toLowerCase() == 'no'){
                    Mask = 'no';
                  }

                  localStorage.setItem('Mask', Mask);

                  this.processing.style.display = 'none';
                  this.body.classList.remove('login-page');
                  this.router.navigateByUrl('/dashboard'); 
                });
            }else if(checkGroup === 'Superadmin' || checkGroup === 'SystemAdmin'){
              localStorage.setItem('MANAGE-USER', 'yes');
              let params:any;
              params = new HttpParams({
                fromObject: {
                  sub: user.username,
                  group: user.attributes['custom:group']
                }
              });
              this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getallpractice', { params, headers: new HttpHeaders({ 'Authorization': localStorage.getItem('AUTH-TOKEN') }) })
              .subscribe((response: any) => {
                if (response.status === "success") {
                  localStorage.setItem('ALL-POOL', response.data);

                  console.log("mask", response.mask)
                  if(response.mask){
                    localStorage.setItem('Mask', 'yes');
                  }else{
                    localStorage.setItem('Mask', 'no');
                  }

                  this.processing.style.display = 'none';
                  this.body.classList.remove('login-page');
                  this.router.navigateByUrl('/admin'); 
                }
              },
              async (error: any) => {
                await Auth.signOut({
                  global: true
                });
                this.processing.style.display = 'none';
                this.authError = 'An internal error occurred!';
                this.authFailed = true;
              });

            }
          } else {
            await Auth.signOut({
              global: true
            });
            this.processing.style.display = 'none';
            this.authError = 'Invalid credential!';
            this.authFailed = true;
          }
        }
      })
      .catch(async(err) => {
        if(err.code && err.code == "PasswordResetRequiredException")
        {
            await Auth.forgotPassword(email.value)
                .then((data) => {
                  localStorage.setItem('CHANGE_PASSWORD_EMAIL', email.value);
                  this.processing.style.display = 'none';
                  let changePasswordModal: HTMLElement = document.getElementById('changePassword') as HTMLElement;
                  changePasswordModal.click();
                })
                .catch(err => {
                  this.processing.style.display = 'none';
                  this.forgotPasswordEmailInvalid = true;
                  this.forgotPasswordEmailValidMessage = '';
                  if (err.code === 'NotAuthorizedException') {
                    this.forgotPasswordEmailError = 'No password set yet for your account! Please try signing in with a new password.';
                  } else if (err.code === 'LimitExceededException') {
                    this.forgotPasswordEmailError = err.message;
                  } else {
                    this.forgotPasswordEmailError = 'Invalid email or email does not exist!';
                  }
                });

            
        }
        else
        {
          this.processing.style.display = 'none';
          this.authError = 'Invalid credential!';
          this.authFailed = true;
        }
      });
    }
  }

  forgotPassword = async (email) => {
    if (this.preventExecution()) return false;
    if (email.value !== '') {
      this.processing.style.display = 'block';
      await Auth.forgotPassword(email.value)
        .then((data) => {
          this.processing.style.display = 'none';
          localStorage.setItem('FORGOT_PASSWORD_EMAIL', email.value);
          this.forgotPasswordEmailValid = true;
          this.forgotPasswordEmailError = '';
          this.forgotPasswordEmailValidMessage = 'An verification code has been sent to your email.';
        })
        .catch(err => {
          this.processing.style.display = 'none';
          this.forgotPasswordEmailInvalid = true;
          this.forgotPasswordEmailValidMessage = '';
          if (err.code === 'NotAuthorizedException') {
            this.forgotPasswordEmailError = 'No password set yet for your account! Please try signing in with a new password.';
          } else if (err.code === 'LimitExceededException') {
            this.forgotPasswordEmailError = err.message;
          } else {
            this.forgotPasswordEmailError = 'Invalid email or email does not exist!';
          }
        });
    } else {
      this.forgotPasswordEmailInvalid = true;
      this.forgotPasswordEmailError = 'Enter your email!';
    }
  }

  changePassword = async () => {
    if (this.preventExecution()) return false;
    let email = localStorage.getItem('FORGOT_PASSWORD_EMAIL');
    let code = this.verificationCode;
    let pass = this.newPassword;
    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(pass) && /^[0-9]{6,6}$/.test(code)) {

      this.newPasswordInvalid = false;
      this.newPasswordError = '';
      this.changePasswordInvalid = false;
      this.changePasswordError = '';
      this.changeConfPasswordInvalid = false;
      this.changeConfPasswordError = '';
      this.forgotPasswordSuccess = false;
      this.forgotPasswordError = false;
      this.authFailed = false;
      this.verificationCodeInvalid= false;
      this.verificationCodeError= '';

      let modalClose: HTMLElement = document.getElementById('forgotPasswordModal') as HTMLElement;
      this.processing.style.display = 'block';
      await Auth.forgotPasswordSubmit(email, code, pass)
        .then(data => {
          this.processing.style.display = 'none';
          this.forgotPasswordSuccess = true;
          this.forgotPasswordMessage = 'Password changed successfully.';
          modalClose.click();
          this.router.navigateByUrl('/sign-in');
        })
        .catch(err => {
          this.processing.style.display = 'none';
          this.forgotPasswordError = true;
          this.forgotPasswordMessage = 'Password not changed! An error occurred, try later.';
          modalClose.click();
          this.router.navigateByUrl('/sign-in');
        });
    } else {
      this.newPasswordInvalid = true;
      this.verificationCodeInvalid = true;
      this.newPasswordError = 'Password minimum 8 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character.';
      this.verificationCodeError = 'verification Code must be 6 digis and numbers.';
    }
  }

  setPassword = async() => {
    if (this.preventExecution()) return false;
    let go = true;
    let password = this.newChangePassword;
    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(password)) {
      this.changePasswordInvalid = false;
      this.changePasswordError = '';
    } else {
      go = false;
      this.changePasswordInvalid = true;
      this.changePasswordError = 'Password minimum 8 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character.';
    }
    if (this.newChangePassword === this.newChangeConfPassword) {
      this.changeConfPasswordInvalid = false;
      this.changeConfPasswordError = '';
    } else {
      go = false;
      this.changeConfPasswordInvalid = true;
      this.changeConfPasswordError = 'Password mismatch!';
    }
    if(/^[0-9]{6,}$/.test(this.changeConVerifyCode)){
      this.changeConVerifyCodeInvalid = false;
      this.changeConVerifyCodeError = '';
    }else
    {
      go = false;
      this.changeConVerifyCodeInvalid = true;
      this.changeConVerifyCodeError = 'verification Code must be 6 digis and numbers.';
    }

    if (go) {
      let email = localStorage.getItem('CHANGE_PASSWORD_EMAIL');
      this.processing.style.display = 'block';
      let changePasswordModalClose: HTMLElement = document.getElementById('changePasswordModal') as HTMLElement;
      await Auth.forgotPasswordSubmit(email, this.changeConVerifyCode, this.newChangeConfPassword)
      .then(async(user) => {
        await Auth.signOut({
          global: true
        });
        this.processing.style.display = 'none';
        this.changePassSuccess = true;
        this.changePassMessage = 'Password changed successfully.';
        this.loginEmail = '';
        this.loginPassword = '';
        localStorage.removeItem('CHANGE_PASSWORD_EMAIL');
        changePasswordModalClose.click();
      })
      .catch(async(err) => {
        await Auth.signOut({
          global: true
        });
        this.processing.style.display = 'none';
        this.changePassError = true;
        this.changePassErrorMessage = 'An error occurred to change password, try later.';
        changePasswordModalClose.click();
      });
    } else {
      return false;
    }
  }

  completeNewPassword = async() => {
    if (this.preventExecution()) return false;
    let go = true;
    let password = this.newChangePassword;
    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(password)) {
      this.changePasswordInvalid = false;
      this.changePasswordError = '';
    } else {
      go = false;
      this.changePasswordInvalid = true;
      this.changePasswordError = 'Password minimum 8 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character.';
    }
    if (this.newChangePassword === this.newChangeConfPassword) {
      this.changeConfPasswordInvalid = false;
      this.changeConfPasswordError = '';
    } else {
      go = false;
      this.changeConfPasswordInvalid = true;
      this.changeConfPasswordError = 'Password mismatch!';
    }

    if (go) {
      let email = localStorage.getItem('CHANGE_PASSWORD_EMAIL');
      this.processing.style.display = 'block';
      let changePasswordModalClose: HTMLElement = document.getElementById('changePasswordModal') as HTMLElement;
      //await Auth.forgotPasswordSubmit(email, this.changeConVerifyCode, this.newChangeConfPassword)
      await Auth.completeNewPassword(this.cognitoUser, this.newChangePassword, {})
      .then(async(user) => {
        await Auth.signOut({
          global: true
        });
        this.processing.style.display = 'none';
        this.changePassSuccess = true;
        this.changePassMessage = 'Password changed successfully.';
        this.loginEmail = '';
        this.loginPassword = '';
        this.forChangePass = false;
        this.cognitoUser = ''
        localStorage.removeItem('CHANGE_PASSWORD_EMAIL');
        changePasswordModalClose.click();
      })
      .catch(async(err) => {
        await Auth.signOut({
          global: true
        });
        this.processing.style.display = 'none';
        this.changePassError = true;
        this.changePassErrorMessage = 'An error occurred to change password, try later.';

        changePasswordModalClose.click();
      });
    } else {
      return false;
    }
  }

  passwordLockUnlock = (element: any, type: string) => {
    let ele: HTMLElement;
    if (element.name === 'sign-in-password') {
      ele = document.getElementById('passLockUnlock') as HTMLElement;
    } else if (element.name === 'forgot-password') {
      ele = document.getElementById('newPassLockUnlock') as HTMLElement;
    } else if (element.name === 'change-password') {
      ele = document.getElementById('changePassLockUnlock') as HTMLElement;
    } else if (element.name === 'change-password-conf') {
      ele = document.getElementById('changeConfPassLockUnlock') as HTMLElement;
    }

    if (type === 'login' || type === 'forgot') {
      if (element.type === 'password') {
        ele.classList.remove('fa-lock');
        ele.classList.add('fa-unlock');
        this.passText = 'text';
        this.newPassText = 'text';
      } else {
        ele.classList.remove('fa-unlock');
        ele.classList.add('fa-lock');
        this.passText = 'password';
        this.newPassText = 'password';
      }
    } else if (type === 'changepass') {
      if (element.type === 'password') {
        ele.classList.remove('fa-lock');
        ele.classList.add('fa-unlock');
        this.changePassText = 'text';
      } else {
        ele.classList.remove('fa-unlock');
        ele.classList.add('fa-lock');
        this.changePassText = 'password';
      }
    } else if (type === 'changeconfpass') {
      if (element.type === 'password') {
        ele.classList.remove('fa-lock');
        ele.classList.add('fa-unlock');
        this.changeConfPassText = 'text';
      } else {
        ele.classList.remove('fa-unlock');
        ele.classList.add('fa-lock');
        this.changeConfPassText = 'password';
      }
    }

  }

}
import { Component } from '@angular/core';
import defaultLanguage from "./../assets/i18n/en.json";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private translate: TranslateService) {
    translate.setTranslation('en', defaultLanguage);
    translate.setDefaultLang('en');
    
    if (!localStorage.getItem('myLocale') || localStorage.getItem('myLocale') === 'null') {
      localStorage.setItem('myLocale', 'en');
    } else {
      translate.setDefaultLang(localStorage.getItem('myLocale'));
    }
  }

  ngOnInit() {
  }
  
}

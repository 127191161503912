import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  body: HTMLElement = document.getElementById('body') as HTMLElement;
  screen: HTMLElement = document.getElementById('screen') as HTMLElement;
  processing: HTMLElement = document.getElementById('cover') as HTMLElement;

  isValidated: boolean = false;
  errordob: string = '';

  needOtpValidated: boolean = false;
  otpnum: any;
  errorotp: string = '';

  practiceId: any;
  subId: any;

  userDetails: any;

  syncing: boolean = false;
  
  alertSettingDeviceCount = 0;

  //Graph
  isPatientDeviceLineChartData: boolean = false;
  isPatientGlocoseLineChartData: boolean = false;

  patientLineChartStr = ""

  allGraphDevice = []
  allUploadHistory = []

  patientLineChartData: any = [];
  patientLinechartLabels: any = [];
  patientLinechartColors = [];
  patientLinechartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: false
        }
      }],
      xAxes:[{
        ticks:{
            display: true,
            autoSkip: true,
            maxTicksLimit: 10
        }
    }]
    }
  };

  patientLineFAChartData: any = [];
  patientLineFAchartLabels: any = [];
  patientLineFAchartColors = [];

  patientLineAMChartData: any = [];
  patientLineAMchartLabels: any = [];
  patientLineAMchartColors = [];

  patientLineNTChartData: any = [];
  patientLineNTchartLabels: any = [];
  patientLineNTchartColors = [];

  constructor(private route: ActivatedRoute, private router: Router, private httpClient: HttpClient) { }

  formatDateCustom = (date) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    const days = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat'
    ]
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes();
    
    var time = "AM";
    if(hour >= 12) {
      time = "PM";
      if(hour > 12) {
        hour = hour - 12;
      }
    }
    var strhour = ''+ hour;
    if(hour == 0) {
      strhour = '12';
    }
    else if(hour < 10){
      strhour = '0' + hour;
    }
    var strminute = '' + minute;
    if(minute < 10){
      strminute = '0' + minute;
    }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    // return [year, month, day].join('-');
    return strhour+":"+strminute+" "+time+" "+days[d.getDay()]+" "+day+" "+months[d.getMonth()]+" "+year;
  }

  formatTime = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes();
      var strhour = ''+ hours;
      var strminute = '' + minutes;
      if(hours < 10){
        strhour = '0' + hours;
      }
      if(minutes < 10){
        strminute = '0' + minutes;
      }

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-')+'T'+strhour+":"+strminute;
  }

  formatDateMDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  formatDateMSlash = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    //console.log(d.toString());
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('/');
  }

  validatePatientForm = (data) =>{
    if(data.dobdate == this.userDetails['birthdate_date']){
      this.isValidated = true;
      this.errordob = "";
    }
    else {
      this.errordob = "Invalid data provided";
    }
  }

  twilioEndPoint = "https://3xcxz84v68.execute-api.us-east-1.amazonaws.com";
  twilioEndPointApiKey = "2pdKyWDlxE6KxzVGV8Dsq5cDxcTVuWhZ3k5TCmYe";

  validatePatientOtpForm = (data) =>{
    this.errorotp = "";
    console.log(data);
    let params = {
      countryCode: this.otpnum[0],
      phoneNumber: this.otpnum[1],
      otp: data.otp
    };
    console.log(params);
    this.httpClient.post(this.twilioEndPoint + '/stage/twilio/VerifyOTP', params, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }), responseType: 'text' })
      .subscribe( (response: String) => {
        console.log(response);
        if(response == "OTP Verification Done Successfully!"){
          this.isValidated = true;
          this.errorotp = "";
        }
        else{
          this.errorotp = response.toString();
        }
      },
      async (error: any) => {
        console.log(error);
        this.errorotp = "Some error occurred";
      });
  }

  resendOtp = () => {
    let params = {
      countryCode: this.otpnum[0],
      phoneNumber: this.otpnum[1],
    };
    console.log(params);
    this.httpClient.post(this.twilioEndPoint + '/stage/twilio/SendOTP', params, { headers: new HttpHeaders({ 'x-api-key': this.twilioEndPointApiKey }), responseType: 'text' })
      .subscribe( (response: String) => {
        console.log(response);
        if(response == "OTP Verification Sent Successfully!"){
          this.errorotp = "OTP Sent Successfully!";
        }
        else{
          this.errorotp = response.toString();
        }
      },
      async (error: any) => {
        console.log(error);
        this.errorotp = "Some error occurred";
      });
  }

  partDataProcess = (responsedata) => {
    let j = 0;
    let startk = responsedata[j].TransmissionDate.length;
    if(responsedata[j].PartDate) {
      for (var z = 0; z < responsedata[j].PartDate.length; z++) {
        responsedata[j].TransmissionDate.push(responsedata[j].PartDate[z]);
        responsedata[j].UploadHistory.push(responsedata[j].UploadHistoryPart[z]);
      }
    }
    for (var k = startk; k < responsedata[j].TransmissionDate.length; k++) {
      console.log(k);
      console.log(responsedata[j].TransmissionDate[k]);
      if(responsedata[j].TransmissionDate[k].StartDate && responsedata[j].TransmissionDate[k].EndDate) {
        // console.log(responsedata[j].TransmissionDate[k].StartDate);
        // console.log(responsedata[j].TransmissionDate[k].StartDate + " UTC");
        // console.log(this.formatDateMSlash(responsedata[j].TransmissionDate[k].StartDate + " UTC"));
        // console.log(responsedata[j].TransmissionDate[k].EndDate);
        // console.log(responsedata[j].TransmissionDate[k].EndDate + " UTC");
        // console.log(this.formatDateMSlash(responsedata[j].TransmissionDate[k].EndDate + " UTC"));
        responsedata[j].TransmissionDate[k].StartDateUTC = responsedata[j].TransmissionDate[k].StartDate.replaceAll('-','/');
        responsedata[j].TransmissionDate[k].EndDateUTC = responsedata[j].TransmissionDate[k].EndDate.replaceAll('-','/');
        responsedata[j].TransmissionDate[k].StartDateActual = this.formatTime(responsedata[j].TransmissionDate[k].StartDate.replaceAll('-','/') + " UTC");
        responsedata[j].TransmissionDate[k].EndDateActual = this.formatTime(responsedata[j].TransmissionDate[k].EndDate.replaceAll('-','/') + " UTC");
        responsedata[j].TransmissionDate[k].StartDate = this.formatDateMSlash(responsedata[j].TransmissionDate[k].StartDate.replaceAll('-','/') + " UTC");
        responsedata[j].TransmissionDate[k].EndDate = this.formatDateMSlash(responsedata[j].TransmissionDate[k].EndDate.replaceAll('-','/') + " UTC");
        responsedata[j].TransmissionDate[k].StartDateStr = responsedata[j].TransmissionDate[k].StartDate.replace('/','').replace('/','');
      }
      if(responsedata[j].Alerts && responsedata[j].Alerts[k]) {
        for (var i = 0; i < responsedata[j].Alerts[k].length; i++) {
          
        }
        responsedata[j].TransmissionDate[k].Alerts = responsedata[j].Alerts[k];
      }
      if(responsedata[j].DevicesUploadCount && responsedata[j].DevicesUploadCount[k - startk]) {
        responsedata[j].TransmissionDate[k].DevicesUploadCount = responsedata[j].DevicesUploadCount[k - startk].toString();
      }
      if(responsedata[j].DevicesUploads && responsedata[j].DevicesUploads[k - startk]) {
        responsedata[j].TransmissionDate[k].DevicesUploads = responsedata[j].DevicesUploads[k - startk];
      }
      if(responsedata[j].Devices && responsedata[j].Devices[k]) {
        responsedata[j].TransmissionDate[k].Devices = responsedata[j].Devices[k];
      }
      if(responsedata[j].InRange && responsedata[j].InRange[k]) {
        responsedata[j].TransmissionDate[k].InRange = responsedata[j].InRange[k].length;
      }
      if(responsedata[j].UploadHistory && responsedata[j].UploadHistory[k]) {
        responsedata[j].devicesetting[k] = {};
        for (var i = 0; i < responsedata[j].UploadHistory[k].length; i++) {
          if(responsedata[j].UploadHistory[k][i]) {  // && responsedata[j].UploadHistory[k][i].length > 0
            responsedata[j].UploadHistory[k][i]['timestamp'] = this.formatDateCustom(responsedata[j].UploadHistory[k][i]['timestamp'].replaceAll('-','/') + " UTC");

            // responsedata[j].UploadHistory[k][i]['extended_comments'] = "";
            // if(responsedata[j].UploadHistory[k][i]['ext_alert_comments'] != null && responsedata[j].UploadHistory[k][i]['ext_alert_comments'] != ''){
            //   var commentarr = responsedata[j].UploadHistory[k][i]['ext_alert_comments'].trim().split(",");
            //   for(var m = 0; m < commentarr.length; m++) {
            //     responsedata[j].UploadHistory[k][i]['extended_comments'] = responsedata[j].UploadHistory[k][i]['extended_comments']+"<div>"+(Number(m)+1).toString()+". "+this.interventiondataarr[commentarr[m]]+"</div>";
            //   }
            // }

            // var settingjsonarr = responsedata[j].UploadHistory[k][i]['alert_setting'].trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
            var settingjson = "";
            if(responsedata[j].UploadHistory[k][i]['alert_setting'] && responsedata[j].UploadHistory[k][i]['alert_setting'] != null) {
              var settingjsonarr = responsedata[j].UploadHistory[k][i]['alert_setting'].trim().split(" ");
              for(var l = 0; l < settingjsonarr.length; l++) {
                if(settingjsonarr[l].trim() != '') {
                  if(settingjson) {
                    if(settingjsonarr[l].trim() != ":" && settingjsonarr[l].trim().slice(-1) != '"') {
                      settingjson = settingjson + settingjsonarr[l].trim() + ',';
                    }
                    else {
                      settingjson = settingjson + settingjsonarr[l].trim();
                    }
                  }
                  else {
                    settingjson = settingjson + settingjsonarr[l].trim();
                  }
                }
              }
            }
            if(settingjson.slice(-1) == ",") {
              settingjson = settingjson.slice(0, -1);
            }
            settingjson = '{' + settingjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
            var detailjson = "";
            if(responsedata[j].UploadHistory[k][i]['detailed_value']) {
              var detailjsonarr = responsedata[j].UploadHistory[k][i]['detailed_value'].trim().replace(/ : /g, ':').replace(/ :/g, ':').replace(/: /g, ':').replace(/" /g, '"').replace(/ "/g, '"').split(" ");
              for(var l = 0; l < detailjsonarr.length; l++) {
                if(detailjsonarr[l].trim() != '') {
                  if(detailjson) {
                    detailjson = detailjson + ',' + detailjsonarr[l].trim();
                  }
                  else {
                    detailjson = detailjson + detailjsonarr[l].trim();
                  }
                }
              }
            }
            var detailjson = '{' + detailjson.replace(/After,Meal/g, 'After Meal').replace(/Normal,Test/g, 'Normal Test') + '}';
            if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "BPM" || responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Oximeter" || responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Weight" || responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Blood Glucose" || responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Thermometer") {
              responsedata[j].UploadHistory[k][i]['alert_setting'] = JSON.parse(settingjson);
              if(!(responsedata[j].UploadHistory[k][i]['device_name'].trim() in responsedata[j].devicesetting[k]) && settingjson != '{}') {
                responsedata[j].devicesetting[k][responsedata[j].UploadHistory[k][i]['device_name'].trim()] = JSON.parse(settingjson);
              }
              responsedata[j].UploadHistory[k][i]['detailed_value'] = JSON.parse(detailjson);
              if(responsedata[j].UploadHistory[k][i]['detailed_value']['weight']) {
                responsedata[j].UploadHistory[k][i]['detailed_value']['weight'] = Math.floor(responsedata[j].UploadHistory[k][i]['detailed_value']['weight'])+" lbs";
              }
              if(responsedata[j].UploadHistory[k][i]['detailed_value']['bmi']) {
                responsedata[j].UploadHistory[k][i]['detailed_value']['bmi'] = Math.floor(responsedata[j].UploadHistory[k][i]['detailed_value']['bmi']);
              }
            }
            if(responsedata[j].UploadHistory[k][i]['critical_alert'] && responsedata[j].UploadHistory[k][i]['critical_alert'] == 1) {
              responsedata[j].UploadHistory[k][i]['super_alert'] = 1;
            }
            if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "BPM") {
              responsedata[j].UploadHistory[k][i]['device_name_str'] = "BPM (SYS/DIA/Pulse)";
              // Attn Required : 210 => SYS =<70 120 => DIA =<30 120 => PR =<40
              // if(responsedata[j].UploadHistory[k][i]['detailed_value']['sysData'] >= 210 || responsedata[j].UploadHistory[k][i]['detailed_value']['sysData'] <= 70) {
              //   responsedata[j].UploadHistory[k][i]['super_alert'] = 1;
              // }
              // if(responsedata[j].UploadHistory[k][i]['detailed_value']['diaData'] >= 120 || responsedata[j].UploadHistory[k][i]['detailed_value']['diaData'] <= 30) {
              //   responsedata[j].UploadHistory[k][i]['super_alert'] = 1;
              // }
              // if(responsedata[j].UploadHistory[k][i]['detailed_value']['pulseData'] >= 120 || responsedata[j].UploadHistory[k][i]['detailed_value']['pulseData'] <= 40) {
              //   responsedata[j].UploadHistory[k][i]['super_alert'] = 1;
              // }
            }
            else if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Oximeter") {
              responsedata[j].UploadHistory[k][i]['device_name_str'] = "Pulse Oximeter (Spo2%/PR)";
            }
            else if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Weight") {
              responsedata[j].UploadHistory[k][i]['device_name_str'] = "Weight (lb./BMI)";
            }
            else if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Blood Glucose") {
              responsedata[j].UploadHistory[k][i]['device_name_str'] = "Blood Glucose in mg/dL (Mode)";
            }
            else if(responsedata[j].UploadHistory[k][i]['device_name'].trim() == "Thermometer") {
              responsedata[j].UploadHistory[k][i]['device_name_str'] = "Thermometer (Temp. in Farenheit)";
            }
            else {
              responsedata[j].UploadHistory[k][i]['device_name_str'] = responsedata[j].UploadHistory[k][i]['device_name'].trim();
            }
          }
        }
        responsedata[j].TransmissionDate[k].devicesetting = responsedata[j].devicesetting[k];
        responsedata[j].TransmissionDate[k].UploadHistory = responsedata[j].UploadHistory[k];

        //A1C
        let faData = [];
        let amData = [];
        let ntData = [];

        for(var UploadHis of responsedata[j].UploadHistory[k]){        
          if (UploadHis.device_name.trim() == "Blood Glucose") {
            if (UploadHis.detailed_value.bloodGlucose && UploadHis.detailed_value.type.trim() == 'Fasting' ){
              faData.push(UploadHis.detailed_value.bloodGlucose);
            }else if (UploadHis.detailed_value.bloodGlucose && UploadHis.detailed_value.type.trim() == 'After Meal' ){
              amData.push(UploadHis.detailed_value.bloodGlucose);
            }else if (UploadHis.detailed_value.bloodGlucose && UploadHis.detailed_value.type.trim() == 'Normal Test' ){
              ntData.push(UploadHis.detailed_value.bloodGlucose);
            }
          }
        }

        let faDatatotalval = 0;
        let faDataA1c = "";
        for (var str of faData){
          faDatatotalval += Number(str);
        }
        let faDataa1c = ((46.7 + (faDatatotalval/faData.length))/28.7);
        if (faDataa1c > 0){
          faDataA1c = faDataa1c.toFixed(2);
        }




        let amDatatotalval = 0;
        let amDataA1c = "";
        for (var str of amData){
          amDatatotalval += Number(str);
        }
        let amDataa1c = ((46.7 + (amDatatotalval/amData.length))/28.7);
        if (amDataa1c > 0){
          amDataA1c = amDataa1c.toFixed(2);
        }



        let ntDatatotalval = 0;
        let ntDataA1c = "";
        for (var str of ntData){
          ntDatatotalval += Number(str);
        }
        let ntDataa1c = ((46.7 + (ntDatatotalval/ntData.length))/28.7);
        if (ntDataa1c > 0){
          ntDataA1c = ntDataa1c.toFixed(2);
        }

        responsedata[j].TransmissionDate[k].A1C = {faData: faDataA1c, amData: amDataA1c, ntData: ntDataA1c};
      }
    }
    return responsedata;
  }

  allGraphUpdate = () =>{
          // Start ALL Graph

          let tempUploadHistory = []
          let tempdevice = []
          let tempSettingDevice = []
          if(this.userDetails.DevicesAlertSettings) {
            tempSettingDevice = Object.keys(this.userDetails.DevicesAlertSettings);
          }
          this.alertSettingDeviceCount = tempSettingDevice.length;

          if(this.userDetails.UploadHistory) {
            for (var UploadHis of this.userDetails.UploadHistory){
              tempUploadHistory = tempUploadHistory.concat(UploadHis)
            }
          }

          for (var tempUploadHis of tempUploadHistory) {
            tempdevice.push(tempUploadHis.device_name.trim())
          }
          
          let unique = tempdevice.filter((v, i, a) => a.indexOf(v) === i);

          var allGraphDevice = unique.filter(function(v) {
            return tempSettingDevice.indexOf(v) > -1;
          })

          this.allGraphDevice = allGraphDevice;
          this.allUploadHistory = tempUploadHistory;
  }

  ngOnInit() {
    this.patientLineChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLineFAChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLineAMChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLineNTChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];

    this.body.classList.add('login-page');
    this.practiceId = atob(this.route.snapshot.paramMap.get('practice'));
    let sparam = this.route.snapshot.paramMap.get('sub').split('~');
    console.log(sparam);
    let subParams = atob(sparam[0]).split('~');
    console.log(subParams);
    if(sparam[1]){
      let numParams = atob(sparam[1]).split('~');
      console.log(numParams);
      if(subParams[2] && subParams[2] == "forceotpvalidate" && numParams.length > 0){
        this.needOtpValidated = true;
        this.otpnum = numParams;
      }
    }
    
    this.subId = subParams[0];
    if(subParams[1]) {
      if(subParams[1] == "false") {
        this.isValidated = true;
      }
    }
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: this.practiceId,
        patient_sub: this.subId
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getprofile', { params })
    .subscribe((response: any) => {
      if (response.status === "success") {
        let responsedata = JSON.parse(response.data);
        console.log(responsedata);
        if (responsedata.length > 0) {
          for (var j = 0; j < responsedata.length; j++) {
            if(responsedata[j]['birthdate']) {
              responsedata[j]['birthdate']=this.formatDateMSlash(responsedata[j]['birthdate'].replaceAll('-','/') + " 00:00:00");
              responsedata[j]['birthdate_date']=this.formatDateMDate(responsedata[j]['birthdate'].replaceAll('-','/') + " 00:00:00");
            }
            if(responsedata[j].TransmissionDate) {
              responsedata[j].devicesetting = [];
              responsedata[j].TransmissionDateArr = responsedata[j].TransmissionDate;
              responsedata[j].TransmissionDate = [];
              responsedata[j].UploadHistory = [];
              responsedata = this.partDataProcess(responsedata);
              
            }
            responsedata[j]['sub_btoa'] = btoa(responsedata[j]['sub']);
          }
          // console.log(this.facilityid);
          this.userDetails = responsedata[0];
          this.screen.style.display = 'none';
          this.allGraphUpdate();
        }
      }
      this.screen.style.display = 'none';
    },
    async (error: any) => {
      console.log(error);
    });
  }

  getNext = () => {
    this.screen.style.display = 'block';
    let params = new HttpParams({
      fromObject: {
        userpull_id: this.practiceId,
        patient_sub: this.subId,
        page: this.userDetails.nextpage
      }
    });
    this.httpClient.get('https://o1iu7pqt5i.execute-api.us-east-1.amazonaws.com/Development/getprofile', { params })
    .subscribe((response: any) => {
      if (response.status === "success") {
        let responsedatapart = JSON.parse(response.data);
        console.log(responsedatapart);
        // responsedata[0] = { ...responsedata[0], ...responsedatapart[0] }
        responsedatapart[0]['TransmissionDate'] = this.userDetails.TransmissionDate;
        responsedatapart[0]['UploadHistory'] = this.userDetails.UploadHistory;
        responsedatapart[0]['devicesetting'] = this.userDetails.devicesetting;
        this.partDataProcess(responsedatapart);
        this.userDetails.nextpage = responsedatapart[0].nextpage;
        this.allGraphUpdate();
      }
      this.screen.style.display = 'none';
    },
    async (error: any) => {
      console.log(error);
    })
  }

  patientgraphclick = (graphType, devicedata, device_name, DevicesAlertSettings, tempUploadHistory) => {
    console.log(graphType)
    console.log(devicedata)
    console.log(tempUploadHistory)
    console.log(DevicesAlertSettings)

    this.patientLineChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLinechartColors = []
    this.patientLinechartLabels = []

    this.patientLineFAChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLineFAchartColors = []
    this.patientLineFAchartLabels = []

    this.patientLineAMChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLineAMchartColors = []
    this.patientLineAMchartLabels = []

    this.patientLineNTChartData = [{ 'data': [], 'label': '', 'fill': false, 'lineTension': 0 }];
    this.patientLineNTchartColors = []
    this.patientLineNTchartLabels = []



    let tempGraphDevicedata = []

    if (graphType == 'AllGraph') {
      tempGraphDevicedata = devicedata;
      this.patientLineChartStr = device_name + "  All Data";

      if (tempUploadHistory.length > 0){
        this.patientLineChartStr = device_name + ": ( " + tempUploadHistory[tempUploadHistory.length - 1].StartDate + " - " + tempUploadHistory[0].EndDate + " )";
      }      

    }else if (graphType == 'Graph'){
      tempGraphDevicedata = devicedata.UploadHistory;
      this.patientLineChartStr = device_name + ": ( " + devicedata.StartDate + " - " + devicedata.EndDate + " )";
    }    

    if (device_name == "BPM"){
      let diaData = [];
      let pulseData = [];
      let sysData = [];

      let diaMin = [];
      let pulseMin = [];
      let sysMin = [];

      let diaMax = [];
      let pulseMax = [];
      let sysMax = [];

      let marList = [];

      var diaMinVal: number = DevicesAlertSettings.BPM.diaMin;
      var pulseMinVal: number = DevicesAlertSettings.BPM.pulseMin;
      var sysMinVal: number = DevicesAlertSettings.BPM.sysMin;

      var diaMaxVal: number = DevicesAlertSettings.BPM.diaMax;
      var pulseMaxVal: number = DevicesAlertSettings.BPM.pulseMax;
      var sysMaxVal: number = DevicesAlertSettings.BPM.sysMax;

      let labels = [];

      for(var UploadHis of tempGraphDevicedata){        
        if (UploadHis.device_name.trim() == device_name) {

          var dia: number;
          var pulse: number;
          var sys: number;

          if (UploadHis.detailed_value.diaData){
            dia = UploadHis.detailed_value.diaData
          }

          if (UploadHis.detailed_value.pulseData){
            pulse = UploadHis.detailed_value.pulseData
          }

          if (UploadHis.detailed_value.sysData){
            sys = UploadHis.detailed_value.sysData
          }

          if (dia && pulse && sys){
            diaData.push(dia)
            pulseData.push(pulse)
            sysData.push(sys)

            diaMin.push(diaMinVal)
            pulseMin.push(pulseMinVal)
            sysMin.push(sysMinVal)

            diaMax.push(diaMaxVal)
            pulseMax.push(pulseMaxVal)
            sysMax.push(sysMaxVal)

            let marVal = ((sys + (2 * dia))/3).toFixed(2);

            marList.push(marVal)

            labels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))
          }
        }
      }

      if (diaData.length == 1) {
        diaData.push(diaData[0])
        pulseData.push(pulseData[0])
        sysData.push(sysData[0])

        diaMin.push(diaMin[0])
        pulseMin.push(pulseMin[0])
        sysMin.push(sysMin[0])

        diaMax.push(diaMax[0])
        pulseMax.push(pulseMax[0])
        sysMax.push(sysMax[0])

        marList.push(marList[0])

        labels.push(labels[0])
      }

      if (diaData.length > 0) {
      
        //DATA      
        this.patientLineChartData =   [{ 'data':  diaData, 'label': 'DIA', 'fill': true, 'lineTension': 0 }];
        this.patientLineChartData.push({ 'data': pulseData, 'label': 'PULSE', 'fill': true, 'lineTension': 0 });
        this.patientLineChartData.push({ 'data': sysData, 'label': 'SYS', 'fill': true, 'lineTension': 0 });

        this.patientLineChartData.push({ 'data': diaMin, 'label': 'DIA MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': pulseMin, 'label': 'PULSE MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': sysMin, 'label': 'SYS MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineChartData.push({ 'data': diaMax, 'label': 'DIA MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': pulseMax, 'label': 'PULSE MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': sysMax, 'label': 'SYS MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1 , hidden: true});

        this.patientLineChartData.push({ 'data': marList, 'label': 'MAP (mean arterial pressure)', 'fill': false, 'lineTension': 0, 'borderWidth': 1 , hidden: true});

        //COLOR
        this.patientLinechartColors.push({ borderColor: 'rgba(0, 188, 158)', backgroundColor: 'rgba(167, 204, 213, .6)' })        
        this.patientLinechartColors.push({ borderColor: 'rgba(51, 51, 51)', backgroundColor: 'rgba(235, 187, 164, .6)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(0, 156, 215)', backgroundColor: 'rgba(200, 230, 245, .6)' })
        

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(38, 41, 51)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(10, 10, 100)' })

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(38, 41, 51)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(10, 10, 100)' })

        this.patientLinechartColors.push({ borderColor: 'red' })

        this.patientLinechartLabels = labels
      }
      
      console.log(diaData)
      console.log(pulseData)
      console.log(sysData)
      console.log(labels)
    }else if (device_name == "Weight"){
      let bmiData = [];
      let weightData = [];

      let bmiMin = [];
      let weightMin = [];

      let bmiMax = [];
      let weightMax = [];

      var bmiMinVal: number = DevicesAlertSettings.Weight.bmiMin;
      var weightMinVal: number = DevicesAlertSettings.Weight.wtMin;

      var bmiMaxVal: number = DevicesAlertSettings.Weight.bmiMax;
      var weightMaxVal: number = DevicesAlertSettings.Weight.wtMax;

      let labels = [];

      for(var UploadHis of tempGraphDevicedata){        
        if (UploadHis.device_name.trim() == device_name) {

          var bmi: number;
          var weight: number;

          if (UploadHis.detailed_value.bmi){
            bmi = UploadHis.detailed_value.bmi
          }

          if (UploadHis.detailed_value.weight){
            weight = +UploadHis.detailed_value.weight.split(" ")[0]
          }

          if (bmi && weight){
            bmiData.push(bmi)
            weightData.push(weight)

            bmiMin.push(bmiMinVal)
            weightMin.push(weightMinVal)

            bmiMax.push(bmiMaxVal)
            weightMax.push(weightMaxVal)

            labels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))
          }
        }
      }

      if (bmiData.length == 1) {
        bmiData.push(bmiData[0])
        weightData.push(weightData[0])

        bmiMin.push(bmiMin[0])
        weightMin.push(weightMin[0])

        bmiMax.push(bmiMax[0])
        weightMax.push(weightMax[0])

        labels.push(labels[0])
      }

      if (bmiData.length > 0) {
      
        //DATA      
        this.patientLineChartData =   [];
        // this.patientLineChartData.push({ 'data':  bmiData, 'label': 'BMI', 'fill': true, 'lineTension': 0 });
        this.patientLineChartData.push({ 'data': weightData, 'label': 'Weight', 'fill': true, 'lineTension': 0 });

        // this.patientLineChartData.push({ 'data': bmiMin, 'label': 'BMI MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': weightMin, 'label': 'Weight MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        // this.patientLineChartData.push({ 'data': bmiMax, 'label': 'BMI MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': weightMax, 'label': 'Weight MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        //COLOR
        this.patientLinechartColors.push({ borderColor: 'rgba(0, 188, 158)', backgroundColor: 'rgba(167, 204, 213, .6)' })        
        this.patientLinechartColors.push({ borderColor: 'rgba(51, 51, 51)', backgroundColor: 'rgba(184, 224, 235, .6)' })
        

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(38, 41, 51)' })

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(38, 41, 51)' })

        this.patientLinechartLabels = labels
      }
      
      console.log(bmiData)
      console.log(weightData)
      console.log(labels)


    }else if (device_name == "Oximeter"){
      let prData = [];
      let spo2Data = [];

      let prMin = [];
      let spo2Min = [];

      let prMax = [];
      let spo2Max = [];

      var prMinVal: number = DevicesAlertSettings.Oximeter.prMin;
      var spo2MinVal: number = DevicesAlertSettings.Oximeter.spo2Min;

      var prMaxVal: number = DevicesAlertSettings.Oximeter.prMax;
      var spo2MaxVal: number = DevicesAlertSettings.Oximeter.spo2Max;

      let labels = [];

      for(var UploadHis of tempGraphDevicedata){        
        if (UploadHis.device_name.trim() == device_name) {

          var pr: number;
          var spo2: number;

          if (UploadHis.detailed_value.pr){
            pr = +UploadHis.detailed_value.pr
          }

          if (UploadHis.detailed_value.spo2){
            spo2 = +UploadHis.detailed_value.spo2
          }

          if (pr && spo2){
            prData.push(pr)
            spo2Data.push(spo2)

            prMin.push(prMinVal)
            spo2Min.push(spo2MinVal)

            prMax.push(prMaxVal)
            spo2Max.push(spo2MaxVal)

            labels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))
          }
        }
      }

      if (prData.length == 1) {
        prData.push(prData[0])
        spo2Data.push(spo2Data[0])

        prMin.push(prMin[0])
        spo2Min.push(spo2Min[0])

        prMax.push(prMax[0])
        spo2Max.push(spo2Max[0])

        labels.push(labels[0])
      }

      if (prData.length > 0) {
      
        //DATA      
        this.patientLineChartData =   [{ 'data':  prData, 'label': 'PR', 'fill': true, 'lineTension': 0 }];
        this.patientLineChartData.push({ 'data': spo2Data, 'label': 'SPO2', 'fill': true, 'lineTension': 0 });

        this.patientLineChartData.push({ 'data': prMin, 'label': 'PR MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': spo2Min, 'label': 'SPO2 MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineChartData.push({ 'data': prMax, 'label': 'PR MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        this.patientLineChartData.push({ 'data': spo2Max, 'label': 'SPO2 MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        //COLOR
        this.patientLinechartColors.push({ borderColor: 'rgba(0, 188, 158)', backgroundColor: 'rgba(167, 204, 213, .6)' })        
        this.patientLinechartColors.push({ borderColor: 'rgba(51, 51, 51)', backgroundColor: 'rgba(184, 224, 235, .6)' })
        

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(38, 41, 51)' })

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })
        this.patientLinechartColors.push({ borderColor: 'rgba(38, 41, 51)' })

        this.patientLinechartLabels = labels
      }
      
      console.log(prData)
      console.log(spo2Data)
      console.log(labels)



    }else if (device_name == "Thermometer"){
      let temperatureData = [];

      let temperatureMin = [];

      let temperatureMax = [];

      var temperatureMinVal: number = DevicesAlertSettings.Thermometer.tempMin;

      var temperatureMaxVal: number = DevicesAlertSettings.Thermometer.tempMax;

      let labels = [];

      for(var UploadHis of tempGraphDevicedata){        
        if (UploadHis.device_name.trim() == device_name) {

          var temperature: number;

          if (UploadHis.detailed_value.temperature){
            temperature = UploadHis.detailed_value.temperature
          }

          

          if (temperature){
            temperatureData.push(temperature)

            temperatureMin.push(temperatureMinVal)

            temperatureMax.push(temperatureMaxVal)

            labels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))
          }
        }
      }

      if (temperatureData.length == 1) {
        temperatureData.push(temperatureData[0])

        temperatureMin.push(temperatureMin[0])

        temperatureMax.push(temperatureMax[0])

        labels.push(labels[0])
      }

      if (temperatureData.length > 0) {
      
        //DATA      
        this.patientLineChartData =   [{ 'data':  temperatureData, 'label': 'temperature', 'fill': true, 'lineTension': 0 }];

        this.patientLineChartData.push({ 'data': temperatureMin, 'label': 'temperature MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineChartData.push({ 'data': temperatureMax, 'label': 'temperature MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        //COLOR
        this.patientLinechartColors.push({ borderColor: 'rgba(0, 188, 158)', backgroundColor: 'rgba(167, 204, 213, .6)' })         

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })

        this.patientLinechartColors.push({ borderColor: 'rgba(10, 198, 10)' })

        this.patientLinechartLabels = labels
      }
      
      console.log(temperatureData)
      console.log(labels)

    }else if (device_name == "Blood Glucose"){
      let faData = [];
      let amData = [];
      let ntData = [];

      let faMin = [];
      let amMin = [];
      let ntMin = [];

      let faMax = [];
      let amMax = [];
      let ntMax = [];

      let faa1c = [];
      let ama1c = [];
      let nta1c = [];

      let falabels = [];
      let amlabels = [];
      let ntlabels = [];

      var faMinVal: number = DevicesAlertSettings['Blood Glucose']['glucoseFastingMin'];
      var amMinVal: number = DevicesAlertSettings['Blood Glucose']['glucoseAfterMealMin'];
      var ntMinVal: number = DevicesAlertSettings['Blood Glucose']['glucoseNormalTestMin'];

      var faMaxVal: number = DevicesAlertSettings['Blood Glucose']['glucoseFastingMax'];
      var amMaxVal: number = DevicesAlertSettings['Blood Glucose']['glucoseAfterMealMax'];
      var ntMaxVal: number = DevicesAlertSettings['Blood Glucose']['glucoseNormalTestMax'];

      var faa1cVal: number = 0;
      var ama1cVal: number = 0;
      var nta1cVal: number = 0;

      var fatotalVal: number = 0;
      var amtotalVal: number = 0;
      var nttotalVal: number = 0;

      for(var UploadHis of tempGraphDevicedata){        
        if (UploadHis.device_name.trim() == device_name) {

          console.log(UploadHis.detailed_value)

          if (UploadHis.detailed_value.bloodGlucose && UploadHis.detailed_value.type.trim() == 'Fasting' ){
            faData.push(UploadHis.detailed_value.bloodGlucose)

            fatotalVal += Number(UploadHis.detailed_value.bloodGlucose);
            faa1cVal = Number(((46.7 + (fatotalVal/faData.length))/28.7).toFixed(2));

            faMin.push(faMinVal)

            faMax.push(faMaxVal)

            faa1c.push(faa1cVal)

            falabels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))

          }else if (UploadHis.detailed_value.bloodGlucose && UploadHis.detailed_value.type.trim() == 'After Meal' ){
            amData.push(UploadHis.detailed_value.bloodGlucose)

            amtotalVal += Number(UploadHis.detailed_value.bloodGlucose);
            ama1cVal = Number(((46.7 + (amtotalVal/amData.length))/28.7).toFixed(2));

            amMin.push(amMinVal)

            amMax.push(amMaxVal)

            ama1c.push(ama1cVal)

            amlabels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))
          }else if (UploadHis.detailed_value.bloodGlucose && UploadHis.detailed_value.type.trim() == 'Normal Test' ){
            ntData.push(UploadHis.detailed_value.bloodGlucose)

            nttotalVal += Number(UploadHis.detailed_value.bloodGlucose);
            nta1cVal = Number(((46.7 + (nttotalVal/ntData.length))/28.7).toFixed(2));

            ntMin.push(ntMinVal)

            ntMax.push(ntMaxVal)

            nta1c.push(nta1cVal)

            ntlabels.push(UploadHis.timestamp.split(" ").slice(3, 6).join(" "))
          }


          
        }
      }      

      if (faData.length == 1) {
        faData.push(faData[0])

        faMin.push(faMin[0])

        faMax.push(faMax[0])

        faa1c.push(faa1c[0])

        falabels.push(falabels[0])
      }

      if (amData.length == 1) {
        amData.push(amData[0])

        amMin.push(amMin[0])

        amMax.push(amMax[0])
        
        ama1c.push(ama1c[0])
        
        amlabels.push(amlabels[0])
      }

      if (ntData.length == 1) {
        ntData.push(ntData[0])
        
        ntMin.push(ntMin[0])
        
        ntMax.push(ntMax[0])

        nta1c.push(nta1c[0])
        
        ntlabels.push(ntlabels[0])
      }

      if (faData.length > 0) {
      
        //DATA      
        this.patientLineFAChartData =   [{ 'data':  faData, 'label': 'Fasting', 'fill': true, 'lineTension': 0, 'borderWidth': 1, hidden: true }];

        this.patientLineFAChartData.push({ 'data': faMin, 'label': 'Fasting MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineFAChartData.push({ 'data': faMax, 'label': 'Fasting MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineFAChartData.push({ 'data': faa1c, 'label': 'Fasting A1C', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: false });

        //COLOR
        this.patientLineFAchartColors.push({ borderColor: 'rgba(0, 188, 158)', backgroundColor: 'rgba(167, 204, 213, .6)' }) 

        this.patientLineFAchartColors.push({ borderColor: 'rgba(10, 198, 10)' })

        this.patientLineFAchartColors.push({ borderColor: 'rgba(10, 198, 10)' })

        this.patientLineFAchartColors.push({ borderColor: 'red' })

        this.patientLineFAchartLabels = falabels
      }

      if (amData.length > 0) {
      
        //DATA 
        this.patientLineAMChartData =   [{ 'data': amData, 'label': 'After Meal', 'fill': true, 'lineTension': 0, 'borderWidth': 1, hidden: true }];
        
        this.patientLineAMChartData.push({ 'data': amMin, 'label': 'After Meal MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        
        this.patientLineAMChartData.push({ 'data': amMax, 'label': 'After Meal MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineAMChartData.push({ 'data': ama1c, 'label': 'After Meal A1C', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: false });

        //COLOR     
        this.patientLineAMchartColors.push({ borderColor: 'rgba(51, 51, 51)', backgroundColor: 'rgba(164, 224, 235, .6)' })

        this.patientLineAMchartColors.push({ borderColor: 'rgba(38, 41, 51)' })
        
        this.patientLineAMchartColors.push({ borderColor: 'rgba(38, 41, 51)' })

        this.patientLineAMchartColors.push({ borderColor: 'red' })        

        this.patientLineAMchartLabels = amlabels
      }

      if (ntData.length > 0) {
      
        //DATA 
        this.patientLineNTChartData =   [{ 'data': ntData, 'label': 'Normal Test', 'fill': true, 'lineTension': 0, 'borderWidth': 1, hidden: true }];
        
        this.patientLineNTChartData.push({ 'data': ntMin, 'label': 'Normal Test MIN', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });
        
        this.patientLineNTChartData.push({ 'data': ntMax, 'label': 'Normal Test MAX', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: true });

        this.patientLineNTChartData.push({ 'data': nta1c, 'label': 'Normal Test A1C', 'fill': false, 'lineTension': 0, 'borderWidth': 1, hidden: false });

        //COLOR
        this.patientLineNTchartColors.push({ borderColor: 'rgba(0, 156, 215)', backgroundColor: 'rgba(200, 230, 245, .6)' })
        
        this.patientLineNTchartColors.push({ borderColor: 'rgba(10, 10, 100)' })
        
        this.patientLineNTchartColors.push({ borderColor: 'rgba(10, 10, 100)' })

        this.patientLineNTchartColors.push({ borderColor: 'red' })

        this.patientLineNTchartLabels = ntlabels
      }

      console.log("Fasting")      
      console.log(faData)
      console.log(faa1c)
      console.log(falabels)

      console.log("After Meal")
      console.log(amData)
      console.log(amlabels)

      console.log("Normal Test")
      console.log(ntData)
      console.log(ntlabels)
    }   

    if (device_name == "Blood Glucose") {
      this.isPatientGlocoseLineChartData = true;
      this.isPatientDeviceLineChartData = false;

      let patientGlucoseGraphModal = document.getElementById("patient-graph-glucose-ankerTag") as HTMLElement;
      patientGlucoseGraphModal.click();
    }else {
      this.isPatientDeviceLineChartData = true;
      this.isPatientGlocoseLineChartData = false;

      let patientGraphModal = document.getElementById("patient-graph-ankerTag") as HTMLElement;
      patientGraphModal.click();
    }

  }

}

import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Auth } from 'aws-amplify';

@Injectable()

export class IfAllowed implements CanActivate {

  constructor(private router: Router) {
    this.authSuccessWatcher();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('AUTH')) {
      let userData = JSON.parse(localStorage.getItem('AUTH'));
      let group = userData['group'];
      let groups = ['Provider', 'Superadmin', 'SystemAdmin'];
      let checkGroup = groups.find((ele) => {
        return ele === group;
      });
      if (checkGroup) {
        return true;
      }
    }
    this.router.navigateByUrl('/sign-in');
    return false;
  }

  authSuccessWatcher = () => {
    setInterval(async () => {
      /*if (localStorage.getItem('AUTH-LIFE')) {        
        let storedTime = parseInt(localStorage.getItem('AUTH-LIFE'));
        let date = new Date();
        let nowTime = date.getTime();
        if (nowTime - storedTime > 15000) {
          await Auth.signOut();
          localStorage.removeItem('AUTH-LIFE');
          localStorage.removeItem('AUTH');
          localStorage.removeItem('AUTH-PROFILE-PIC');
          localStorage.removeItem('USER-DETAILS');
          this.router.navigateByUrl('/sign-in'); 
        } else {
          localStorage.setItem('AUTH-LIFE', nowTime.toString());
        }
      }*/

      if (sessionStorage.getItem('flashSuccess')) {
            sessionStorage.removeItem('flashSuccess');
        }
    }, 600);
  }
}